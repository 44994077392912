import React from 'react';
import { AjaxConfirm, toastMessageShow, getApplicantId, getMemberId, getLoginToken, lockObject, unlockObject } from 'service/common.js';
import CreateMemberUnavailabilityModel from './unavailability/CreateMemberUnavailabilityModel';
import ShiftNotes from './ShiftNotes';
import TimesheetShiftModal from './TimesheetShiftModal';
import checkProps from '@salesforce/design-system-react/lib/components/modal/check-props';

/**
* when archive is requested by the user for selected member unavailability
*/
export function showArchiveMemberUnavailabilityModal(id, fresh_call_back) {
    const msg = `Are you sure you want to archive this unavailability?`
    const confirmButton = `Archive Unavailability`
    var Request = { id: id, token: getLoginToken(), applicant_id: getApplicantId(), member_id: getMemberId() };
    AjaxConfirm(Request, msg, `recruitment/Recruitment_member/archive_member_unavailability`, { confirm: confirmButton, heading_title: `Archive Unavailability` }).then(result => {
        if (result.status) {
            toastMessageShow(result.msg, "s");
            fresh_call_back();
        } else {
            if (result.error) {
                toastMessageShow(result.error, "e");
            }
            return false;
        }
    })
}

/**
 * Render add/edit member unavailability modal
 * 
 */
export function openAddEditMemberUnavailabilityModal(member_unavailability_id, member_id, openCreateMemberUnavailabilityModal, closeCreateMemberUnavailabilityModal) {
    return (
        <React.Fragment>
            {
                openCreateMemberUnavailabilityModal && (
                    <CreateMemberUnavailabilityModel
                        id={member_unavailability_id}
                        member_id={member_id}
                        showModal={openCreateMemberUnavailabilityModal}
                        closeModal={closeCreateMemberUnavailabilityModal}
                        headingTxt="Create Member Unavailability"
                    />
                )
            }
        </React.Fragment>
    )
}

/**
 * Open shift status confirmation box
 */
export function showStatusChangeConfirm(shift_member_id, shift_id, shift_no, shift_status, scheduled_start_datetime, callback1, callback2) {
    var label = (shift_status == "1") ? "Accept" : "Decline";

    const msg = "Are you sure you want to " + label + " the shift: " + shift_no + " scheduled to start on: " + scheduled_start_datetime + "?";
    const confirmButton = label + ` Shift`;
    
    // lock object
    lockObject(shift_id, 'shift');

    AjaxConfirm({ token: getLoginToken(), applicant_id: getApplicantId(), status: shift_status, id: shift_member_id, shift_id: shift_id, member_id: getMemberId(), shift_no: shift_no }, msg, `recruitment/Recruitment_member/accept_reject_shift`, { confirm: confirmButton, heading_title: confirmButton }).then(result => {
        // unlock object
        unlockObject(shift_id, 'shift');
        
        if (result.status) {
            toastMessageShow(result.msg, "s");
            callback1(shift_id);
            if (callback2) {
                callback2(shift_id);
            }
        } else {
            if (result.error) {
                toastMessageShow(result.error, "e");
            }
            return false;
        }
    })
}

/**
 * when status change is requested
 */
export function updateShiftStatus(target_id, shift_id, shift_no, callback1, callback2=null,callback3=null) {
    var status = '';
    var label = '';
    if (target_id == "start") {
        status = 4;
        label = 'Start';
    }
    else if (target_id == "complete" || target_id == "edit") {
        target_id = "complete"
        status = 5;
        label = 'Complete';
    }

    const msg = "Are you sure you want to " + target_id + " the shift: " + shift_no + "?";
    const confirmButton = label + ` Shift`;
    // lock object
    lockObject(shift_id, 'shift');

    AjaxConfirm({ reason_drop: '', reason_note: '', id: shift_id, status: status, applicant_id: getApplicantId(), member_id: getMemberId() }, msg, `recruitment/Recruitment_member/update_shift_status`, { confirm: confirmButton, heading_title: confirmButton }).then(result => {
        if (result.status) {
            if (callback1) {
                callback1(shift_id);
            }
            if (callback2) {
                callback2(shift_id);
            }
            toastMessageShow(result.msg, "s");
        } else {
   
            if(callback3){
    
                if(result.error!='API ERROR'&&result.timeElaspe)
                {
                    callback3(result.error,status)
                }
                else{
                    toastMessageShow(result.error, "e");
                }
               
               // (result.error);
            }
        }

        // unlock object
        unlockObject(shift_id, 'shift');
    })
}

/**
 * Render add/edit shift modal
 * 
 */
export function openAddEditShiftModal(shift_id, openCreateShiftModal, closeCreateShiftModal, modal_name , status, readOnly = false) {

    if (modal_name == 'shift_notes') {
        return (
            <React.Fragment>
                {
                    openCreateShiftModal && modal_name == 'shift_notes' && (
                        <ShiftNotes
                            id={shift_id}
                            clone_id={0}
                            showModal={openCreateShiftModal}
                            closeModal={closeCreateShiftModal}
                            title={'Shift Notes'}
                            shift_status={status}
                            readOnly={readOnly}
                        />
                    )
                }
            </React.Fragment>
        )
    } else if (modal_name == 'timesheet') {
        return (
            <React.Fragment>
                {
                    openCreateShiftModal && modal_name == 'timesheet' && (
                        <TimesheetShiftModal
                            id={shift_id}
                            clone_id={0}
                            showModal={openCreateShiftModal}
                            closeModal={closeCreateShiftModal}
                            title={'Timesheet'}
                            shift_status={status}
                            readOnly={readOnly}
                        />
                    )
                }
            </React.Fragment>
        )
    }

}

