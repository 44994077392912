import React, { Component } from 'react';
import jQuery from "jquery";
import 'react-select-plus/dist/react-select-plus.css';
import 'react-block-ui/style.css';
import 'service/jquery.validate.js';
import "service/custom_script.js";
import { checkItsNotLoggedIn, postData, toastMessageShow, queryOptionData, archiveALL, AjaxConfirm, getLoginToken } from 'service/common.js';
import 'scss/salesforce/lightning/salesforce-lightning-design-system.scss';
import Modal from '@salesforce/design-system-react/lib/components/modal';
import Button from '@salesforce/design-system-react/lib/components/button'; 
import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import Icon from '@salesforce/design-system-react/lib/components/icon'; 
import SLDSReactSelect from 'components/admin/salesforce/lightning/SLDSReactSelect.jsx';

import { 
    Checkbox,
    Textarea,
    ProgressIndicator,
    ProgressBar
} from '@salesforce/design-system-react';


class QuizModal extends Component {

    /**
     * setting the initial prop values
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        
        // Initialize state
        this.state = {
            applicant_id: this.props.applicant_id ? this.props.applicant_id : '',
            application_id: this.props.application_id ? this.props.application_id : '',
            task_id: this.props.task_id ? this.props.task_id : '',
            loading: true,
            questions: [],
            completed_ques: [],
			disabled_ques: [],
            selected_que: [],
            selected_que_index: 0,
            progress_value: 0,
            que_weight: 0,
        }
    }

    /**
     * Save Quiz
     * @param {eventObj} e 
     */
    handleSubmit = (e) => {
        e.preventDefault();

        const msg = 'Are you sure you want to submit the quiz?'
        const confirmButton = 'Submit Quiz';

        var reqData = { task_id: this.state.task_id, applicant_id: this.state.applicant_id, application_id: this.state.application_id, token: getLoginToken(), questions: this.state.questions };
        
        AjaxConfirm(reqData, msg, 'recruitment/Recruitment_member/submit_answers_list_for_quiz', { confirm: confirmButton, heading_title: 'Submit Quiz' }).then(result => {
            if (result.status) {
                toastMessageShow(result.msg, "s")
                this.props.onClose();
            } else {
                if (result.error) {
                    toastMessageShow(result.error, "e");
                }
            }
        })
    }

    /**
     * rendering the question and its answer options
     */
    rendorQuestion = () => {
        var finalArr = [];
        var columns = [];
        var selected_que_index = this.state.selected_que_index;
        var sel_question_det = this.state.questions[selected_que_index];
        return (
        <div className="row my-3">
            <fieldset className="slds-form-element">
                <legend className="slds-form-element__legend slds-form-element__label" style={{'padding-bottom':'5px'}}>
                {sel_question_det.question}{(sel_question_det.is_required == 1) ? <abbr className="slds-required" title="required"> *</abbr> : ''}</legend>  
                {this.answerRender(sel_question_det, this.state.selected_que_index)}
            </fieldset>
        </div>
        );
    }

    /**
     * Go to next or previous step/question
     */
    handleNextPrevStep(next_que_index, skip_validation = false) {

        if(!skip_validation)
        jQuery("#cand_quiz").validate({ /* */ });
    
        if (skip_validation || jQuery("#cand_quiz").valid()) {
            if(this.state.loading == true)
            return;

            const sel_question = this.state.questions[next_que_index] || [];
            const completed_ques = this.state.questions.slice(0, next_que_index);
            var que_weight = Number(100 / this.state.questions.length);
            var progress_value = que_weight * (next_que_index + 1);

            this.setState({
                completed_ques,
                disabled_ques:[],
                selected_que: sel_question,
                progress_value,
                que_weight,
                selected_que_index: next_que_index,
            });
        }
    }

    /**
     * mounting all the components
     */
    componentDidMount() {
        this.getQuestionListAndDetails();
    }

    /**
     * When an answer option is chosen/selected
     */
    onChangeAnswer = (main_index, answer_index, answer) => {
        var questions = this.state.questions;
        var question_type = questions[main_index]['question_type'];

        if (question_type == 1) {
            questions[main_index]['answer_option'][answer_index]['answered'] = answer;
        }
        else if(question_type == 2 || question_type == 3) {
            questions[main_index]['answer_option'].map((val, idxx) => {
                questions[main_index]['answer_option'][idxx]['answered'] = false;
            });
            questions[main_index]['answer_option'][answer_index]['answered'] = true;
        } else {
            questions[main_index]['answer_text'] = answer;
        }

        this.setState({ questions: questions });
    }

    /**
     * rendering all the answer options
     */
    answerOptionsRender = (question, main_index) => {
        var classNametype = question.question_type == 1 ? "radio_F1 check_F1 mb-0" : "radio_F1 mb-0";
        var type = question.question_type == 1 ? "checkbox" : "radio";

        return <React.Fragment>
            {question.answer_option.map((val, idxx) => {
                return <span className={"pl-2 slds-" + type}>
                <input type={type} id={idxx} value={idxx} name={question.id} onChange={(e) => this.onChangeAnswer(main_index, idxx, e.target.checked)} checked={(val.answered && val.answered == true)?true:false} required={question.is_required == true ? true : false} style={{width:'50%'}} />
                <label className={"slds-" + type + "__label"} htmlFor={idxx}>
                  <span className={"slds-" + type + "_faux"} style={{'margin-right': '0.5rem'}}></span>
                  <span className="slds-form-element__label">{val.option}</span>
                </label>
              </span>
            })}
        </React.Fragment>
    }

    /**
     * Rendering the answer
     */
    answerRender = (val, main_index) => {
        console.log(val);
        switch (val.question_type) {
            case '2':
                return <div className="slds-form-element__control">
                    {this.answerOptionsRender(val, main_index)}
                </div>;
                break;
            case '3':
                return <div className="slds-form-element__control">
                    {this.answerOptionsRender(val, main_index)}
                </div>;
                break;
            case '1':
                return <div className="slds-form-element__control">
                    {this.answerOptionsRender(val, main_index)}
                </div>;
                break;
            case '4':
                return <div className="slds-form-element__control">
                    <Textarea
                        type="text"
                        className="slds-input"
                        name={val.id}
                        placeholder=""
                        onChange={(e) => this.onChangeAnswer(main_index, 0, e.target.value)}
                        value={(val.answer_text)?val.answer_text:''}
                        required={val.is_required  == true ? true : false}
                        maxLength="500"
                    />
                </div>;
                break;
            default:
                return '';
                break;
        }
    }

    /**
     * Fetching the question and answer details
     */
    getQuestionListAndDetails = () => {
        this.setState({ loading: true });

        var reqData = { task_id: this.state.task_id, applicant_id: this.state.applicant_id, application_id: this.state.application_id };

        postData('recruitment/Recruitment_member/get_question_list', reqData).then((result) => {
            if (result.status) {
                var res = result.data;
                this.setState(res);
            }
            this.setState({ loading: false });
            this.handleNextPrevStep(0, true);
        });
    }

    /**
     * Render the display content
     */
    render() {
        if (this.state.loading) {
            return (
                <React.Fragment></React.Fragment>
            )
        }
        
        var buttons_bar = (
            <div className="row">
                <div className="col-xs-6 text-left">
                <Button
                    label={'Cancel'}
                    type="button"
                    onClick={this.props.onClose}
                />
                </div>
                <div className="col-xs-6 text-right">
                <Button
                    label={'Prev'}
                    type="button"
                    disabled={(this.state.selected_que_index == 0) ? true : false}
                    onClick={() => this.handleNextPrevStep(this.state.selected_que_index -1)}
                />
                {
                (this.state.selected_que_index == (Number(this.state.questions.length) - 1)) ? 
                <Button 
                    disabled={false} 
                    label={'Submit'} 
                    variant="brand" 
                    type="button" 
                    onClick={this.handleSubmit}
                />
                :
                <Button 
                    label={'Next'} 
                    type="button" 
                    disabled={false}
                    onClick={() => this.handleNextPrevStep(this.state.selected_que_index + 1)}
                />
                }
                </div>
            </div>
        );

        return (
            <React.Fragment>
                <IconSettings iconPath={"/assets/salesforce-lightning-design-system/assets/icons"}>
                    <Modal
                        id="new-docusign"
                        isOpen={this.props.isOpen}
                        heading={
                            <div><span className="pb-3">Quiz</span>
                            
                            </div>}
                        containerClassName={'cus-footer-flex'}
                        footer={[      
                            <>{(this.state.questions.length > 0) ? buttons_bar : ''}</>
                        ]}
                        onRequestClose={this.props.onClose}
                        size="x-small"
                        dismissOnClickOutside={false}
                    >
                    <ProgressBar style={{height:'12px'}} value={this.state.progress_value} id="progress-bar-medium" thickness="medium" />
                    <div className="container-fluid">
                        <form id="cand_quiz" autoComplete="off" className="slds_form">
                        {(this.state.questions.length > 0) ? this.rendorQuestion() : <div className="py-5">No Questions Found!</div>}
                        </form>
                    </div>
                    </Modal>
                </IconSettings>
            </React.Fragment>
        );
    }
}

export default QuizModal;
