import React from 'react'
import { postData } from '../../../../../service/common'
import moment from 'moment';
import { defaultSpaceInTable } from 'service/custom_value_data.js';

/**
 * @typedef {typeof AttachmentCard.defaultProps} Props
 * 
 * Card to display uploaded attachments
 * 
 * @extends {React.Component<Props>}
 */
class DiagnosisCard extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			openAddDiagnosis: false,
			openEditDiagnosis: false,
			isResultAlreadySelected: false,
			diagnosisCount: 0,
			showList: false
		}

		this.myRefForSelectedItem = React.createRef(); // that reference is send from item listing to edit
		this.myRefForSelectedItemFromEditScreen = React.createRef(); // that reference is send from edit to item listing
	}

	componentDidMount() {
		if (this.props.need_assessment_id) {
			this.setState({ need_assessment_id: this.props.need_assessment_id }, () => {
				this.getSelectedDiagnosisAssistance();
			})
		}
	}

	getSelectedDiagnosisAssistance = () => {
		postData("sales/NeedAssessment/get_selected_diagnosis_assistance", { need_assessment_id: this.state.need_assessment_id }).then((res) => {
			if (res.status) {
				this.setState({ diagnosis_list: res.data, isResultAlreadySelected: true, user_id: res.user_id, showList: true }, () => {
				})
			}
		});
	}

	determineColumns() {
		return [
			{
				_label: 'Diagnosis',
				accessor: "label",
				Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
				Cell: props => <span className="slds-truncate">{defaultSpaceInTable(props.value)}</span>,
			},
			{
				_label: 'Primary Disability',
				accessor: "primary_disability",
				Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
				Cell: props => <span className="slds-truncate">
					{(props.value == 1) ?
						<div class="slds-form-element">
							<div class="slds-form-element__control">
								<div class="slds-checkbox">
									<input type="checkbox" name="options" checked="true" />
									<label class="slds-checkbox__label">
										<span class="slds-checkbox_faux"></span>
										<span class="slds-form-element__label"></span>
									</label>
								</div>
							</div>
						</div>
						: ''}
				</span>
			},
			{
				_label: 'Level of Support',
				accessor: "support_level",
				Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
				Cell: props => (
					<span className="slds-truncate">
						{defaultSpaceInTable((props.value == 1) ? 'High' : (props.value == 2 ? 'Medium' : (props.value == 3 ? 'Low' : '')))}
					</span>
				),
			},
			{
				_label: 'Current Plan',
				accessor: "current_plan",
				Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
				Cell: props => <span className="slds-truncate">
					{(props.value == 1) ?
						<div class="slds-form-element">
							<div class="slds-form-element__control">
								<div class="slds-checkbox">
									<input type="checkbox" name="options" checked="true" />
									<label class="slds-checkbox__label">
										<span class="slds-checkbox_faux"></span>
										<span class="slds-form-element__label"></span>
									</label>
								</div>
							</div>
						</div>
						: ''}
				</span>
			},
			{
				_label: 'Plan End Date',
				accessor: "plan_end_date",
				Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
				Cell: props => <span className="slds-truncate">{defaultSpaceInTable((props.value) ? moment(props.value).format('DD/MM/YYYY') : '')}</span>
			},
			{
				_label: 'Impact on Participant',
				accessor: "impact_on_participant",
				Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
				Cell: props => <span className="slds-truncate">
					{defaultSpaceInTable((props.value == 1) ? 'Severe' : (props.value == 2 ? 'Moderate' : (props.value == 3 ? 'Mild' : (props.value == 4 ? ' Managed by medication' : ''))))}
				</span>
			},
			{
				_label: 'Edited by',
				accessor: "admin_name",
				Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
				Cell: props => <span className="slds-truncate">{defaultSpaceInTable(props.value)}</span>
			},
			{
				_label: 'Edited date',
				accessor: "updated",
				Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
				Cell: props => <span className="slds-truncate">{defaultSpaceInTable(moment(props.value).format("DD/MM/YYYY"))}</span>
			}
		]
	}

	render() {
		return (
			<React.Fragment>
				<div className="slds-grid">
					<div className="slds-panel slds-size_full slds-is-open" aria-hidden="false">
						<div className="slds-m-horizontal_small slds-p-vertical_x-small ml-0">
							<div className="slds-tile slds-tile_board slds-m-horizontal_small ml-0">
								<div className="slds-tile__detail">
									{this.state.showList && this.state.diagnosis_list.map((diagnosis) =>
										<div className="col-sm-12 dia_form_siz">
											<div className="form-group m-0">
												<label>Diagnosis&nbsp;:&nbsp;</label>
												<span>{diagnosis.label}</span>
											</div>
											<div className="form-group m-0">
												<label>Primary Disability&nbsp;:&nbsp;</label>
												<span>{diagnosis.primary_disability == 1 ? 'Yes' : 'No'}</span>
											</div>
											<div className="form-group m-0">
												<label>Level of Support&nbsp;:&nbsp;</label>
												<span>{(diagnosis.support_level == 1) ? 'High' : (diagnosis.support_level == 2 ? 'Medium' : (diagnosis.support_level == 3 ? 'Low' : ''))}</span>
											</div>
											<div className="form-group m-0">
												<label>Impact on Participant&nbsp;:&nbsp;</label>
												<span>{(diagnosis.impact_on_participant == 1) ? 'Severe' : (diagnosis.impact_on_participant == 2 ? 'Moderate' : (diagnosis.impact_on_participant == 3 ? 'Mild' : (diagnosis.impact_on_participant == 4 ? ' Managed by medication' : '')))}</span>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment >
		);
	}

}

export default DiagnosisCard