import React from 'react'
import { ExpandableSection } from '@salesforce/design-system-react'
import PersonalCareForm from './PersonalCareForm';
import DailyLiving from './DailyLiving';

/**
 * @typedef {typeof AttachmentCard.defaultProps} Props
 * 
 * Card to display uploaded attachments
 * 
 * @extends {React.Component<Props>}
 */
class PersonalCareCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      need_assessment_id: props.need_assessment_id,
      isOpen: false
    }
  }

  onToggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    return (
      <React.Fragment>
        <ExpandableSection
          id="default-expandable-section"
          title="Personal Care"
        >
          <PersonalCareForm
            state={this.state}
            ref={e => this.personalCareRef = e}
          />
        </ExpandableSection>

        <ExpandableSection
          id="default-expandable-section"
          title="Daily Living"
          isOpen={this.state.isOpen}
          onToggleOpen={this.onToggleOpen}
        >
          <DailyLiving
            state={this.state}
            ref={e => this.dailyLivingRef = e}
          />
        </ExpandableSection>

      </React.Fragment >
    );
  }

}

export default PersonalCareCard