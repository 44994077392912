import React from 'react'
import { postData, css } from 'service/common'
import moment from 'moment';
import { ExpandableSection } from '@salesforce/design-system-react';
/**
 * @typedef {typeof ShiftGoalsTrackingData.defaultProps} Props
 * 
 * Card to display uploaded attachments
 * 
 * @extends {React.Component<Props>}
 */


const GOAL_TYPE = [
	{ id: 'not_relevant', label: 'Not Attempted:Not relevant to this shift', value: '1' },
	{ id: 'customer_choice', label: "Not Attempted:Customer's Choice", value: '2' },
	{ id: 'verbal_prompt', label: 'Verbal Prompt', value: '3' },
	{ id: 'physical_assistance', label: 'Physical Assistance', value: '4' },
	{ id: 'independent', label: 'Independent', value: '5' },
];

class ShiftGoalsTrackingData extends React.Component {

	constructor(props) {
		super(props);


		this.state = {
			shift_goal_list1: [],
			objStateTest: [],
			setGoalsData: [],
		}

	}

	componentDidMount() {
		this.getParticipantGoals(this.props);
	}

	getParticipantGoals = (data) => {
		var req = {
			account_id: data.account_id,
			account_type: data.account_type,
			goal_id: data.selected_goal,
			selected_date_type: data.selected_date_type,
			start_end_date: data.start_end_date
		}
		postData("item/Goals/get_all_goals_and_shift_by_participant_id", req).then((res) => {
			if (res.status) {

				let obj = res.data.list;
				let keys = Object.keys(res.data.list);
				// mapping keys and objects
				var keyChange = Object.keys(obj).reduce((prev, curr, index) => { return { ...prev, ['shift_detail_' + (index + 1)]: obj[curr] } }, {});

				let formatedArray = [];
				keys.map((e, i) => {
					let newKey = 'shift_detail_' + (i + 1);
					formatedArray.push(keyChange[newKey]);
					newKey = '';
				});

				this.setState({ setGoalsData: formatedArray, showList: true });

			}else{
				this.setState({ setGoalsData: [], showList: true });
			}
		});
	}

	refreshPage = (data) => {
		this.getParticipantGoals(data);
	}

	toggleExpand = (mainIndex, subIndex) => {
		let clone = this.state.setGoalsData;
		clone[mainIndex].shift_data[subIndex].expand_option = !clone[mainIndex].shift_data[subIndex].expand_option;
		this.setState({ setGoalsData: clone });
	}	

	shift_goal_sub_render = (shift_goal, index) => {
		return (
			<React.Fragment>
				{shift_goal.map((goal, i) =>
					<ExpandableSection
						id="controlled-expandable-section" key={i}
						isOpen={goal.expand_option}
						onToggleOpen={() => {
							this.toggleExpand(index, i)
						}}
						title={'Goal: ' + goal.goal}
					>
						<div className="col-sm-12 dia_form_siz">
							<div className="form-group m-0">
								<label>Service Type&nbsp;:&nbsp;</label>
								<span>{goal.service_type ? goal.service_type : 'N/A'}</span>
							</div>
							<div className="form-group m-0">
								<label>Action&nbsp;:&nbsp;</label>
								<span>{goal.goal_type ? goal.goal_action : 'N/A'}</span>
							</div>
							<div className="form-group m-0">
								<label>Snapshot&nbsp;:&nbsp;</label>
								<span>{goal.snapshot ? goal.snapshot : 'N/A'}</span>
							</div>
							<div className="form-group m-0">
								<label>Outcome&nbsp;:&nbsp;</label>
								<span className="shift-outcome">{goal.outcometype ? goal.outcometype : 'N/A'}</span>
							</div>
						</div>
					</ExpandableSection>
				)}
			</React.Fragment>
		)
	}

	render() {
		const styles = css({
			
			font_bold: {
				fontSize: '12px',
				fontWeight: '600'
			}})
		return (
			<React.Fragment>
				<div className="col-lg-11 col-md-11 col-xs-12 col-sm-11 my-10" style={{'padding' : '0px'}}>
					<div className="slds-panel slds-size_full slds-is-open" aria-hidden="false">
						<div className="slds-m-horizontal_small slds-p-vertical_x-small" style={{'margin': '0px'}}>
							<div className="slds-tile slds-tile_board slds-m-horizontal_small shift_box">
								<div className="slds-tile__detail">
									{this.state.showList && this.state.setGoalsData.length > 0 ? this.state.setGoalsData.map((goal, i) => {

										return (
											<>
												<span style={styles.font_bold}>Shift Performed On  {goal.scheduled_start_datetime ? moment(goal.scheduled_start_datetime).format("DD/MM/YYYY") : "N/A"}</span>
												<div>
													{goal.shift_data ? this.shift_goal_sub_render(goal.shift_data, i) : ' '}
												</div>
											</>
										)

										// 
									}
									) : 'No records found'}
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment >
		);
	}

}

export default ShiftGoalsTrackingData