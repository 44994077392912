
const initialState = {
    states: {
        loading: false,
        /**
         * @type {{label: string, value: number|string}[]}
         */
        data: [],
    },
    title: {
        loading: false,
        /**
         * @type {{label: string, value: number|string}[]}
         */
        data: [],
    },
    isMember: false,
}


export default function CommonReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case 'FETCH_STATE_LIST_STARTED':
            return {
                ...state,
                states: { 
                    ...state.states, 
                    loading: true 
                }
            }
        case 'FETCH_STATE_LIST_SUCCESS':
            return {
                ...state,
                states: {
                    ...state.states,
                    loading: false,
                    data: payload.data,
                }
            }
        case 'FETCH_STATE_LIST_FAILED':
            return {
                ...state,
                states: {
                    ...state.states,
                    loading: false,
                }
            }
        case 'FETCH_TITLE_OPTIONS_STARTED':
            return {
                ...state,
                title: {
                    ...state.title,
                    loading: true,
                }
            }
        case 'FETCH_TITLE_OPTIONS_SUCCESS':
            return {
                ...state,
                title: {
                    ...state.title,
                    loading: false,
                    data: payload.data,
                }
            }
        case 'FETCH_TITLE_OPTIONS_FAILED':
            return {
                ...state,
                title: {
                    ...state.title,
                    loading: false,
                }
            }
        case 'SET_MEMBER_FLAG':
            return {
                ...state,
                isMember: true
            }
        default:
            return state
    }
}