import React from 'react';
import jQuery from "jquery";
import _ from 'lodash';
import classNames from 'classnames';
import {  IconSettings,Button,Icon } from '@salesforce/design-system-react'
import { css,postData } from '../../../../service/common'
import moment from 'moment';
import { Redirect } from 'react-router';
import CreateLivingSituation from './RiskAssessmentDetails/CreateLivingSituation';

import RiskMatrixDetails from './RiskAssessmentDetails/RiskMatrixDetails.jsx';
import RiskCourtActions from './RiskAssessmentDetails/RiskCourtActions.jsx';
import RiskBehaviourSupport from './RiskAssessmentDetails/RiskBehaviourSupport.jsx';


/**
 * RequestData get the detail of risk assessment
 * @param {int} riskAssessmentId
 */
 const requestRAData = (riskAssessmentId) => {

    return new Promise((resolve, reject) => {
        // request json
        var Request = { risk_assessment_id: riskAssessmentId };
        postData('sales/RiskAssessment/get_risk_assessment_detail_by_id', Request).then((result) => {
            if (result.status) {
                let resData = result.data;
                const res = {
                    data: resData,
                };
                resolve(res);
            } else {
                const res = {
                    data: []
                };
                resolve(res);
            }
        });
    });
};

/**
 * 
 * @param {object} props
 * @param {string} [props.subject]
 * @param {string} [props.datetime]
 * @param {string} [props.variant]
 * @param {boolean} [props.expandable]
 * @param {string} [props.icon]
 * @param {any[]} [props.actions]
 * @param {string} [props.description]
 */
export const TimelineItem = ({
    subject,
    datetime = undefined,
    variant = 'event',
    expandable = true,
    icon = 'call',
    actions = [],
    description = undefined,

}) => {

    const timelineClassNames = classNames({
        'slds-timeline__item': true,
        'slds-timeline__item_expandable': expandable,
    })

    return (
        <li>
            <div className={classNames([timelineClassNames, `slds-timeline__item_${variant}`])}>
                <div className="slds-media">
                    <div className="slds-media__figure">
                        {/* "action","custom","doctype","standard","utility" */}
                        <Button
                            type="button"
                            title="Toggle details"
                            iconName="switch"
                            iconCategory="utility"
                            variant="icon"
                            aria-controls="call-item-base"
                            iconClassName={['slds-timeline__details-action-icon']}
                        />
                        <Icon
                            category="standard"
                            name={icon}
                            containerClassName={['slds-timeline__icon']}
                            size="small"
                            title={'Activity'}
                        />
                    </div>

                    <div className="slds-media__body">
                        <div className="slds-grid slds-grid_align-spread slds-timeline__trigger">
                            <div className="slds-grid slds-grid_vertical-align-center slds-truncate_container_75 slds-no-space">
                                <h3 className="slds-truncate sdls-timeline-subject" title={subject}>
                                    {subject}
                                </h3>
                            </div>
                            <div className="slds-timeline__actions slds-timeline__actions_inline">
                                {
                                    datetime && (
                                        <p className="slds-timeline__date">
                                            <time dateTime={datetime}
                                                title={moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD hh:mm a')}>
                                                {moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')}
                                            </time>
                                        </p>
                                    )
                                }
                                {
                                    (actions || []).length > 0 && (
                                        <Button
                                            variant="icon"
                                            aria-haspopup="true"
                                            title="More Options"
                                            iconName="down"
                                            iconVariant="border-filled"
                                            iconSize="x-small"
                                        />
                                    )
                                }
                            </div>
                        </div>
                        {
                            description && (
                                <p className="slds-m-horizontal_xx-small">
                                    {description}
                                </p>
                            )
                        }
                    </div>
                </div>
            </div>
        </li>
    )
}

/**
 * @typedef {typeof AttachmentCard.defaultProps} Props
 * 
 * Card to display uploaded attachments
 * 
 * @extends {React.Component<Props>}
 */
class ShiftRiskAssessmentCard extends React.Component {

    static defaultProps = {
        /**
         * @type {string | React.ReactNode}
         */
        notAvailable: <span>&nbsp;</span>
    }

    constructor(props) {
        super(props);
        // Initialize state
        this.state = {
            loading: false,
            redirectPage: false,
            stageActiveIndex: 0,
            stage_index: 0,
            stage_status: 1,
            openEditModal: false,
            riskAssessmentId: "",
            redirectTo: false,
            refereence_id: "",
            status: "",
            created_by: "",
            created_date: "",
            openOppBox: false,
            living_situation: {},
            showBehaviour: false
            
                }

        this.rootRef = React.createRef();
        this.handleClick = this.handleClick.bind(this);
        this.handleDetailsTab = this.renderDetailsTab.bind(this);
    }

    /**
     * When component is mounted, remove replace the parent element
     */
    componentDidMount() {
        if(this.props.risk_assessment_id){
            this.getRADetails(this.props.risk_assessment_id);
        }
        
        jQuery(this.rootRef.current).parent('.col-lg-11').removeClass('col-lg-11').addClass('col-lg-12')
    }

    componentWillUnmount() {
        jQuery(this.rootRef.current).parent('.col-lg-12').removeClass('col-lg-12').addClass('col-lg-11')
    }

    /**
     * Call requestRAData
     * param {int} id
     */
    getRADetails = (id) => {
        requestRAData(
            id,
        ).then(res => {
            var raData = res.data;
            if (raData) {
                this.setState({
                    risk_assessment_id: raData.risk_assessment_id,
                    reference_id: raData.reference_id,
                    topic: raData.topic,
                    owner_id: raData.owner_id,
                    owner: raData.owner,
                    account_id: raData.account_id,
                    account_person: raData.account_person,
                    account_type: raData.account_type,
                    status: raData.status,
                    risk_status: raData.risk_status,
                    created_by: raData.created_by,
                    behaviour_support_matrices: raData.behaviour_support_matrices,
                    probability_options: raData.probability_options,
                    impact_options: raData.impact_options,
                    matrices: raData.matrices,
                    created_date: moment(raData.created_date).format("DD/MM/YYYY"),
                    living_situation: raData.living_situation,
                    showBehaviour: true
                });
            }

        });
    }

    /**
     * Update the stage index
     * param {int} stage
     */
    handleClick = (stage) => {
        const state = this.state;
        state['stage_index'] = stage;
        this.setState(state);
    }   
    /**
     * Render Left side tab
     */
    renderTab = () => {
        const tab = [
            {
                label: "Details",
                content: this.handleDetailsTab,
            }
        ]
        return (
            <>
            {this.renderDetailsTab()}
            </>
        );
    }
    /**
     * Renders the details tab
     */
    renderDetailsTab() {
        const notAvailable = 'N/A';
        const id = _.get(this.props, 'props.match.params.id');

        const styles = css({
            headingText: {
                fontSize: 15,
                fontWeight: 'normal',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 15,
            },
            hr: {
                marginTop: 15,
                marginBottom: 15,
                border: 0,
                borderTop: '1px solid #eee',
                width: 'auto'
            },
            row: {
                marginLeft: -15,
                marginRight: -15,
            },
            col: {
                paddingLeft: 15,
                paddingRight: 15,
                marginBottom: 15,
            },
            tableParent: {
                marginTop: 10,
            }
        })

        return (
            <div className="slds-detailed-tab container-fluid">   
                {this.state.showBehaviour &&<CreateLivingSituation
                            openOppBox={this.state.openLvingSitBox}
                            closeModal={this.closeLivingModal}
                            risk_assessment_id={this.state.risk_assessment_id}
                            pageTitle={'New Opportunity'}
                            data={this.state}
                />}
                 <div className="row" style={styles.row}>
                    <div className="col col-12" style={styles.col}>
                        <div style={styles.tableParent}>
                        {this.state.showBehaviour && <RiskBehaviourSupport
                                // key={Date.now()}
                                risk_assessment_id={this.state.risk_assessment_id}
                                behvsupportmatrices={this.state.behaviour_support_matrices}
                                onUpdate={() => this.getRADetails(id)}
                            />}
                        </div>
                    </div>
                </div>
                {this.state.showBehaviour && <RiskCourtActions
                    risk_assessment_id={this.props.risk_assessment_id}
                />}
                <div className="row" style={styles.row}>
                    <div className="col col-12" style={styles.col}>
                        <div style={styles.tableParent}>
                            <RiskMatrixDetails
                                key={Date.now()}
                                risk_assessment_id={this.state.risk_assessment_id}
                                probability_options={this.state.probability_options}
                                impact_options={this.state.impact_options}
                                matrices={this.state.matrices}
                                onUpdate={() => this.getRADetails(id)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    /**
     * Open living create/update modal
     */
    showLivingModal = () => {
        this.setState({ openLvingSitBox: true })
    }

    /**
     * Close opportunity create modal
     */
    closeLivingModal = (status) => {
        const id = _.get(this.props, 'props.match.params.id')
        if (status) {
            this.getRADetails(id);
        }
        this.setState({ openLvingSitBox: false });
    }

    render() {
        // This will only run when you delete risk assessment
        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} />
        }
        return (
            <div className="RiskAssessmentDetails slds" ref={this.rootRef}>
                <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                    <div className="slds-grid slds-grid_vertical">      
                        <div className="slds-col">
                            <div className="slds-gutters row">
                                <div className="slds-col col-lg-12 col-md-12 slds-m-top_medium">
                                    {this.renderTab()}
                                </div>
                            </div>
                        </div>
                    </div>
                </IconSettings>
            </div>
        )
    };

}

export default ShiftRiskAssessmentCard