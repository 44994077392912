import React, { Component } from 'react';
import 'react-select-plus/dist/react-select-plus.css';
import 'react-block-ui/style.css';
import 'service/jquery.validate.js';
import "service/custom_script.js";
import { checkItsNotLoggedIn, postData, reFreashReactTable, toastMessageShow, AjaxConfirm, css } from 'service/common.js';
import '../../scss/components/admin/salesforce/lightning/salesforce-lightning-design-system.scss';
import ReactTable from "react-table";
import PropTypes from 'prop-types';
import { ROUTER_PATH, COMMON_DOC_DOWNLOAD_URL } from 'config.js';
import { Link } from 'react-router-dom';
import moment from 'moment';

import {
    Card,
    Button,
    Icon,
    IconSettings,
} from '@salesforce/design-system-react';
import TilesCard from './TilesCard';
import CreateDocumentModel from './CreateDocumentModel.jsx';
import EditDocumentModel from './EditDocumentModel.jsx';

import '../../scss/components/admin/member/member.scss';

/**
 * RequestData get the list of member document
 * @param {int} pageSize 
 * @param {int} page 
 * @param {int} sorted 
 * @param {int} filtered 
 */
const requestData = (member_id, applicant_id, pageSize, page, sorted, filtered) => {

    return new Promise((resolve, reject) => {
        // request json
        var Request = { member_id: member_id, applicant_id: applicant_id, pageSize: pageSize, page: page, sorted: sorted, filtered: filtered };
        postData('member/MemberDocumentMemberPortal/get_member_document_list', Request).then((result) => {
            if (result.status) {
                let filteredData = result.data;
                const res = {
                    rows: filteredData,
                    pages: (result.count),
                    document_count: result.document_count
                };
                resolve(res);
            } else {
                const res = {
                    rows: [],
                    pages: 0,
                    document_count: 0
                };
                resolve(res);
            }
           
        });

    });
};

/**
 * Class: DocumentCard
 */
class DocumentCard extends Component {   

    constructor(props) {
        super(props);
        // Check user is logged in or not
        // checkItsNotLoggedIn();
        // Initialize state
        this.state = {
            loading: false,
            redirectPage: false,
            document_count: 0,
            openCreateDocument: false,
            openEditDocument: false,
            searchVal: '',
            filterVal: '',
            documentList: [],
            pageSize: 6,
            page: 0,
            document_id: '',
        }
        // Initilize react table
        this.reactTable = React.createRef();
    }

    /*
     * method runs after the component output has been rendered to the DOM
     */
    componentDidMount() {       
        this.fetchDocumentData(this.state);
    }

    /**
     * Call the requestData
     * @param {temp} state 
     */
    fetchDocumentData = (state, instance) => {
        this.setState({ loading: true });
        console.log(this.props.applicant_id);
        requestData(
            this.props.member_id,
            this.props.applicant_id,
            state.pageSize,
            state.page,
            state.sorted,
            state.filtered
        ).then(res => {
            this.setState({
                documentList: res.rows,
                document_count: res.document_count,
                pages: res.pages,
                loading: false
            });
        });
    }

    
    renderCards = () => {
        if (this.state.document_count > 0 ) {
            return (
                <div className="slds-card__body_inner mb-4">
                    <ul class="slds-grid slds-wrap slds-grid_pull-padded">
                        {
                            this.state.documentList.map((val, index) => {
                                var issue_date = '';            
                                const issueDateMoment = moment(val.issue_date)
                                if (!issueDateMoment.isValid()) {
                                    issue_date = <span className="vcenter slds-truncate"></span>
                                } else {
                                    issue_date = issueDateMoment.format('DD/MM/YYYY')
                                }

                                var expiry_date = '';            
                                const expiryDateMoment = moment(val.expiry_date)
                                if (!expiryDateMoment.isValid()) {
                                    expiry_date = <span className="vcenter slds-truncate"></span>
                                } else {
                                    expiry_date = expiryDateMoment.format('DD/MM/YYYY')
                                }
                                let disabledStatus = Number(val.document_status);
                                return (
                                    <li class="slds-p-horizontal_small slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-3">
                                        <TilesCard
                                            showEditDocumentModal={this.showEditDocumentModal}
                                            handleOnArchiveDocument={this.handleOnArchiveDocument}
                                            document_id={val.id}
                                            disabled={(disabledStatus === 0 || disabledStatus === 1 || disabledStatus === 3) ? true : false}
                                            url={(!val.file_path || val.file_path == '') ? '' : COMMON_DOC_DOWNLOAD_URL + 'document/' + val.file_path}
                                            title={val.file_name}
                                            title_details={[
                                                { label: "Issue Date", value: issue_date },
                                                { label: "Expiry Date", value: expiry_date },
                                                { label: "Reference Number", value: val.reference_number }
                                            ]}
                                            icon={{ category: "standard", name: "file", size: "small" }}
                                        />
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            );
        } else {
            return (<React.Fragment />);
        }
    }

    /**
     * Render view all if count greater than 0
     */
    renderViewAll = () => {
        if (this.state.document_count === 0) {
            return <React.Fragment />
        }
        var id = this.props.applicant_id;
        if (this.props.member_id !== 'null') {
            id = this.props.member_id;
        }
        return (            
            <div className={'slds-align_absolute-center pt-3 bor-top-1'}>
                <Link to={ROUTER_PATH + `mycredentials/document/${id}`} className="reset" style={{ color: '#0070d2' }}>
                    {'View All'}
                </Link>
            </div>   
        );
    }

    /**
     * Show update document model
     */
    showEditDocumentModal = (id) => {
        this.setState({
            openEditDocument: true,
            document_id: id,
        });
    }

    /**
     * Close the modal when user save the document and refresh the table
     * Get the Unique reference id
     */
    closeEditDocumentModal = (status, documentId) => {
        this.setState({openEditDocument: false});

        if(status){
            if (documentId) {
                // to do...
            } else {
                this.fetchDocumentData(this.state);
            }
        }
    }
    
    /**
     * when archive is requested by the user for selected document
     */
    handleOnArchiveDocument = (id) => {
        const msg = `Are you sure you want to archive this document?`
        const confirmButton = `Archive Document`
        var is_member = false;
        if(this.props.member_id !== 'null') {
            is_member = true;
        }
        AjaxConfirm({ document_id: id, member_id: this.props.member_id, is_member: is_member }, msg, `member/MemberDocumentMemberPortal/archive_document`, { confirm: confirmButton, heading_title: `Archive Document` }).then(result => {
            if (result.status) {
                toastMessageShow(result.msg, "s");
                this.fetchDocumentData(this.state);
            } else {
                if (result.error) {
                    toastMessageShow(result.error, "e");
                }
            }
        })
    }

    /**
     * Close the modal when user save the document and refresh the table
     * Get the Unique reference id
     */
    closeModal = (status, documentId) => {
        this.setState({openCreateDocument: false});

        if(status){
            if (documentId) {
                // to do...
            } else {
                this.fetchDocumentData(this.state);
            }
        }
    }

    /**
     * Render modals
     * - Create Document
     * - Edit Document
     * 
     */
    renderModals() {
        return (
            <React.Fragment>
                {
                    this.state.openCreateDocument && (
                        <CreateDocumentModel
                            showModal = {this.state.openCreateDocument}
                            closeModal = {this.closeModal}
                            applicant_id = {this.state.applicant_id}
                            headingTxt = "New Document"
                            {...this.props}
                        />
                    )
                }
                {
                    this.state.openEditDocument && (
                        <EditDocumentModel
                            document_id={this.state.document_id}
                            showModal = {this.state.openEditDocument}
                            closeModal = {this.closeEditDocumentModal}
                            location_id = {this.state.document_id}
                            applicant_id = {this.state.applicant_id}
                            headingTxt = "Update Document"
                            {...this.props}
                        />
                    )
                }
            </React.Fragment>
        )
    }

    /**
     * Render the display content
     */
    render() {
        return (
            <React.Fragment>
                <IconSettings iconPath={"/assets/salesforce-lightning-design-system/assets/icons"}>
                    <div class="slds_my_card">
                    <Card
                        headerActions={<Button label="New" onClick={() => this.setState({ openCreateDocument: true }) }/>}
                        heading={Number(this.state.document_count) > 6 ? "Documents (6+)" : "Documents ("+this.state.document_count+")"}
                        className="slds-card-bor"
                        icon={<Icon category="standard" name="file" size="small" />}
                    >
                        {this.renderCards()}
                        {this.renderViewAll()}
                    </Card>
                    </div>
                    {this.renderModals()}
                </IconSettings>
            </React.Fragment>
        );
    }
}


// Defalut Prop
ReactTable.PropTypes = {
    defaultFiltered: PropTypes.object
}
export default DocumentCard;
