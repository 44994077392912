import React from 'react';
import jQuery from "jquery";
import BlockUi from 'react-block-ui';
import {
    Card, Button, Icon,
    Textarea,
    Checkbox,
    Combobox
} from '@salesforce/design-system-react'
import { css, toastMessageShow, postData, handleChange, comboboxFilterAndLimit, handleChangeSFLDSDatePicker, } from '../../../../../service/common'
import moment from 'moment';
import { IconSettings } from '@salesforce/design-system-react';
import SLDSReactSelect from 'components/admin/salesforce/lightning/SLDSReactSelect.jsx';
import Datepicker from '@salesforce/design-system-react/lib/components/date-picker';

/**
 * @typedef {typeof AttachmentCard.defaultProps} Props
 * 
 * Card to display uploaded attachments
 * 
 * @extends {React.Component<Props>}
 */
class PreferencesCard extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,

            inputValueLang: '',
            langSelection: [],
            workerLangOption: [],

            inputValueCulture: '',
            cultureSelection: [],
            workerCultureOption: [],

            inputValueLikes: '',
            likeSelection: [],
            likesOption: [],

            inputValueDislikes: '',
            disLikesSelection: [],
            disLikesOption: [],

            inputValueIntrest: '',
            intrestSelection: [],
            workerIntrestOption: [],

            in_home_shift_tasks: '',
            community_access_shift_tasks: '',
            active_night_details: '',
            sleep_over_details: '',
            preferences_not_applicable: 0,
            options_length: 1
        }
    }

    componentDidMount() {
        if (this.props.need_assessment_id) {
            this.setState({ need_assessment_id: this.props.need_assessment_id }, () => {
                this.getSelectedPreference();
            })
        }
    }    

    getSelectedPreference = () => {
        postData("sales/NeedAssessment/get_selected_preference_assistance", { need_assessment_id: this.state.need_assessment_id }).then((res) => {
            if (res.status) {
                this.setState(res.data, () => {
                    this.setState({
                        likeSelection: (res.data.selected_preference && res.data.selected_preference[1]) ? res.data.selected_preference[1] : [],
                        disLikesSelection: (res.data.selected_preference && res.data.selected_preference[2]) ? res.data.selected_preference[2] : [],
                        langSelection: (res.data.selected_preference && res.data.selected_preference[3]) ? res.data.selected_preference[3] : [],
                        cultureSelection: (res.data.selected_preference && res.data.selected_preference[4]) ? res.data.selected_preference[4] : [],
                        intrestSelection: (res.data.selected_preference && res.data.selected_preference[5]) ? res.data.selected_preference[5] : [],
                        preferences_not_applicable: res.data.not_applicable === "1" ? true : false
                    })
                })
            }
        });
    }

    render() {
        
        return (
            <React.Fragment>
                <BlockUi tag="div" blocking={this.state.loading}>
                    <div className="slds-grid">
                        <div className="slds-panel slds-size_full slds-is-open" aria-hidden="false">
                            <form id="preference_form" autoComplete="off" className="col-md-12 slds_form" onSubmit={e => this.onSubmit(e)}>
                                <div className="slds-panel__header">
                                    <h2 className="slds-panel__header-title slds-text-heading_small slds-truncate" title="Panel Header">Preferences</h2>
                                </div>

                                <div className="slds-panel__body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Checkbox
                                                assistiveText={{
                                                    label: 'Not Applicable',
                                                }}
                                                id="preferences_not_applicable"
                                                labels={{
                                                    label: 'Not Applicable',
                                                }}
                                                checked={this.state.preferences_not_applicable ? true : false}    
                                                disabled                                            
                                            />
                                        </div>
                                    </div>
                                    <div className="grey-bg">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <fieldset className="slds-form-element mb-3">
                                                    <legend className="slds-form-element__legend slds-form-element__label"> Likes</legend>
                                                    {this.state.likeSelection && this.state.likeSelection.map((data, i) =>
                                                        <label className="slds-radio__label" htmlFor="inout_chair_1">
                                                            <span className="slds-radio_faux"></span>
                                                            <span className="slds-form-element__label">{i != this.state.likeSelection.length - 1 ? data.label + ', ' : data.label}</span>
                                                        </label>
                                                    )}
                                                </fieldset>


                                                <div className="slds-grid slds-grid_vertical-reverse" style={{ height: '140px' }}>
                                                    <div className="slds-col">
                                                        <span></span>
                                                    </div>
                                                    <div className="slds-col">
                                                        <span></span>
                                                    </div>
                                                </div>

                                                <fieldset className="slds-form-element mb-3">
                                                    <legend className="slds-form-element__legend slds-form-element__label">Prefered Start Date</legend>
                                                    <div className="slds-form-element__control">
                                                        <div className="SLDS_date_picker_width">
                                                        {this.state.prefered_start_date ? moment(this.state.prefered_start_date).format('M/D/YYYY') : '' }                                                           
                                                        </div>
                                                    </div>
                                                </fieldset>

                                                <fieldset className="slds-form-element mb-3">
                                                    <legend className="slds-form-element__legend slds-form-element__label">Vacant Shifts</legend>
                                                    <div className="slds-form-element__control row">
                                                        <span className="slds-checkbox ">
                                                            <input type="checkbox" name="known_unknown_worker" id="known_unknown_worker" checked={(this.state.known_unknown_worker && this.state.known_unknown_worker == '1') ? true : false} disabled />
                                                            <label className="slds-checkbox__label" htmlFor="known_unknown_worker">
                                                                <span className="slds-checkbox_faux"></span>
                                                                <span className="slds-form-element__label">Can send known and unknown workers</span>
                                                            </label>
                                                        </span>

                                                        <span className="slds-checkbox ">
                                                            <input type="checkbox" name="meet_greet_required" id="meet_greet_required" checked={(this.state.meet_greet_required && this.state.meet_greet_required == '1') ? true : false} disabled />
                                                            <label className="slds-checkbox__label" htmlFor="meet_greet_required">
                                                                <span className="slds-checkbox_faux"></span>
                                                                <span className="slds-form-element__label">Meet and Greet required for all new staff</span>
                                                            </label>
                                                        </span>
                                                    </div>

                                                    <div className="slds-form-element__control row">
                                                        <span className="slds-checkbox ">
                                                            <input type="checkbox" name="shadow_shift" id="shadow_shift" checked={(this.state.shadow_shift && this.state.shadow_shift == '1') ? true : false} disabled />
                                                            <label className="slds-checkbox__label" htmlFor="shadow_shift">
                                                                <span className="slds-checkbox_faux"></span>
                                                                <span className="slds-form-element__label">Shadow Shift required for all new staff</span>
                                                            </label>
                                                        </span>

                                                        <span className="slds-checkbox ">
                                                            <input type="checkbox" name="worker_available" id="worker_available" checked={(this.state.worker_available && this.state.worker_available == '1') ? true : false} disabled />
                                                            <label className="slds-checkbox__label" htmlFor="worker_available">
                                                                <span className="slds-checkbox_faux"></span>
                                                                <span className="slds-form-element__label">Contact if no known worker available</span>
                                                            </label>
                                                        </span>

                                                        <span className="slds-checkbox ">
                                                            <input type="checkbox" name="cancel_shift" id="cancel_shift" checked={(this.state.cancel_shift && this.state.cancel_shift == '1') ? true : false} disabled />
                                                            <label className="slds-checkbox__label" htmlFor="cancel_shift">
                                                                <span className="slds-checkbox_faux"></span>
                                                                <span className="slds-form-element__label">Cancel shift if no known worker available</span>
                                                            </label>
                                                        </span>
                                                    </div>

                                                </fieldset>

                                                <fieldset className="slds-form-element mb-3">
                                                    <legend className="slds-form-element__legend slds-form-element__label">In home Support</legend>
                                                    <div className="slds-form-element__control row">
                                                        <span className="slds-checkbox ">
                                                            <input type="checkbox" name="hs_weekday" id="hs_weekday" checked={(this.state.hs_weekday && this.state.hs_weekday == '1') ? true : false} disabled />
                                                            <label className="slds-checkbox__label" htmlFor="hs_weekday">
                                                                <span className="slds-checkbox_faux"></span>
                                                                <span className="slds-form-element__label">Weekday</span>
                                                            </label>
                                                        </span>

                                                        <span className="slds-checkbox ">
                                                            <input type="checkbox" name="hs_saturday" id="hs_saturday" checked={(this.state.hs_saturday && this.state.hs_saturday == '1') ? true : false} disabled />
                                                            <label className="slds-checkbox__label" htmlFor="hs_saturday">
                                                                <span className="slds-checkbox_faux"></span>
                                                                <span className="slds-form-element__label">Saturday</span>
                                                            </label>
                                                        </span>
                                                    </div>

                                                    <div className="slds-form-element__control row">
                                                        <span className="slds-checkbox ">
                                                            <input type="checkbox" name="hs_sunday" id="hs_sunday" checked={(this.state.hs_sunday && this.state.hs_sunday == '1') ? true : false} disabled />
                                                            <label className="slds-checkbox__label" htmlFor="hs_sunday">
                                                                <span className="slds-checkbox_faux"></span>
                                                                <span className="slds-form-element__label">Sunday</span>
                                                            </label>
                                                        </span>

                                                        <span className="slds-checkbox ">
                                                            <input type="checkbox" name="hs_sleep_over" id="hs_sleep_over" checked={(this.state.hs_sleep_over && this.state.hs_sleep_over == '1') ? true : false} disabled />
                                                            <label className="slds-checkbox__label" htmlFor="hs_sleep_over">
                                                                <span className="slds-checkbox_faux"></span>
                                                                <span className="slds-form-element__label">Sleep Over</span>
                                                            </label>
                                                        </span>
                                                    </div>

                                                    <div className="slds-form-element__control row">
                                                        <span className="slds-checkbox ">
                                                            <input type="checkbox" name="hs_active_night" id="hs_active_night" checked={(this.state.hs_active_night && this.state.hs_active_night == '1') ? true : false} disabled />
                                                            <label className="slds-checkbox__label" htmlFor="hs_active_night">
                                                                <span className="slds-checkbox_faux"></span>
                                                                <span className="slds-form-element__label">Active Night</span>
                                                            </label>
                                                        </span>

                                                        <span className="slds-checkbox ">
                                                            <input type="checkbox" name="hs_public_holiday" id="hs_public_holiday" checked={(this.state.hs_public_holiday && this.state.hs_public_holiday == '1') ? true : false} disabled />
                                                            <label className="slds-checkbox__label" htmlFor="hs_public_holiday">
                                                                <span className="slds-checkbox_faux"></span>
                                                                <span className="slds-form-element__label">Public Holiday</span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                </fieldset>

                                            </div>

                                            <div className="col-md-6">

                                                <fieldset className="slds-form-element mb-3">
                                                    <legend className="slds-form-element__legend slds-form-element__label"> Dislikes</legend>
                                                        {this.state.disLikesSelection && this.state.disLikesSelection.map((data, i) =>
                                                        <label className="slds-radio__label" htmlFor="inout_chair_1">
                                                            <span className="slds-radio_faux"></span>
                                                            <span className="slds-form-element__label">{i != this.state.disLikesSelection.length - 1 ? data.label + ', ' : data.label}</span>
                                                        </label>
                                                    )}                                                            
                                                </fieldset>


                                                <fieldset className="slds-form-element mb-3">
                                                    <legend className="slds-form-element__legend slds-form-element__label"> Support Worker Language</legend>
                                                        {this.state.langSelection && this.state.langSelection.map((data, i) =>
                                                        <label className="slds-radio__label" htmlFor="inout_chair_1">
                                                            <span className="slds-radio_faux"></span>
                                                            <span className="slds-form-element__label">{i != this.state.langSelection.length - 1 ? data.label + ', ' : data.label}</span>
                                                        </label>
                                                    )}                                                            
                                                </fieldset>

                                                <fieldset className="slds-form-element mb-3">
                                                    <legend className="slds-form-element__legend slds-form-element__label">Support Worker Culture</legend>

                                                    {this.state.cultureSelection && this.state.cultureSelection.map((data, i) =>
                                                        <label className="slds-radio__label" htmlFor="inout_chair_1">
                                                            <span className="slds-radio_faux"></span>
                                                            <span className="slds-form-element__label">{i != this.state.cultureSelection.length - 1 ? data.label + ', ' : data.label}</span>
                                                        </label>
                                                    )}
                                                </fieldset>                                                

                                                <fieldset className="slds-form-element mb-3">
                                                    <legend className="slds-form-element__legend slds-form-element__label">Support Worker Gender</legend>
                                                    <div className="slds-form-element__control">
                                                        <span className="slds-radio slds-float_left">
                                                            <input type="radio" id="inout_chair_1" value="1" name="support_worker_gender" checked={(this.state.support_worker_gender && this.state.support_worker_gender == 1) ? true : false} disabled />
                                                            <label className="slds-radio__label" htmlFor="inout_chair_1">
                                                                <span className="slds-radio_faux"></span>
                                                                <span className="slds-form-element__label">Female</span>
                                                            </label>
                                                        </span>
                                                        <span className="slds-radio slds-float_left">
                                                            <input type="radio" id="inout_chair_2" value="2" name="support_worker_gender" checked={(this.state.support_worker_gender && this.state.support_worker_gender == 2) ? true : false} disabled />
                                                            <label className="slds-radio__label" htmlFor="inout_chair_2">
                                                                <span className="slds-radio_faux"></span>
                                                                <span className="slds-form-element__label">Male</span>
                                                            </label>
                                                        </span>
                                                        <span className="slds-radio slds-float_left">
                                                            <input type="radio" id="inout_chair_3" value="3" name="support_worker_gender" checked={(this.state.support_worker_gender && this.state.support_worker_gender == 3) ? true : false} disabled />
                                                            <label className="slds-radio__label" htmlFor="inout_chair_3">
                                                                <span className="slds-radio_faux"></span>
                                                                <span className="slds-form-element__label">Either</span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                </fieldset>

                                                <fieldset className="slds-form-element mb-3">
                                                    <legend className="slds-form-element__legend slds-form-element__label">Community Access Support</legend>
                                                    <div className="slds-form-element__control row">
                                                        <span className="slds-checkbox ">
                                                            <input type="checkbox" name="as_weekday" id="as_weekday" checked={(this.state.as_weekday && this.state.as_weekday == '1') ? true : false} disabled />
                                                            <label className="slds-checkbox__label" htmlFor="as_weekday">
                                                                <span className="slds-checkbox_faux"></span>
                                                                <span className="slds-form-element__label">Weekday</span>
                                                            </label>
                                                        </span>

                                                        <span className="slds-checkbox ">
                                                            <input type="checkbox" name="as_saturday" id="as_saturday" checked={(this.state.as_saturday && this.state.as_saturday == '1') ? true : false} disabled />
                                                            <label className="slds-checkbox__label" htmlFor="as_saturday">
                                                                <span className="slds-checkbox_faux"></span>
                                                                <span className="slds-form-element__label">Saturday</span>
                                                            </label>
                                                        </span>
                                                    </div>

                                                    <div className="slds-form-element__control row">
                                                        <span className="slds-checkbox ">
                                                            <input type="checkbox" name="as_sunday" id="as_sunday" checked={(this.state.as_sunday && this.state.as_sunday == '1') ? true : false} disabled />
                                                            <label className="slds-checkbox__label" htmlFor="as_sunday">
                                                                <span className="slds-checkbox_faux"></span>
                                                                <span className="slds-form-element__label">Sunday</span>
                                                            </label>
                                                        </span>

                                                        <span className="slds-checkbox ">
                                                            <input type="checkbox" name="as_sleep_over" id="as_sleep_over" checked={(this.state.as_sleep_over && this.state.as_sleep_over == '1') ? true : false} disabled />
                                                            <label className="slds-checkbox__label" htmlFor="as_sleep_over">
                                                                <span className="slds-checkbox_faux"></span>
                                                                <span className="slds-form-element__label">Sleep Over</span>
                                                            </label>
                                                        </span>
                                                    </div>

                                                    <div className="slds-form-element__control row">
                                                        <span className="slds-checkbox ">
                                                            <input type="checkbox" name="as_active_night" id="as_active_night" checked={(this.state.as_active_night && this.state.as_active_night == '1') ? true : false} disabled />
                                                            <label className="slds-checkbox__label" htmlFor="as_active_night">
                                                                <span className="slds-checkbox_faux"></span>
                                                                <span className="slds-form-element__label">Active Night</span>
                                                            </label>
                                                        </span>

                                                        <span className="slds-checkbox ">
                                                            <input type="checkbox" name="as_public_holiday" id="as_public_holiday" checked={(this.state.as_public_holiday && this.state.as_public_holiday == '1') ? true : false} disabled />
                                                            <label className="slds-checkbox__label" htmlFor="as_public_holiday">
                                                                <span className="slds-checkbox_faux"></span>
                                                                <span className="slds-form-element__label">Public Holiday</span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <fieldset className="slds-form-element mb-3">
                                                    <legend className="slds-form-element__legend slds-form-element__label">In Home Shift Tasks</legend>
                                                    <div className="slds-form-element__control">
                                                        <div className="SLDS_date_picker_width">
                                                          {this.state.in_home_shift_tasks}                                                            
                                                        </div>
                                                    </div>
                                                </fieldset>

                                                <fieldset className="slds-form-element mb-3">
                                                    <legend className="slds-form-element__legend slds-form-element__label">Sleep Over Details</legend>
                                                    <div className="slds-form-element__control">
                                                        <div className="SLDS_date_picker_width">
                                                        {this.state.sleep_over_details}
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>

                                            <div className="col-md-6">
                                                <fieldset className="slds-form-element mb-3">
                                                    <legend className="slds-form-element__legend slds-form-element__label">Community Access Shift Tasks</legend>
                                                    <div className="slds-form-element__control">
                                                        <div className="SLDS_date_picker_width">
                                                        {this.state.community_access_shift_tasks}
                                                        </div>
                                                    </div>
                                                </fieldset>

                                                <fieldset className="slds-form-element mb-3">
                                                    <legend className="slds-form-element__legend slds-form-element__label">Active Night Details</legend>
                                                    <div className="slds-form-element__control">
                                                        <div className="SLDS_date_picker_width">
                                                        {this.state.active_night_details}
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </BlockUi>
            </React.Fragment >
        );
    }

    addNewLanguage(lang) {
        return new Promise((resolve, reject) => {
            if (lang && lang.length) {
                let data = {
                    "operation": "A",
                    "type": "14",
                    "code": lang.toUpperCase(),
                    "display_name": lang,
                    "definition": ""
                };
                this.setState({ loading: true });
                postData('recruitment/RecruitmentReferenceData/save_ref_data', data).then((result) => {
                    if (result.status) {
                        let wlo = this.state.workerLangOption;
                        wlo.push({ id: result.id, label: lang });
                        this.setState({ workerLangOption: wlo });
                        resolve(result.id);
                    } else {
                        reject(result.error);
                        toastMessageShow(result.error, "e");
                    }
                    this.setState({ loading: false });
                });
            }
        });
    }
}


export default PreferencesCard