import React from 'react'
import { Card, Icon } from '@salesforce/design-system-react'
import { css } from '../../../../service/common'
import { IconSettings } from '@salesforce/design-system-react';

/**
 * @typedef {typeof AttachmentCard.defaultProps} Props
 * 
 * Card to display uploaded attachments
 * 
 * @extends {React.Component<Props>}
 */
class ShiftPreviousShiftCard extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            existingAttachments: this.props.attachments,
        }
    }
    render() {
        const styles = css({
            card: {
                border: '1px solid #dddbda',
                boxShadow: '0 2px 2px 0 rgba(0,0,0,.1)',
            },
            rowParent: {
                borderRadius: 0,
                borderBottom: 'none',
                borderLeft: 'none',
                borderRight: 'none',
            },
            // form: {
            //     display: 'block'
            // }
        })

        const existingAttachments = this.state.existingAttachments;

        return (
            <React.Fragment>
                <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                    <Card

                        heading={'Previous Shift Notes'}
                        style={styles.card}
                        icon={
                            <Icon
                                category="standard"
                                name="document"
                                size="small"
                            />
                        }
                    >

                        <div className="slds-box checking" style={styles.rowParent}>
                            <div className="row">

                                <div className="col col-sm-12 " style={{ marginBottom: 15 }}>

                                </div>

                            </div>
                        </div>


                    </Card>
                </IconSettings>
            </React.Fragment>
        )
    }
}

export default ShiftPreviousShiftCard