import moment from 'moment';
import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { Link } from 'react-router-dom';
import jQuery from "jquery";
import { ROUTER_PATH } from 'config.js';
import { checkItsLoggedIn, postData, getRemeber, setRemeber, setLoginToken, setFullName, setApplicantId, setPersonId ,setInterviewTypeId,settaskId,setTaskApplicationId,setMemberId} from 'service/common.js';
import { LOGIN_SVG, OCS_LOGO } from 'service/OcsConstant.js';
import { connect } from 'react-redux'
import { setHeaderFooterVisibility } from 'actions/PermissionAction.js';

import 'react-block-ui/style.css';

class LoginAuth extends Component {

    constructor(props) {
        super(props);
        this.state = {
            gender: 1,
            remember: '',
            loading: false,
            error: '',
            success: '',
            uuid_user_type: 2, 
        }

        checkItsLoggedIn(ROUTER_PATH);
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleChange(e) {
        var state = {};
        this.setState({ error: '' });
        state[e.target.name] = (e.target.type === 'checkbox' ? e.target.checked : e.target.value);
        this.setState(state);
    }

    onSubmit = (e) => {
        e.preventDefault();

        jQuery('#login').validate();
        if (!this.state.loading && jQuery('#login').valid()) {
            this.setState({ loading: true });

            postData('admin/Login/check_login', this.state).then((result) => {
                (this.state.remember == 1) ? setRemeber({ email: this.state.email, password: this.state.password, user_type: this.state.user_type }) : setRemeber({ email: '', password: '' });
                this.setState({ loading: false });
                if (result.status) {
                    this.setState({ success: <span><i className="icon icon-input-type-check"></i><div>{result.success}</div></span>, error: '' });
                    setLoginToken(result.data.token)
                   console.log(result.data);
                    if(result.data.applicant_info.applicant_id){
                        setApplicantId(result.data.applicant_info.applicant_id);
                        setFullName(result.data.applicant_info.applicant_name);
                        setPersonId(result.data.applicant_info.person_id);
                    }else{
                        setFullName(result.data.applicant_info.member_fullname);
                    }
                    setMemberId(result.data.applicant_info.member_id);
                    
                   
                    setInterviewTypeId(result.data.interview_type_id);
                    settaskId(result.data.task_id);
                    setTaskApplicationId(result.data.task_applicant_id);  


                    window.location = ROUTER_PATH + 'mydetails';
                } else {
                    this.setState({ error: <span><i className="icon ocs-warning2-ie"></i><div>{result.error}</div></span> });
                }
            });
        }
    }

    componentDidMount() {
        this.props.setHeaderFooterVisibility(false);
        var loginCookie = getRemeber();
        this.setState({ gender: loginCookie.gender });
        this.setState({ email: loginCookie.email });
        this.setState({ password: loginCookie.password });
    }

    render() {
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.loading}>

                    <section className="gradient_color">
                        <div className="container">
                            <div className="row ">
                                <div className="col-md-4 col-md-offset-4">
                                    <div className="logo text-center">
                                        <a href="#"><img className="img-fluid" width="70px" src={OCS_LOGO} /></a>
                                    </div>

                                    <div className="limiter">
                                        <div className="login_1">
                                            <div className="Smiley">
                                                <h1><span>Login here<i className="icon icon-smail-big"></i></span></h1>
                                            </div>

                                            <form id="login" className="login100-form" onSubmit={this.onSubmit} noValidate>
                                                <div className="col-md-12">
                                                    <div className="User d-flex">
                                                        <span><img src={LOGIN_SVG} className="img-fluid align-self-center" alt="IMG" /></span>
                                                    </div>

                                                    <div className="input_2">
                                                        <input className="input_3" type="email" name="email" placeholder="Email" value={this.state['email'] || ''} onChange={(e) => this.setState({ email: e.target.value.replace(/\s/g, '') })} data-rule-required="true" data-placement="right" data-msg-email="Enter a valid email" />
                                                    </div>


                                                    <div className="input_2">
                                                        <input className="input_3 required" type="password" name="password" placeholder="Password" value={this.state['password'] || ''} onChange={this.handleChange} data-rule-required="true" data-placement="right" title={`Enter your password`} />
                                                    </div>

                                                    <div className="login_but">
                                                        <button className="but_login orange" >
                                                            Submit
                                                            </button>
                                                    </div>

                                                    <div className="success_login s_new">{this.state.success}</div>
                                                    <div className="error_login e_new">{this.state.error}</div>
                                                </div>

                                                {/*<p className="col-md-12 text-center P_30_T">
                                                        <span className="b_check_2">
                                                            <input type="checkbox" className="checkbox2" id="c1" name="remember" value={this.state.remember || ''} checked={(this.state.remember)} onChange={this.handleChange} />
                                                            <label htmlFor="c1" ><span></span >Remember me</label>
                                                        </span>
                                                    </p>*/}
                                                <h5 className="col-md-12 text-center P_30_T text-center for_text"><Link to={ROUTER_PATH + 'forgot_password'}>Forgot Password?</Link></h5>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <footer className="text-center">
                        <div className="container">
                            <div className="row">
                                <h6>© {moment().format("YYYY")} All Rights Reserved <span>Healthcare Manager</span></h6>
                            </div>
                        </div>
                    </footer>
                </BlockUi>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    showHeaderFooter: state.Permission.showHeaderFooter,
})

const mapDispatchtoProps = (dispach) => ({
    setHeaderFooterVisibility: (status) => dispach(setHeaderFooterVisibility(status)),
})

export default connect(mapStateToProps, mapDispatchtoProps)(LoginAuth);
