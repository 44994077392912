import React from 'react'
import { css, postImageData, toastMessageShow, getLoginToken, postData } from '../../../../service/common'
import moment from 'moment';
import { IconSettings, Dropdown } from '@salesforce/design-system-react';
import SLDSReactSelect from 'components/admin/salesforce/lightning/SLDSReactSelect.jsx';
import ShiftGoalsTrackingData from './ShiftGoalsTrackingData.jsx';
import { getGoalsStartAndEndDate } from '../../../admin/oncallui-react-framework/services/common_filter';
/**
 * @typedef {typeof AttachmentCard.defaultProps} Props
 * 
 * Card to display uploaded attachments
 * 
 * @extends {React.Component<Props>}
 */
class ShiftGoalTracking extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            goals_data: [],
            selected_goal: 'all',
            account_id: this.props.account_id,
            account_type: this.props.account_type,
            date_list: [
                { label: 'Current Week', value: 'current_week' },
                { label: 'Last week', value: 'last_week' },
                { label: 'Current Month', value: 'current_month' },
                { label: 'Last Month', value: 'last_month' },
                { label: 'Two Months Back', value: 'two_months_back' },
            ],
            date_options: [],
            selected_date_type: 'current_week',
            start_end_date: getGoalsStartAndEndDate('current_week'),
            showGoalTracking: false
        }

        this.rootRef = React.createRef();
        this.reactTable = React.createRef();
    }

    componentDidMount() {
        if (this.state.account_id) {
            this.getParticipantGoals(this.state.account_id);
        }
        const current = new Date();
        current.setMonth(current.getMonth() - 1);
        let previousMonth = current.toLocaleString('default', { month: 'long' });

        current.setMonth(current.getMonth() - 1);
        const twoMonthsBack = current.toLocaleString('default', { month: 'long' });

        let date_data = this.state.date_list;
        date_data.map((opt, i) => {
            if (opt.value == 'last_month') {
                opt.label = previousMonth;
            }
            if (opt.value == 'two_months_back') {
                opt.label = twoMonthsBack;
            }
        })
        this.setState({ date_options: date_data, showGoalTracking: true })
    }

    getParticipantGoals = (id) => {
        postData("item/Goals/get_all_goals_list_by_participant", { participant_id: id }).then((res) => {
            if (res.status) {
                if (res.data) {
                    res.data.splice(0, 0, { "value": 'all', "label": 'All' });
                }
                this.setState({ goals_data: res.data });
            }
        });
    }

    /**
     * handling the status change event
     */
    handleChange = (value, key) => {


        this.setState({ [key]: value }, () => {
            if (key == "selected_date_type") {
                let start_end_date = getGoalsStartAndEndDate(value);
                this.setState({ start_end_date }, () => {
                    this.attachmentRef.refreshPage(this.state);
                })

            }

            if (key == "selected_goal") {
                this.attachmentRef.refreshPage(this.state);
            }

        });

    }

    render() {
        return (
            <React.Fragment>
                <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                    <div>
                        <div className="col-lg-6 col-sm-6 my-10">
                            <div className="slds-form-element">
                                <div className="slds-form-element__control">
                                    <div className="select_options_des">
                                        <div className="col-lg-2 col-sm-2 pt-1">
                                            <label class="slds-form-element__label">Goal</label>
                                        </div>
                                        <div className="col-lg-10 col-sm-10" style={{'padding': '0px'}}>
                                            <SLDSReactSelect
                                                simpleValue={true}
                                                className="custom_select default_validation goal-80"
                                                options={this.state.goals_data}
                                                onChange={value => {
                                                    this.handleChange(value, 'selected_goal')
                                                }}
                                                value={this.state.selected_goal}
                                                clearable={false}
                                                searchable={false}
                                                placeholder="Please Select"
                                                required={true}
                                                disabled={this.state.account_person === '' ? true : false}
                                                name="selected_goal"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-sm-6 my-10">
                            <div className="slds-form-element">
                                <div className="slds-form-element__control">

                                    <div className="select_options_des">
                                        <SLDSReactSelect
                                            simpleValue={true}
                                            className="custom_select default_validation goal-80"
                                            options={this.state.date_options}
                                            onChange={value => {
                                                this.handleChange(value, 'selected_date_type')
                                            }}
                                            value={this.state.selected_date_type}
                                            clearable={false}
                                            searchable={false}
                                            placeholder="Please Select"
                                            required={true}
                                            disabled={this.state.account_person === '' ? true : false}
                                            name="Selected Date"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.showGoalTracking && <ShiftGoalsTrackingData
                            ref={e => this.attachmentRef = e}
                            {...this.state}
                        ></ShiftGoalsTrackingData>}
                    </div>
                </IconSettings>

            </React.Fragment >
        )
    }
}

export default ShiftGoalTracking