import React, { Component } from 'react';
import 'react-select-plus/dist/react-select-plus.css';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import { postData, AjaxConfirm, toastMessageShow, reFreashReactTable } from 'service/common.js';
import { connect } from 'react-redux'

import PropTypes from 'prop-types';

import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';

import ButtonGroup from '@salesforce/design-system-react/lib/components/button-group';
import Button from '@salesforce/design-system-react/lib/components/button';
import PageHeader from '@salesforce/design-system-react/lib/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/lib/components/page-header/control';
import Icon from '@salesforce/design-system-react/lib/components/icon';

class MyQuizzes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'related',
            account_person: {},
            owner: {},
            contacts: [],
            opportunityStage:[],
            opportunity_status:'',
        }
    }

    get_opportunity_details = (id) => {
        postData('sales/Opportunity/view_opportunity', { opportunity_id: id }).then((result) => {
            if (result.status) {
                this.setState(result.data, () => { });
            } else {
                toastMessageShow('something went wrong', "e");
            }
        });
    }

    componentDidMount() { 
        // var opportunity_id = this.props.match.params.id;
        // this.getDrpDwnOption();
        // this.get_opportunity_details(opportunity_id);
    }

    getDrpDwnOption = () => {
        postData('sales/Opportunity/get_opportunity_option').then((result) => {
            if (result.status) {
                this.setState({ opportunityStage: result.data.opportunity_status }, () => {  })
            }
        });
    }

    closeModal = (status) => {
        this.setState({ openCreateModal: false });

        if (status) {
            reFreashReactTable(this, 'fetchData');
        }
    }

    actions = () => (
        <React.Fragment>
            <PageHeaderControl>
                <ButtonGroup variant="list" id="button-group-page-header-actions">
                    {/* <Button label="Edit"  onClick={e => {e.preventDefault();this.setState({ openCreateModal: true, selectedContactId: '' })}} />                     */}
                </ButtonGroup>
            </PageHeaderControl>
        </React.Fragment>
    );

    render() {
        const details = [
            {
                label: 'Start Date & Time',
                content: <div className="slds-truncate fields_inputs" title="Account">
                    <a className="SLDS_a_color" href="#">12/08/2020 , 15:00</a>
                </div>,
                truncate: true,
            },
            {
                label: 'End Date & Time',
                content: <div className="slds-truncate fields_inputs" title="Phone">
                    <a className="SLDS_a_color" href="#">12/08/2020 , 15:00</a>
                </div>
            },
            {
                label: 'Status',
                content: <div className="slds-truncate fields_inputs" title="Home Address">
                    <a className="SLDS_a_color" href="#">In Progress</a>
                </div>
            }
        ];

        return (
            <React.Fragment>
                <div className="container-fluid fixed_size">
                    <div className="slds-grid slds-grid_vertical ">
                        <div className="slds-col col-lg-12 custom_page_header">

                            <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                                <PageHeader
                                    details={details}
                                    icon={
                                        <Icon
                                            assistiveText={{ label: 'User' }}
                                            category="standard"
                                            name="contact"
                                        />
                                    }
                                    label="Quizzes"
                                    onRenderActions={this.actions}
                                    // title={this.state.page_title}
                                    title='Quizzes'
                                    variant="record-home"
                                    className="mydetails"
                                />
                            </IconSettings>
                        </div>
                    </div >
                </div>
            </React.Fragment >
        );
    }
}

ReactTable.PropTypes = {
    defaultFiltered: PropTypes.object
}
const mapStateToProps = state => ({
})

const mapDispatchtoProps = (dispach) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchtoProps)(MyQuizzes);
