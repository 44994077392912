import React, { Component } from 'react';
import 'react-select-plus/dist/react-select-plus.css';
import 'react-block-ui/style.css';
import 'service/jquery.validate.js';
import "service/custom_script.js";
import { postData, toastMessageShow, handleChange } from 'service/common.js';
import { living_situation_option, living_informal_option, lack_of_living_informal_option } from 'dropdown/SalesDropdown.js';
import '../../../scss/components/admin/salesforce/lightning/salesforce-lightning-design-system.scss';
import { ExpandableSection } from '@salesforce/design-system-react';


/**
 * Class: CreateLivingSituation
 */
class CreateLivingSituation extends Component {
    constructor(props) {
        super(props);

        // Initialize state
        this.state = {
            isOpenLivSitun: true,
        }
    }

    getLivingDetails = (id) => {
        postData('sales/RiskAssessment/get_living_situation_details', { risk_assessment_id: id }).then((result) => {
            if (result.status) {
                this.setState(result.data)
            } else {
                // Trigger error pop 
                toastMessageShow(result.error, "e");
            }
            this.setState({ loading: false });
        });
    }

    componentDidMount() {
        this.getLivingDetails(this.props.risk_assessment_id);
    }

    /**
     * Render the display content
     */
    render() {
        var _living_situation_option = living_situation_option();
        var _living_informal_option = living_informal_option();
        var _lack_of_living_informal_option = lack_of_living_informal_option();


        return (
            <React.Fragment>
                <ExpandableSection
                    id="controlled-expandable-section"
                    isOpen={this.state.isOpenLivSitun}
                    onToggleOpen={(event, data) => {
                        this.setState({ isOpenLivSitun: !this.state.isOpenLivSitun });
                    }}
                    title="Living Situation"
                >
                    <div className="container-fluid">
                        <form id="living_situation" autoComplete="off" className="slds_form">
                            <div class="slds-form">

                                <label for="living_situation" class="error CheckieError" style={{ display: "block", width: "20%", marginBottom: '0px' }}></label>
                                <fieldset class="slds-form-element slds-m-top_medium">
                                    <legend class="slds-form-element__legend slds-form-element__label">
                                        <abbr class="slds-required" title="required">* </abbr>Living Situation</legend>

                                    <div class="">
                                        {_living_situation_option.map((val, index) => (
                                            <>
                                                <span class="slds-radio" key={index + 1}>
                                                <input
                                                                type="radio"
                                                                id={"alone" + index}
                                                                value={val.value}
                                                                name="living_situation"
                                                                required={true}
                                                                onChange={(e) => {
                                                                    handleChange(this, e);
                                                                    if (e.target.value != 4) {
                                                                        this.setState({ living_situation_agency: "" })
                                                                    }
                                                                }}
                                                                checked={val.value == this.state.living_situation ? true : false}
                                                                disabled
                                                            />
                                                    <label class="slds-radio__label" for={"alone" + index}>
                                                        <span class="slds-radio_faux"></span>
                                                        <span class="slds-form-element__label">{val.label}</span>
                                                    </label>
                                                </span>

                                                {val.sda_agencey_name ?
                                                    <div>
                                                        <label class="slds-form-element__label" for="Agency">Agency</label>
                                                        <div class="slds-form-element__control">
                                                           {this.state.living_situation_agency }
                                                        </div>
                                                    </div> : ""}
                                            </>
                                        ))}
                                    </div>
                                </fieldset>


                                <fieldset class="slds-form-element slds-m-top_medium">
                                    <label for="informal_support" class="error CheckieError" style={{ display: "block", width: "20%", marginBottom: '0px' }}></label>
                                    <legend class="slds-form-element__legend slds-form-element__label">
                                        <abbr class="slds-required" title="required">* </abbr>Informal Support</legend>
                                    <div class="">
                                        {_living_informal_option.map((val, index) => (
                                            <>
                                                <span class="slds-radio">
                                                    <input
                                                        type="radio"
                                                        id={"Informal_" + index}
                                                        value={val.value}
                                                        name="informal_support"
                                                        required={true}                                                        
                                                        checked={val.value == this.state.informal_support ? true : false}
                                                        disabled
                                                    />
                                                    <label class="slds-radio__label" for={"Informal_" + index}>
                                                        <span class="slds-radio_faux"></span>
                                                        <span class="slds-form-element__label">{val.label}</span>
                                                    </label>
                                                </span>

                                                {val.other ?
                                                    <div class="slds-form-element__control">
                                                        {this.state.informal_support_describe || ""}
                                                        {/* <input name="informal_support_describe" type="text" disabled placeholder="" class="slds-input" required={true} onChange={(e) => handleChange(this, e)} value={this.state.informal_support_describe || ""} /> */}
                                                    </div> : ""}
                                            </>
                                        ))}
                                    </div>
                                </fieldset>


                                <fieldset class="slds-form-element slds-m-top_medium slds-m-bottom_medium">
                                    <label for="lack_of_informal_support" class="error CheckieError" style={{ display: "block", width: "20%", marginBottom: '0px' }}></label>
                                    <legend class="slds-form-element__legend slds-form-element__label">
                                        <abbr class="slds-required" title="required">* </abbr>Risk due to lack of informal support</legend>
                                    <div class="">
                                        {_lack_of_living_informal_option.map((val, index) => (
                                            <>
                                                <span class="slds-radio">
                                                    <input
                                                        type="radio"
                                                        id={"lack_" + index}
                                                        value={val.value}
                                                        name="lack_of_informal_support"
                                                        required={true}
                                                        onChange={(e) => handleChange(this, e)}                                                        
                                                        checked={val.value == this.state.lack_of_informal_support ? true : false}
                                                        disabled
                                                    />
                                                    <label class="slds-radio__label" for={"lack_" + index}>
                                                        <span class="slds-radio_faux"></span>
                                                        <span class="slds-form-element__label">{val.label}</span>
                                                    </label>
                                                </span>

                                                {val.other ?
                                                    <div class="slds-form-element__control">
                                                        {this.state.lack_of_informal_support_describe || ""}
                                                        {/* <input name="lack_of_informal_support_describe" disabled type="text" id="stacked-input-id-01"
                                                            class="slds-input" required={true} onChange={(e) => handleChange(this, e)} value={this.state.lack_of_informal_support_describe || ""} /> */}
                                                    </div> : ""}
                                            </>
                                        ))}
                                    </div>
                                </fieldset>
                            </div>
                        </form>
                    </div>

                </ExpandableSection>
            </React.Fragment>
        );
    }
}

export default CreateLivingSituation;
