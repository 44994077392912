import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { postData, archiveALL, css, toastMessageShow, getApplicantId, getMemberId, } from '../../../service/common.js';
import Select from 'react-select-plus';
import 'react-select-plus/dist/react-select-plus.css';
import { ToastUndo } from 'service/ToastUndo.js'

import { connect } from 'react-redux'

import DataTableListView from '../oncallui-react-framework/view/ListView/DataTableListView.jsx';
import {
    Dropdown,
    IconSettings,
    PageHeaderControl,
    Button,DataTableColumn, DropdownTrigger, ButtonGroup } from '@salesforce/design-system-react';

import {updatenotificationMarkAsReadById, getUnreadNotificationCount } from '../notification/actions/NotificationAction';

import moment from 'moment-timezone';
// globale varibale to stote data
var rawData = [];
const selectFilterOptions = [
    { field: "title", label: "Title", value: "title", order: "1" },
    { field: "shortdescription", label: "Short Description", value: "shortdescription", order: "2" },
    { field: "notification_type", label: "Type", value: "notification_type", order: "3" },
    { field: "notification_status", label: "Status", value: "notification_status", order: "4" },
    { field: "created", label: "Raised On", value: "Raised On", order: "5" },
]

/*
 * class ListNotification
 */
class NotificationList extends Component {

    /**
     * default displayed columns in the listing
     */
     static defaultProps = {
        displayed_columns: {
            "title": true,
            "shortdescription": true,
            "notification_type": true,
            "created": true,
            "notification_status": true,
            "actions": true
        }
    }

    constructor(props) {
        super(props);

        this.filterType = [{ label: 'Select', value: '' }, { label: 'Archive only', value: 'archive_only' }, { label: 'Active only', value: 'active_only' }, { label: 'Inactive only', value: 'inactive_only' }]
        this.state = {
            loading: false,
            userList: [],
            counter: 0,
            startDate: new Date(),
            search: '',
            search_by: '',
            selection: [],
            refreshTable: false,
            default_displayed_columns: Object.keys(props.displayed_columns).filter(k => !!props.displayed_columns[k]),
            displayed_columns: Object.keys(props.displayed_columns).filter(k => !!props.displayed_columns[k]),
            filter_val: '',
            selectfilteroperatoroptions: [
                { value: "equals", label: "Equals to", symbol: "=" },
                { value: "not_equal", label: "Not equal to", symbol: "!=" },
                { value: "less_than", label: "Less than", symbol: "<" },
                { value: "less_than_equal", label: "Less than equal to", symbol: "<=" },
                { value: "greater_than", label: "Greater than", symbol: ">" },
                { value: "greater_than_equal", label: "Greater than equal to", symbol: ">=" },
                { value: "contains", label: "Contains", symbol: "%.%" },
                { value: "not_contain", label: "Does not contain", symbol: "!%.%" },
                { value: "starts_with", label: "Starts with", symbol: "%" }
            ],
            filtered: false,
            checkedItem: 0,
        };
    }


    refreashReactTable = () => {
        // if filter is update then react table automaticallty update on change to filter
        // and even odd functionality use beacuse same change it does not refreash react table
        if (this.state.counter % 2 == 0) {
            this.setState({ counter: this.state.counter + 1, filtered: {} });
        } else {
            this.setState({ counter: this.state.counter + 1, filtered: false });
        }
    }

    searchTable = (search_by) => {
        var searchData = { search: this.state.search, search_by: search_by };
        this.setState({ filtered: searchData, search_by: search_by });
    }

    submitSearch = (e) => {
        e.preventDefault();
        // reflec value of search box
        this.searchTable(this.state.search, this.state.search_by);
    };

    archiveHandle = (id) => {
        alert()
        archiveALL({ id: id }, 'Are you you won’t to move Archive', 'admin/Dashboard/delete_user').then((result) => {
            if (result.status) {
                this.refreashReactTable();
            } else {
                this.setState({ loading: false });
                toast.dismiss();
                toast.error(<ToastUndo message={result.error} showType={'e'} />, {
                    // toast.error(result.error, {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });
            }
        })
    }

    activeDeactiveHandle = (id, status) => {
        this.setState({ loading: true });
        postData('admin/Dashboard/active_inactive_user', { 'adminID': id, 'status': status }).then((result) => {
            if (result.status) {
                this.refreashReactTable();
            } else {
                this.setState({ loading: false });
                toast.dismiss();
                toast.error(<ToastUndo message={result.error} showType={'e'} />, {
                    // toast.error(result.error, {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });
            }
        });
    }

    determineColumns() {
        return [
            { _label: 'Title', accessor: 'title',
            multipleCustomUrl: 'notification_title',
            width: '200px'},

            {_label: 'Description', accessor: 'shortdescription',
            },
            { _label: 'Type', accessor: 'notification_type', width: '100px'},
            { _label: 'Raised On', accessor: 'created', id: 'created',
                width: '200px', CustomDateFormat: "DD/MM/YYYY HH:mm A"
            },
            { _label: 'Status', accessor: 'notification_status', width: '100px',col_modification: true},
            { _label: '', accessor: 'actions',
            sortable: false,
            width: '50px',
            actionList : [
                {
                    id: 0,
                    label: 'Mark as read',
                    value: '1',
                    key: 'read'
                },
            ]},
        ]
    }
    componentDidMount() {
        //This will clear the existing filter local storage value
        this.setState({ selectedfilval: [] });
        this.setState({ filter_logic: '' });
        sessionStorage.removeItem('filterarray');
    }
    handleMarkMultipleAsRead = (item) => {

        if(this.state.selection.length <= 0) {
            toastMessageShow("Please select at least one Notification", "e");
            return false;
        }

        this.props.handleMarkMultipleAsRead({ notification_data: this.state.selection});
        this.refreshListView();
    }

    handleOnRenderActions() {
        return (
            <PageHeaderControl>
                <ButtonGroup variant="list" id="button-group-page-header-actions">
                    <Button label="Mark as read" id="mark_read" title={`Mark as read`} onClick={e => this.handleMarkMultipleAsRead()} />
                </ButtonGroup>
            </PageHeaderControl>
        )
    }

    handleChanged = (event, data) => {
        let dataSelection = data.selection;
        let selection_count = dataSelection.length;
		this.setState({ selection: data.selection, checkedItem: selection_count });
	};

    showModal = (item, action) => {
        let data = [];
        data[0] = item;
        this.props.handleMarkMultipleAsRead({notification_data: data}).then(() => {
            this.props.getUnreadNotificationCountact({member_id: getMemberId(), applicant_id: getApplicantId()}); 
        });
        this.refreshListView();
      
    }

    refreshListView() {
        this.setState({refreshTable: !this.state.refreshTable, selection:[]}, ()=>{});
    }

     /***
     * Reset selection List
     */
      resetSelection() {
        this.setState({ selection:[] });
    }
    render() {

        const styles = css({
            root: {
                fontFamily: "Salesforce Sans, Arial, Helvetica, sans-serif",
                marginRight: -15,
            }
        })

        return (
            <React.Fragment>
                <div className="ListContact slds" style={styles.root} ref={this.rootRef}>
                    <DataTableListView
                        page_name="Notifications"
                        header_icon="date_input"
                        default_filter_logic="1 AND 2"
                        list_api_url="admin/notification/get_notification_list_for_member"
                        filter_options={
                            selectFilterOptions
                        }
                        determine_columns={this.determineColumns}
                        on_render_actions={() => this.handleOnRenderActions()}
                        displayed_columns={this.props.displayed_columns}
                        filter_title="Notifications"
                        filtered={this.state.filtered}
                        defaultPageSize={10}
                        related_type="notification"
                        selectRows="checkbox"
                        selection={this.state.selection}
                        resetSelection={this.resetSelection.bind(this)}
                        selectionHandleChange={this.handleChanged.bind(this)}
                        checkedItem={this.state.checkedItem}
                        get_default_pinned_data={false}
                        is_header_info={false}
                        is_list_view_control={false}
                        refresh={this.state.refreshTable}
                        sortColumnLabel="Raised On"
                        sortColumn="created"
                        showModal={this.showModal}
                        show_filter={true}
                        custom_header_filter={true}
                        member_id={getMemberId() !== 'null' ? getMemberId() : ''}
                        applicant_id={getApplicantId()}
                        showaddfiltbtn={true}
                        selectedfilval={false}
                    />

                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    NotificationType: state.NotificationReducer.NotificationType,
    NotificationCount: state.NotificationReducer.NotificationCount,
    IsAllRead: state.NotificationReducer.IsAllRead,
    NotificationData: state.NotificationReducer.NotificationData,
    notification_unread_count: state.NotificationReducer.notificationUnreadCount,
})

const mapDispatchtoProps = (dispach) => {
    return {
        handleMarkMultipleAsRead: (index) => dispach(updatenotificationMarkAsReadById(index)),
        getUnreadNotificationCountact: (object) => dispach(getUnreadNotificationCount(object)),
    }
}

export default connect(mapStateToProps, mapDispatchtoProps)(NotificationList);
