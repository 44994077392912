import React from 'react';
import 'react-select-plus/dist/react-select-plus.css';
import 'react-table/react-table.css'
import {
    IconSettings
} from '@salesforce/design-system-react';

/**
 * Specialized `SLDSLeftColContent` for container 1 content
 */
const SLDSLeftColContent = React.forwardRef(
    /**
     * @param {Props} props
     */
    (props) => {
    return (
        <>
            {props.leftColContent}
        </>
    )
})

/**
 * Specialized `SLDSRightColContent` for container 2 content
 */
const SLDSRightColContent = React.forwardRef(
    /**
     * @param {Props} props
     */
    (props) => {
    return (
        <>
            {props.rightColContent}
        </>
    )
})

/**
 * Split container
 */
export const SplitContainer = React.forwardRef(
/**
 * @param {Props} props
 */
(props) => {
    const left_cols = (props.left_cols) ? props.left_cols : 8;
    const right_cols = (props.right_cols) ? props.right_cols : 4;

    return (
        <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
            <div className="slds-col detailspage slds-row-wid-max">
                <div className="slds-grid slds-wrap slds-gutters_x-small">
                    {(props.leftColContent) ? 
                    <div className={"slds-col slds-m-top_medium slds-size_1-of-1 slds-medium-size_1-of-1 slds-large-size_"+left_cols+"-of-12 slds-p-right_small"}>
                        <div className="white_bg_color slds-box ">
                            <SLDSLeftColContent {...props}/>
                        </div>
                    </div> : ''}
                    {(props.rightColContent) ? 
                    <div className={"slds-col slds-m-top_medium slds-size_1-of-1 slds-medium-size_1-of-1 slds-large-size_"+right_cols+"-of-12"}>
                        <div className="white_bg_color slds-box">
                            <SLDSRightColContent {...props}/>
                        </div>
                    </div> : ''}
                </div>                        
            </div>
        </IconSettings>
    )
})


/**
 * Specialized `SLDSColContent` for content
 */
const Content = React.forwardRef(
    /**
     * @param {Props} props
     */
    (props) => {
    return (
        <>
            {props.content}
        </>
    )
})

/**
 * Single container
 */
export const SingleContainer = React.forwardRef(
    /**
     * @param {Props} props
     */
    (props) => {
        return (
            <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                <div className="slds-col detailspage slds-row-wid-max">
                    <div className="slds-grid slds-wrap slds-gutters_x-small">
                        <div className="slds-col slds-m-top_medium slds-size_1-of-1 slds-medium-size_1-of-1 slds-large-size_1-of-1 slds-p-right_small">
                            <div className="white_bg_color slds-box ">
                                <Content {...props}/>
                            </div>
                        </div>
                    </div>                        
                </div>
            </IconSettings>
        )
    })

/**
 * Default props set for Split Container
 */
const defaultPropsSplit = {
    leftColContent: <React.Fragment> Coming Soon ...</React.Fragment>,
    rightColContent: <React.Fragment> Coming Soon ...</React.Fragment>,
}

SplitContainer.defaultProps = defaultPropsSplit;

/**
 * Default props set for Single Container
 */
const defaultPropsSingle = {
    content: <React.Fragment> Coming Soon ...</React.Fragment>,
}

SingleContainer.defaultProps = defaultPropsSingle;

