import React, { Component } from 'react'
import classNames from 'classnames'
import '../scss/components/admin/crm/pages/sales/opportunity/OpportunityDetails.scss'
import { Icon ,Modal,Button} from '@salesforce/design-system-react';
import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';



export default function SystemAlertPopup(props){

   /**copy to clipboard */
   const copyToClipboard=()=>{
    navigator.clipboard.writeText('1300 962 468');
    props.closeAlert()
   }
console.log(props.shiftStatus,'props')

    return(
        <Modal
        isOpen={true}
        heading={props.shiftStatus==4? "Start a shift":props.shiftStatus==5?"Complete a shift":'System Alert'}
        size="small"
        className="slds_custom_modal"
        onRequestClose={() => props.closeAlert()}
        dismissOnClickOutside={false}
    >
<section className="manage_top py-2" >
     <div className="container-fluid py-2">
        <div className="col-lg-12 py-2">
          <div className="row py-2" style={{fontSize:14+'px'}}dangerouslySetInnerHTML={{__html: props.alertMessage }} />
     
       <div style={{float:'right'}}>
        <Button label="Copy Contact Number" variant="neutral" onClick={()=>copyToClipboard()} >
        <Icon
        style={{'position': 'relative',
        'top': -2+'px',
        'left': 2+'px'}}
            assistiveText={{ label: 'copy' }}
            category="utility"
            name="copy"
            size="small"
        />
       </Button>
        </div>
         </div>
         </div>
         </section>
         </Modal>

    )


}