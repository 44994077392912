import React, { Component } from 'react';
import jQuery from "jquery";
import 'react-select-plus/dist/react-select-plus.css';
import { checkItsNotLoggedIn, postData, toastMessageShow, css, handleChange, handleDecimalChange, comboboxFilterAndLimit, getApplicantId, getMemberId } from 'service/common.js';
import { Icon } from '@salesforce/design-system-react';
import 'react-block-ui/style.css';
import Modal from '@salesforce/design-system-react/lib/components/modal';
import Button from '@salesforce/design-system-react/lib/components/button';
import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import 'service/jquery.validate.js';
import "service/custom_script.js";
import SLDSReactSelect from 'components/admin/salesforce/lightning/SLDSReactSelect.jsx';
import Combobox from '@salesforce/design-system-react/lib/components/combobox';

const styles = css({
    root: {
        fontFamily: `Salesforce Sans, Arial, Helvetica, sans-serif`,
        zIndex: 12,
    },
    backdrop: {
        zIndex: 11,
    },
})


class CreateMemberModal extends Component {

    /**
     * setting the initial prop values
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        checkItsNotLoggedIn();

        this.state = {
            loading: false,
            id: '',
            fullname: '',
            account_person: '',
            like_selection_ids: [],
            language_selection_ids: [],
            transport_selection_ids: [],
            hours_per_week: '',
            like_options: [],
            like_selection: [],
            language_selection: [],
            language_options: [],
            transport_options: [],
            transport_selection: [],
            status_options: [{ value: "1", label: "Active" }, { value: "0", label: "Inactive" }],
            status: 1,
            applicant_id: getApplicantId(),
            member_id: getMemberId(),
        }
    }

    /**
     * handling the status change event
     */
    handleChange = (value, key) => {
        this.setState({ [key]: value });
    }

    /**
     * when submit button is clicked on the modal
     */
    onSubmit = (e) => {
        e.preventDefault();
        jQuery("#update_member").validate({ /* */ });
        this.setState({ validation_calls: true })
        if (!this.state.loading && jQuery("#update_member").valid()) {
            this.setState({ loading: true });

            var req = {
                member_id: this.state.member_id, applicant_id: this.props.applicantId, 
                hours_per_week: this.state.hours_per_week, language_selection: this.state.language_selection,
                transport_selection: this.state.transport_selection,
                like_selection: this.state.like_selection,
                created_by: this.state.updated_by ? this.state.updated_by : this.state.created_by,
                max_dis_to_travel: this.state.max_dis_to_travel, 
                mem_experience: this.state.mem_experience
            }
            postData('recruitment/Recruitment_member/update_member', req).then((result) => {
                if (result.status) {
                    let msg = result.hasOwnProperty('msg') ? result.msg : '';
                    toastMessageShow(result.msg, 's');
                    this.props.closeModal(true);
                } else {
                    toastMessageShow(result.error, "e");
                }
                this.setState({ loading: false });
            });
        }
    }

    /**
     * mounting all the components
     */
    componentDidMount() {
        if (this.props.applicantId) {
            this.get_member_details(this.props.applicantId);
        }
        
            this.get_member_details(getMemberId());
        
        this.getReferenceData(getMemberId());
    }

    /**
     * fetching the member details if the modal is opened in the edit mode
     */
    get_member_details = (id) => {
        let req = {};
        if(this.state.applicant_id){
            req = {applicant_id: this.state.applicant_id}
        }else{
            req = {member_id: this.state.member_id}
        }  
        postData('recruitment/Recruitment_member/get_applicant_member_details_by_id', req).then((result) => {
            if (result.status) {
                this.setState(result.data);
            } else {
                toastMessageShow(result.error, "e");
            }
        });
    }

    /**
     * fetching the reference data of member's object
     */
    getReferenceData = (id) => {
        let req = {};
        if(this.state.applicant_id){
            req = {applicant_id: this.state.applicant_id}
        }else{
            req = {member_id: this.state.member_id}
        }  
        postData("recruitment/Recruitment_member/get_reference_data", req).then((res) => {
            if (res.status) {
                this.setState({
                    like_options: (res.data.likes) ? res.data.likes : [],
                    language_options: (res.data.language) ? res.data.language : [],
                    transport_options: (res.data.language) ? res.data.transport : []
                })
            }
        });       
    }

    /**
     * Rendering likes, transport and language multi selection boxes
     * @param {str} label 
     * @param {str} stateName - selection state
     * @param {str} valueName - value state
     */
    renderCCComboBox = (id, selection_options, stateName, valueName) => {
        return (
            <Combobox
                id={id}
                predefinedOptionsOnly
                disabled={this.props.disabled}
                events={{
                    onChange: (event, { value }) => {
                        if (this.props.action) {
                            this.props.action('onChange')(event, value);
                        }
                        this.setState({ [valueName]: value });
                    },
                    onRequestRemoveSelectedOption: (event, data) => {
                        this.setState({
                            [valueName]: '',
                            [stateName]: data.selection,
                        });
                    },
                    onSubmit: (event, { value }) => {
                        if (this.props.action) {
                            this.props.action('onChange')(event, value);
                        }
                        this.setState({
                            [valueName]: '',
                            [stateName]: [
                                ...this.state[stateName],
                                {
                                    label: value,
                                    icon: (
                                        <Icon
                                            assistiveText={{ label: 'Account' }}
                                            category="standard"
                                            name="account"
                                        />
                                    ),
                                },
                            ],
                        });
                    },
                    onSelect: (event, data) => {
                        if (this.props.action) {
                            this.props.action('onSelect')(
                                event,
                                ...Object.keys(data).map((key) => data[key])
                            );
                        }
                        this.setState({
                            [valueName]: '',
                            [stateName]: data.selection,
                        });
                    },
                }}

                menuMaxWidth="500px"
                menuItemVisibleLength={5}
                multiple
                options={comboboxFilterAndLimit(
                    this.state[valueName],
                    5,
                    this.state[selection_options],
                    this.state[stateName],
                )}
                selection={this.state[stateName]}
                value={this.state[valueName]}
            />
        );
    }

    /**
     * rendering components
     */
    render() {
        return (
            <IconSettings iconPath={"/assets/salesforce-lightning-design-system/assets/icons"}>
                <div className="popupheader">
                    <Modal
                        isOpen={this.props.showModal}
                        footer={[
                            <Button disabled={this.state.loading} label="Cancel" onClick={() => this.props.closeModal(false)} />,
                            <Button disabled={this.state.loading} label="Save" variant="brand" onClick={this.onSubmit} />,
                        ]}
                        onRequestClose={this.toggleOpen}
                        heading={this.props.applicantId ? "Update Member" : " "}
                        size="small"
                        className="slds_custom_modal"
                        onRequestClose={() => this.props.closeModal(false)}
                    >

                        <section className="manage_top" >
                            <div className="container-fluid">
                                <form id="update_member" autoComplete="off" className="slds_form">
                                    <div className="row py-2">
                                        <div className="col-lg-6 col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" htmlFor="select-01">Transport</label>
                                                <div className="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right" role="none">
                                                    <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                                                        {this.renderCCComboBox("transports", "transport_options", "transport_selection", "transport_selection_ids")}
                                                    </IconSettings>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" htmlFor="select-01">Likes</label>
                                                <div className="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right" role="none">
                                                    <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                                                        {this.renderCCComboBox("likes", "like_options", "like_selection", "like_selection_ids")}
                                                    </IconSettings>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row py-2">


                                        <div className="col-lg-6 col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" htmlFor="select-01">Languages</label>
                                                <div className="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right" role="none">
                                                    <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                                                        {this.renderCCComboBox("languages", "language_options", "language_selection", "language_selection_ids")}
                                                    </IconSettings>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" >Experience (In Years) </label>
                                                <div className="slds-form-element__control">
                                                    <input type="text"
                                                        name="mem_experience"
                                                        placeholder="Experience (In Years)"
                                                        onChange={(e) => {
                                                            if (!isNaN(e.target.value)) {
                                                                handleDecimalChange(this, e);
                                                            }
                                                        }}
                                                        maxLength="9"
                                                        value={this.state.mem_experience || ''}
                                                        className="slds-input" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row py-2">
                                        <div className="col-lg-6 col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" htmlFor="select-01">Max distance to travel (in Kms)</label>
                                                <div className="slds-form-element__control" role="none">
                                                    <input type="text"
                                                        name="max_dis_to_travel"
                                                        placeholder="Max distance to travel (in Kms)"
                                                        onChange={(e) => {
                                                            if (!isNaN(e.target.value)) {
                                                                handleDecimalChange(this, e);
                                                            }
                                                        }}
                                                        maxLength="9"
                                                        value={this.state.max_dis_to_travel || ''}
                                                        className="slds-input" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" >Hours per week</label>
                                                <div className="slds-form-element__control">
                                                    <input type="text"
                                                        name="hours_per_week"
                                                        placeholder="Hours per week"
                                                        onChange={(e) => handleChange(this, e)}
                                                        value={this.state.hours_per_week || ''}
                                                        data-rule-maxlength="6"
                                                        maxLength="6"
                                                        data-msg-number="Please enter valid number"
                                                        className="slds-input" 
                                                        disabled={true} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-sm-6">
                                            &nbsp;
                                    </div>
                                    </div>

                                    <div className="row py-2">&nbsp;</div>
                                </form>
                            </div>
                        </section>

                    </Modal>
                </div>
            </IconSettings>
        );
    }
}

export default CreateMemberModal;
