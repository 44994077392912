import { combineReducers } from 'redux'

import Permission from './Permission'
import sidebarData from '../components/admin/reducer/SidebarReducer'
import CommonReducer from '../components/admin/reducer/CommonReducer'
import NotificationReducer from '../components/admin/notification/reducer/NotificationReducer'

export default combineReducers({
  Permission,
  sidebarData,
  CommonReducer,
  NotificationReducer
})
