import React from 'react'
import _ from 'lodash'
import { css } from 'service/common'

/**
 * @typedef {typeof RiskMatrixDetails.defaultProps} Props
 *
 * Displays the risk matrix evaluation table with heading and an edit button
 * 
 * @extends {React.Component<Props>}
 */
class RiskMatrixDetails extends React.Component {

    static defaultProps = {
        risk_assessment_id: null,
        probability_options: [],
        impact_options: [],
        matrices: [],
        onUpdate: () => {},
        score_details: { // probability * impact (not the other way around)
            1: {
                1: 'Very low',
                2: 'Very low',
                3: 'Low',
                4: 'Medium',
                5: 'Medium',
            },
            2: {
                1: 'Very low',
                2: 'Low',
                3: 'Medium',
                4: 'Medium',
                5: 'High',
            },
            3: {
                1: 'Low',
                2: 'Medium',
                3: 'Medium',
                4: 'High',
                5: 'Very high',
            },
            4: {
                1: 'Medium',
                2: 'Medium',
                3: 'High',
                4: 'Very high',
                5: 'Extreme',
            },
            5: {
                1: 'Medium',
                2: 'High',
                3: 'Very high',
                4: 'Extreme',
                5: 'Extreme',
            }
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false,
        }
    }

    handleSave = e => {
        e.preventDefault()
    }

    get_probability_data = (id) => {
        const probability = (this.props.probability_options.find(p => p.id == id) || {});
        return probability.name
    }

    get_impact_data = (id) => {
        const impact = (this.props.impact_options.find(p => p.id == id) || {})
        return impact.name
    }

    get_score_data = (data) => {
        if (!data.probability_id || !data.impact_id) {
            return ''
        }

        const probability = (this.props.probability_options.find(p => data.probability_id == p.id) || {}).multiplier;
        const impact = (this.props.impact_options.find(p => data.impact_id == p.id) || {}).multiplier;

        return probability * impact;

    }

    renderTable() {
        return (
            <div className="slds-grid">
            <div className="slds-panel slds-size_full slds-is-open" aria-hidden="false">
                <div className="slds-m-horizontal_small slds-p-vertical_x-small ml-0">
                    <div className="slds-tile slds-tile_board slds-m-horizontal_small ml-0">
                        <div className="slds-tile__detail">
                            {this.props.matrices && this.props.matrices.map((diagnosis)=>
                                <div className="col-sm-12 dia_form_siz">
                                <div className="form-group m-0">
                                    <label>Risk&nbsp;:&nbsp;</label>
                                    <span>{diagnosis.risk}</span>
                                </div>
                                <div className="form-group m-0">
                                    <label>Probability&nbsp;:&nbsp;</label>
                                    <span>{this.get_probability_data(diagnosis.probability_id)}</span>
                                </div>
                                <div className="form-group m-0">
                                    <label>Impact&nbsp;:&nbsp;</label>
                                    <span>{this.get_impact_data(diagnosis.impact_id)}</span>
                                </div>
                                <div className="form-group m-0">
                                    <label>Score&nbsp;:&nbsp;</label>
                                    <span>{this.get_score_data(diagnosis)}</span>
                                </div>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }


    render() {
        const styles = css({
            headingText: {
                fontSize: 15,
                fontWeight: 'normal',
                // display: 'flex',
                // justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 15,
            },
            button: {
                marginLeft: 15
            }
        })

        return (
            <React.Fragment>
                <h3 style={styles.headingText}>
                    <b>Risk matrix</b>
                </h3>
                { this.renderTable() }
            </React.Fragment>
        )
    }
}

export default RiskMatrixDetails