import React, { Component } from 'react';
import 'react-select-plus/dist/react-select-plus.css';
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import { css, postData, reFreashReactTable, toastMessageShow, AjaxConfirm, getApplicantId, getMemberId  } from 'service/common.js';

import { connect } from 'react-redux'
import { defaultSpaceInTable } from 'service/custom_value_data.js';
import { ROUTER_PATH } from 'config.js';
import PropTypes from 'prop-types';
import moment from "moment";
import jQuery from 'jquery';
import { Redirect } from 'react-router';
import { 
    IconSettings, 
    PageHeader, 
    PageHeaderControl, 
    Button, 
    Dropdown, 
    DropdownTrigger,
    Input,
    Icon,
    InputIcon
} from '@salesforce/design-system-react';
import CreateDocumentModel from '../CreateDocumentModel.jsx';
import EditDocumentModel from '../EditDocumentModel.jsx';
import '../../../scss/components/admin/salesforce/lightning/salesforce-lightning-design-system.scss';
import '../../../scss/components/admin/member/member.scss';
import TilesCard from '../TilesCard';

/**
 * RequestData get the list of member document
 * @param {int} pageSize 
 * @param {int} page 
 * @param {int} sorted 
 * @param {int} filtered 
 */
const requestData = (member_id, applicant_id, pageSize, page, sorted, filtered) => {

    return new Promise((resolve, reject) => {
        // request json
        var Request = { member_id: member_id, applicant_id: applicant_id, pageSize: pageSize, page: page, sorted: sorted, filtered: filtered };
        postData('member/MemberDocumentMemberPortal/get_member_document_list', Request).then((result) => {
            if (result.status) {
                let filteredData = result.data;
                const res = {
                    rows: filteredData,
                    pages: (result.count),
                    document_count: result.document_count
                };
                resolve(res);
            } else {
                const res = {
                    rows: [],
                    pages: 0,
                    document_count: 0
                };
                resolve(res);
            }
           
        });

    });
};

/**
 * Class: ListDocument
 */
class ListDocument extends Component {
    
    /**
     * Set visible columns of the table
     */
    static defaultProps = {
        
    }

    constructor(props) {
        super(props);

        // Initialize state
        this.state = {
            searchVal: '',
            filterVal: '',
            documentList: [],
            contact_id: '',
            filter_status: 'all',
            openCreateDocument: false,
            openEditDocument: false,
            document_id: '',
            pageSize: 99999,
            page: 0,
            document_count: 0,
	        applicant_id: getApplicantId(),
            member_id: getMemberId(),
            validStatus: 1,
        }
        // Initilize react table
        this.rootRef = React.createRef()
    }

    /*
     * method runs after the component output has been rendered to the DOM
     */
    componentDidMount() {       
        this.fetchDocumentData(this.state);
        jQuery(this.rootRef.current).parent('.col-lg-11').removeClass('col-lg-11').addClass('col-lg-12');
        jQuery("#wrapper").attr('id', 'wrapper-tmp');
        this.get_member_details(this.props.match.params.id);
    }

    componentWillUnmount() {
        jQuery(this.rootRef.current).parent('.col-lg-12').removeClass('col-lg-12').addClass('col-lg-11');
        jQuery("#wrapper-tmp").attr('id', 'wrapper');
    }

    /**
     * Fetching member details
     * 
     * Will fetch when:
     * - This component was mounted
     * - Member was successfully updated
     */
    get_member_details = (id) => {
        this.setState({ loading: true })

        postData('member/MemberDocumentMemberPortal/get_member_details', { id })
            .then((result) => {
                if (result.status) {
                    this.setState({ ...result.data })
                } else {
                    // this.setState({ redirectTo: ROUTER_PATH + `/member` })
                }
            })
            .catch(() => console.error('Could not fetch member details'))
            .finally(() => this.setState({ loading: false }))
    }
   
    /**
     * Call the requestData
     * @param {temp} state 
     */
    fetchDocumentData = (state, instance) => {
        this.setState({ loading: true });
        var member_id = this.state.member_id;
        var applicant_id = getApplicantId();
        requestData(
            member_id,
            applicant_id,
            state.pageSize,
            state.page,
            state.sorted,
            state.filtered
        ).then(res => {
            this.setState({
                documentList: res.rows,
                document_count: res.document_count,
                pages: res.pages,
                loading: false
            });
        });
    }

    /**
     * Get the list based on filter value
     * @param {str} key 
     * @param {str} value 
     */
    filterChange = (key, value) => {
        var state = {};
        state[key] = value;
        this.setState(state, () => {
            this.setTableParams();
        });
    }

    /**
     * Get the list based on Search value
     * @param {object} e 
     */
    submitSearch = (e) => {
        e.preventDefault();
        this.setTableParams();
    }

    /**
     * Set the data for fetching the list
     */
    setTableParams = () => {
        var search_re = { search: this.state.search, filter_status: this.state.filter_status };
        this.setState({ filtered: search_re }, () => {
            this.fetchDocumentData(this.state);
        });

    }

    /**
     * Render page header action
     */
    handleOnRenderActions = () => {
        const handleOnClickNewDocument = e => {
            e.preventDefault()
            this.showCreateDocumentModal();
        }

        return (
            <React.Fragment>
                <PageHeaderControl>
                    { this.renderSearchForm() }
                </PageHeaderControl>
                <PageHeaderControl>
                    <Link to={ROUTER_PATH + `member/member_document/create`} className={`slds-button slds-button_neutral`} onClick={handleOnClickNewDocument}>
                        New
                    </Link>
                </PageHeaderControl>
            </React.Fragment> 
        )
    }

    /**
     * Render search input form
     */
    renderSearchForm() {
        return (
            <form 
                autoComplete="off" 
                onSubmit={(e) => this.submitSearch(e)} 
                method="post" 
                className="slds-col_padded"
                style={{ display: 'block' }}
            >
                <Input
                    iconLeft={
                        <InputIcon
                            assistiveText={{
                                icon: 'Search',
                            }}
                            name="search"
                            category="utility"
                        />
                    }
                    onChange={(e) => this.setState({ search: e.target.value })}
                    
                    id="member-search-1"
                    placeholder="Search Documents"
                />
            </form>
        )
    }

    /**
     * Render filter dropdown of status
     */
    renderStatusFilters() {
        let documentStatusFilter = [
            { value: '', label: 'All' },
            { value: 'submitted', label: 'Submitted' },
            { value: 'draft', label: 'Draft' },
        ];
        return (
            <Dropdown
                align="right"
                checkmark
                assistiveText={{ icon: 'Filter by status' }}
                iconCategory="utility"
                iconName="settings"
                iconVariant="more"
                options={documentStatusFilter}
                onSelect={value => this.filterChange('filter_status', value.value)}
                length={null}
            >
                <DropdownTrigger title={`Filter by status`}>
                    <Button
                        assistiveText={{ icon: 'Filter' }}
                        iconCategory="utility"
                        iconName="filterList"
                        iconVariant="more"
                        variant="icon"
                    />
                </DropdownTrigger>
            </Dropdown>
        )
    }

    /**
     * Handle the selected columns visible or not 
     */
    handleOnSelectColumnSelectorItem = option => {
        const value = option.value

        let cols = [...this.state.displayed_columns]
        if (cols.indexOf(value) >= 0) {
            cols = cols.filter(col => col !== value)
        } else {
            cols = [...this.state.displayed_columns, value]
        }

        this.setState({ displayed_columns: cols })
    }

    /**
     * Render table column dropdown
     * @param {object} param
     * @param {(import('react-table').Column & { _label: string })[]} [param.columns]
     */
    renderColumnSelector({ columns = [] }) {

        const mapColumnsToOptions = columns.map(col => ({ 
            value: 'id' in col ? col.id : col.accessor,
            label: col._label,
        }))

        return (
            <Dropdown
                align="right"
                checkmark
                multiple
                assistiveText={{ icon: 'More' }}
                iconCategory="utility"
                iconName="settings"
                iconVariant="more"
                options={mapColumnsToOptions}
                value={this.state.default_displayed_columns}
                onSelect={this.handleOnSelectColumnSelectorItem}
            >
                <DropdownTrigger>
                    <Button
                        title={`Show/hide columns`}
                        assistiveText={{ icon: 'Show/hide columns' }}
                        iconCategory="utility"
                        iconName="table"
                        iconVariant="more"
                        variant="icon"
                    />
                </DropdownTrigger>
            </Dropdown>
        )
    }

    /**
     * Show create document model
     */
    showCreateDocumentModal = () => {
        this.setState({
            openCreateDocument: true,
        });
    }
    
    /**
     * Show update document model
     */
    showEditDocumentModal = (id) => {
        this.setState({
            openEditDocument: true,
            document_id: id,
        });
    }

    /**
     * Close the modal when user save the document and refresh the table
     * Get the Unique reference id
     */
    closeEditDocumentModal = (status, documentId) => {
        this.setState({openEditDocument: false});

        if(status){
            if (documentId) {
                // to do...
            } else {
                this.fetchDocumentData(this.state);
            }
        }
    }
    
    /**
     * when archive is requested by the user for selected document
     */
    handleOnArchiveDocument = (id) => {
        const msg = `Are you sure you want to archive this document?`
        const confirmButton = `Archive Document`
        var is_member = false;
        if (this.state.member_id !== 'null') {
            is_member = true;
        }
        var applicant_id = getApplicantId();
        AjaxConfirm({ document_id: id, member_id: this.state.member_id, applicant_id: applicant_id, is_member: is_member }, msg, `member/MemberDocumentMemberPortal/archive_document`, { confirm: confirmButton, heading_title: `Archive Location` }).then(result => {
            if (result.status) {
                toastMessageShow(result.msg, "s");
                this.fetchDocumentData(this.state);
            } else {
                if (result.error) {
                    toastMessageShow(result.error, "e");
                }
            }
        })
    }

    /**
     * Close the modal when user save the document and refresh the table
     * Get the Unique reference id
     */
    closeModal = (status, documentId) => {
        this.setState({openCreateDocument: false});

        if(status){
            this.fetchDocumentData(this.state);
        }
    }

    /**
     * Covert bytes to MB
     * @param {int} bytes 
     */
    bytesToSize(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        var i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    
    /**
     * Render modals
     * - Create Document
     * - Edit Document
     * 
     */
    renderModals() {
        var applicant_id = getApplicantId();
        return (
            <React.Fragment>
                {
                    this.state.openCreateDocument && (
                        <CreateDocumentModel
                            showModal = {this.state.openCreateDocument}
                            closeModal = {this.closeModal}
                            headingTxt = "New Document"
                            member_id = {this.state.member_id}
                            applicant_id = {applicant_id}
                            {...this.props}
                        />
                    )
                }
                {
                    this.state.openEditDocument && (
                        <EditDocumentModel
                            showModal = {this.state.openEditDocument}
                            closeModal = {this.closeEditDocumentModal}
                            document_id = {this.state.document_id}
                            member_id = {this.state.member_id}
                            applicant_id = {applicant_id}
                            headingTxt = "Update Document"
                            {...this.props}
                        />
                    )
                }
            </React.Fragment>
        )
    }

    /**
     * Render Cards Section
     */
    renderCards = () => {
        if (this.state.document_count > 0 ) {
            return (
                <div className="slds-card__body_inner mb-4">
                    <ul class="slds-grid slds-wrap slds-grid_pull-padded">
                        {
                            this.state.documentList.map((val, index) => {
                                var issue_date = '';            
                                const issueDateMoment = moment(val.issue_date)
                                if (!issueDateMoment.isValid()) {
                                    issue_date = <span className="vcenter slds-truncate"></span>
                                } else {
                                    issue_date = issueDateMoment.format('DD/MM/YYYY')
                                }

                                var expiry_date = '';            
                                const expiryDateMoment = moment(val.expiry_date)
                                if (!expiryDateMoment.isValid()) {
                                    expiry_date = <span className="vcenter slds-truncate"></span>
                                } else {
                                    expiry_date = expiryDateMoment.format('DD/MM/YYYY')
                                }
                                let disabledStatus = Number(val.document_status);
                                return (
                                    <li class="slds-p-horizontal_small slds-size_1-of-1 slds-medium-size_1-of-3">
                                        <TilesCard
                                            showEditDocumentModal={this.showEditDocumentModal}
                                            handleOnArchiveDocument={this.handleOnArchiveDocument}
                                            document_id={val.id}
                                            disabled={(disabledStatus === 0 || disabledStatus === 1 || disabledStatus === 3) ? true : false}
                                            url={(!val.file_path || val.file_path == '') ? '' : val.file_path}
                                            title={val.file_name}
                                            title_details={[
                                                { label: "Issue Date", value: issue_date },
                                                { label: "Expiry Date", value: expiry_date },
                                                { label: "Reference Number", value: val.reference_number }
                                            ]}
                                            icon={{ category: "standard", name: "file", size: "small" }}
                                        />
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            );
        } else {
            return (<React.Fragment />);
        }
    }

    /**
     * Render the display content
     */
    render() {
        // This will only run when user create document
        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} />
        }
        const styles = css({
            root: {
                fontFamily: "Salesforce Sans, Arial, Helvetica, sans-serif",
                marginRight: -15,
            }
        })
        const trail = [
            <Link to={ROUTER_PATH + `member`} className="reset" style={{ color: '#0070d2' }}>
                {'Members'}
            </Link>
		];
        // return
        return (
            <React.Fragment>
                <div className="slds" style={styles.root} ref={this.rootRef}>
                    <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                        <PageHeader
                            icon={
                                <Link to={ROUTER_PATH + `member`} className="back_link" style={{ color: '#0070d2' }}>
                                    <Button
                                        assistiveText={{ icon: 'Icon Bare Small' }}
                                        iconCategory="utility"
                                        iconName="chevronleft"
                                        iconSize="large"
                                        iconVariant="bare"
                                        onClick={() => {
                                            // todo
                                        }}
                                        variant="icon"
                                    />
                                </Link>
                            }
                            className="slds-header-document-mob-view"
                            onRenderActions={this.handleOnRenderActions}
                            trail={[<span> </span>]}
                            title="Documents"
                            label={<span />}
                            truncate
                            variant="object-home"
                        />
                        <div className="white_bg_color slds-box m-3 slds-box-mob-view">
                            {this.renderCards()}
                        </div>
                        </IconSettings>
                </div>
                {this.renderModals()}
            </React.Fragment>
        )
    }
}
// Defalut Prop
ReactTable.PropTypes = {
    defaultFiltered: PropTypes.object
}
// Get the page title and type from reducer
const mapStateToProps = state => ({

})

const mapDispatchtoProps = (dispach) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchtoProps)(ListDocument);
