import React from 'react'
import _ from 'lodash';
import { Checkbox, ExpandableSection, RadioGroup, Radio } from '@salesforce/design-system-react';
import { postData, css } from 'service/common'
import '../../../scss/components/admin/crm/pages/sales/opportunity/OpportunityDetails.scss'

/**
 * Get the court action information
 * @param {int} riskAssessmentId
 */
const requestRABSAData = (riskAssessmentId) => {
    return new Promise((resolve, reject) => {
        // request json
        var Request = { risk_assessment_id: riskAssessmentId };
        postData('sales/RiskAssessment/get_ra_behavsupport_by_id', Request).then((result) => {
            if (result.status) {
                let resData = result.data;
                const res = {
                    data: resData,
                };
                resolve(res);
            } else {
                const res = {
                    data: []
                };
                resolve(res);
            }
        });
    });
};
/**
 * @typedef {typeof RiskBehaviourSupport.defaultProps} Props
 *
 * Displays the cour action with heading
 * 
 * @extends {React.Component<Props>}
 */
class RiskBehaviourSupport extends React.Component {

    static defaultProps = {
        risk_assessment_id: null,
        behaviour_support_matrices: []
    }
    constructor(props) {
        super(props);
        this.state = {
            isBehaviourSupport: true,
            bs_not_applicable: false,
            bs_plan_status: "",
            seclusion: false,
            chemical_constraint: false,
            mechanical_constraint: false,
            physical_constraint: false,
            environmental: false,
            bs_noplan_status: false,
            bs_plan_available_date: '',
            isOpenEditBehvSuppModal: false,
            likelyhoods: [
                { 'id': 1, 'name': 'Likely' },
                { 'id': 2, 'name': 'Very likely' },
                { 'id': 3, 'name': 'Un likely' }
            ]
        }
        this.datepickers = {
            bs_plan_available_date: React.createRef(),
        };
    }
    componentDidMount() {
        const id = this.props.risk_assessment_id;
        this.getRABSADetails(id);
    }
    /**
     * Call requestRACAData
     * param {int} id
     */
    getRABSADetails = (id) => {
        var Request = { risk_assessment_id: id };
        postData('sales/RiskAssessment/get_ra_behavsupport_by_id', Request).then((res) => {
            if (res.status) {
                var raData = res.data;
                var state = this.state;
                if (raData.bs_not_applicable) {
                    state['bs_not_applicable'] = raData.bs_not_applicable == '2' ? true : false;
                }
                if (raData.bs_plan_status) {
                    state['bs_plan_status'] = raData.bs_plan_status == '2' ? 'Yes' : 'No';
                }
                if (raData.seclusion) {
                    state['seclusion'] = raData.seclusion == '2' ? true : false;
                }
                if (raData.chemical_constraint) {
                    state['chemical_constraint'] = raData.chemical_constraint == '2' ? true : false;
                }
                if (raData.mechanical_constraint) {
                    state['mechanical_constraint'] = raData.mechanical_constraint == '2' ? true : false;
                }
                if (raData.physical_constraint) {
                    state['physical_constraint'] = raData.physical_constraint == '2' ? true : false;
                }
                if (raData.environmental) {
                    state['environmental'] = raData.environmental == '2' ? true : false;
                }
                if (raData.bs_plan_available_date) {
                    state['bs_plan_available_date'] = raData.bs_plan_available_date;
                }
                if (raData.bs_noplan_status) {
                    state['bs_noplan_status'] = raData.bs_noplan_status == '2' ? true : false;
                }
                this.setState(state);
            } else {

            }
        });

        requestRABSAData(
            id,
        ).then(res => {
            var raData = res.data;
            var state = this.state;
            if (raData.bs_not_applicable) {
                state['bs_not_applicable'] = raData.bs_not_applicable == '2' ? true : false;
            }
            if (raData.bs_plan_status) {
                state['bs_plan_status'] = raData.bs_plan_status == '2' ? 'Yes' : 'No';
            }
            if (raData.seclusion) {
                state['seclusion'] = raData.seclusion == '2' ? true : false;
            }
            if (raData.chemical_constraint) {
                state['chemical_constraint'] = raData.chemical_constraint == '2' ? true : false;
            }
            if (raData.mechanical_constraint) {
                state['mechanical_constraint'] = raData.mechanical_constraint == '2' ? true : false;
            }
            if (raData.physical_constraint) {
                state['physical_constraint'] = raData.physical_constraint == '2' ? true : false;
            }
            if (raData.environmental) {
                state['environmental'] = raData.environmental == '2' ? true : false;
            }
            if (raData.bs_plan_available_date) {
                state['bs_plan_available_date'] = raData.bs_plan_available_date;
            }
            if (raData.bs_noplan_status) {
                state['bs_noplan_status'] = raData.bs_noplan_status == '2' ? true : false;
            }
            this.setState(state);
        });
    }
    /**
     * Render Behaviour table
     */
    renderBehaviourTable = () => {
        let count = this.props.behvsupportmatrices ? this.props.behvsupportmatrices.length : 0;
        return (
            <div className="slds-grid">
                <div className="slds-panel slds-size_full slds-is-open" aria-hidden="false">
                    <div className="slds-m-horizontal_small slds-p-vertical_x-small ml-0">
                        <div className="slds-tile slds-tile_board slds-m-horizontal_small ml-0">
                            <div className="slds-tile__detail">
                                {this.props.behvsupportmatrices && this.props.behvsupportmatrices.map((diagnosis) =>
                                    <div className="col-sm-12 dia_form_siz">
                                        <div className="form-group m-0">
                                            <label>Behaviour&nbsp;:&nbsp;</label>
                                            <span>{diagnosis.behaviuor}</span>
                                        </div>
                                        <div className="form-group m-0">
                                            <label>Likelyhood&nbsp;:&nbsp;</label>
                                            <span>{(diagnosis.likelyhood_id == 1) ? 'Likely' : (diagnosis.likelyhood_id == 2 ? 'Very likely' : (diagnosis.likelyhood_id == 3 ? 'Un likely' : ''))}</span>
                                        </div>
                                        <div className="form-group m-0">
                                            <label>Trigger&nbsp;:&nbsp;</label>
                                            <span>{diagnosis.trigger}</span>
                                        </div>
                                        <div className="form-group m-0">
                                            <label>Prevention Strategy&nbsp;:&nbsp;</label>
                                            <span>{diagnosis.prevention_strategy}</span>
                                        </div>
                                        <div className="form-group m-0">
                                            <label>De-escalation Strategies&nbsp;:&nbsp;</label>
                                            <span>{diagnosis.descalation_strategy}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    /**
     * Render not applicable bs_noplan_status
     */
    renderNotApplicable = () => {
        const styles = css({
            btn_align_right: {
                float: 'right',
            },
        })
        return (
            <div className="row px-2 pb-2">
                <div className="slds-checkbox">
                    <Checkbox
                        assistiveText={{
                            label: 'Not Applicable',
                        }}
                        id="bs_not_applicable"
                        labels={{
                            label: 'Not Applicable',
                        }}
                        checked={this.state.bs_not_applicable}
                    />
                </div>

            </div>
        );
    }
    /**
     * Render no plan bs_noplan_status
     */
    renderNoPlan = () => {
        const styles = css({
            bs_noplan_status: {
                paddingTop: '0.5rem',
            },
        })
        return (
            <div className="mt-4 mt-sm-1">
                <Checkbox
                    assistiveText={{
                        label: 'No Plan',
                    }}
                    id="bs_noplan_status"
                    labels={{
                        label: 'No Plan',
                    }}
                    checked={this.state.bs_noplan_status}
                    disabled
                />
            </div>
        );
    }
    /**
     * Render behaviour support plan input
     */
    renderBehaviourSupportPlan = () => {
        const values = ['Yes', 'No'];
        const labels = { label: 'Behaviour Support Plan (last 12m)' };
        return (
            <div className="slds-behaiour-support-plan">
                <div className="row py-2">
                    <div className="col-sm-6 col-md-6">
                        <RadioGroup
                            labels={labels}
                            className="bs_plan_status-font-normal"
                            disabled
                            required={this.props.required}
                            name={'bs_plan_status'}
                            variant={'base'}
                        >
                            {values.map((item) => (
                                <Radio
                                    className={"radio-btn-display"}
                                    key={item}
                                    labels={{ label: item }}
                                    value={item}
                                    checked={this.state["bs_plan_status"] === item}
                                    variant="base"
                                />
                            ))}
                        </RadioGroup>
                    </div>

                </div>
            </div>
        );
    }
    /**
     * Render Restrictive pratices options
     */
    renderRestrictivePractices = () => {
        // Options
        let bs_plan_status = this.state.bs_plan_status === 'Yes' ? true : false;
        // Disable the option if bs_plan_status and bs_not_applicable is not enabled
        let disabledPractices = bs_plan_status && !this.state.bs_not_applicable ? false : true;
        let restrictivePractices = [
            {
                label: 'Seclusion',
                name: 'seclusion',
                value: this.state.seclusion,
                disabled: disabledPractices,
            },
            {
                label: 'Chemical constraint',
                name: 'chemical_constraint',
                value: this.state.chemical_constraint,
                disabled: disabledPractices,
            },
            {
                label: 'Mechanical constraint',
                name: 'mechanical_constraint',
                value: this.state.mechanical_constraint,
                disabled: disabledPractices,
            },
            {
                label: 'Physical constraint',
                name: 'physical_constraint',
                value: this.state.physical_constraint,
                disabled: disabledPractices,
            },
            {
                label: 'Environmental',
                name: 'environmental',
                value: this.state.environmental,
                disabled: disabledPractices,
            },
        ];
        return (
            <div className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center row">
                <div className="col col-sm-6 col-md-6">
                    <legend className="slds-form-element__legend slds-form-element__label"> If yes, does the plan include any restrictive practices ?</legend>
                    <div className="slds-form-element__control pt-2 pl-3 pl-sm-0">
                        {
                            restrictivePractices.map((item) =>
                                <Checkbox
                                    assistiveText={{
                                        label: item.label,
                                    }}
                                    id={item.label}
                                    name={item.name}
                                    disabled
                                    checked={this.state[`${item.name}`]}
                                    labels={{
                                        label: item.label,
                                    }}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
    render() {
        const styles = css({
            panel_body: {
                paddingLeft: '0.5rem',
            },
            hr: {
                marginTop: '0.5rem',
                marginBottom: '0.75rem',
                border: 0,
                borderTop: '1px solid #eee',
                width: 'auto'
            },
        });
        return (
            <React.Fragment>
                <div className="slds-behaviour-support">
                    <ExpandableSection
                        id="controlled-expandable-section"
                        isOpen={this.state.isBehaviourSupport}
                        onToggleOpen={(event, data) => {
                            this.setState({ isBehaviourSupport: !this.state.isBehaviourSupport });
                        }}
                        title="Behaviour Support"
                    >
                        <form id="risk_assessent_order" autoComplete="off" className="col-md-12 slds_form">
                            <div style={styles.panel_body}>
                                {this.renderNotApplicable()}
                                {this.renderBehaviourTable()}
                                {this.renderBehaviourSupportPlan()}
                                {this.renderRestrictivePractices()}
                            </div>
                        </form>
                        <hr style={styles.hr} />
                    </ExpandableSection>

                </div>
            </React.Fragment>
        )
    }
}

export default RiskBehaviourSupport;