import React from 'react'
import { Card, Button, Icon, MediaObject } from '@salesforce/design-system-react'
import { css, postImageData, toastMessageShow, getLoginToken, postData } from '../../../../service/common'
import moment from 'moment';
import { IconSettings } from '@salesforce/design-system-react';
import { COMMON_DOC_DOWNLOAD_URL } from '../../../../config';

/**
 * Tile for `<AttachmentCard/>`
 */
export const Tile = ({
    /**
     * @type {string}
     */
    client_name,
    /**
     * @type {number}
     */
    file_size,
    /**
     * @type {string}
     */
    url,
    /**
     * @type {{label: string, value: React.ReactNode}[]}
     */
    details = [],
    is_image = false
}) => {

    const headerTooltip = ['string', 'number'].indexOf(typeof client_name) >= 0 ? client_name : undefined
    const styles = css({
        icon: {
            backgroundColor: '#baac93', // brown
        }
    })

    return (
        <MediaObject
            body={(
                <React.Fragment>
                    <h3 className="slds-tile__title slds-truncate" title={headerTooltip}>
                        <a href={url} className="reset" style={{ color: '#0070d2' }} target="_blank">{client_name}</a>
                    </h3>
                    {
                        (details || []).length > 0 && (
                            <div className="slds-tile__detail">
                                <dl className="slds-list_horizontal slds-wrap">
                                    {
                                        details.map((detail, i) => {
                                            const tooltip = typeof detail.label === "string" ? detail.label : undefined
                                            const tooltipValue = typeof detail.value === "string" ? detail.value : undefined

                                            return (
                                                <React.Fragment>
                                                    <dt className="slds-item_label slds-text-color_weak slds-truncate" title={tooltip}>
                                                        {detail.label}
                                                    </dt>
                                                    <dd className="slds-item_detail slds-truncate" title={tooltipValue}>
                                                        {detail.value}
                                                    </dd>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </dl>
                            </div>
                        )
                    }
                </React.Fragment>
            )}
            className="slds-tile"
            figure={<Icon category="standard" name={is_image ? "photo" : "file"} size="small" style={styles.icon} />}
        // verticalCenter
        />
    )
}


/**
 * @typedef {typeof AttachmentCard.defaultProps} Props
 * 
 * Card to display uploaded attachments
 * 
 * @extends {React.Component<Props>}
 */
class ShiftAttachmentCard extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            existingAttachments: this.props.attachments,
        }

        this.reactTable = React.createRef();
    }

    refreshPage(data) {
        this.setState({ existingAttachments: data })
    }
    /**
     * Determine tile details by provided attachment info
     * 
     * @param {any} attachment 
     */
    determineDetailsForAttachment(attachment) {
        const uploadedMoment = moment(attachment.created)
        let uploaded = null
        if (uploadedMoment.isValid()) {
            uploaded = uploadedMoment.format('Do MMMM YYYY')
        }

        return [
            {
                label: 'File Size',
                value: `${attachment.file_size} KB`,
                width: '50%'
            },
            {
                label: 'Uploaded On',
                value: uploaded,
            }
        ]
    }

    render() {
        const styles = css({
            card: {
                border: '1px solid #dddbda',
                boxShadow: '0 2px 2px 0 rgba(0,0,0,.1)',
            },
            rowParent: {
                borderRadius: 0,
                borderBottom: 'none',
                borderLeft: 'none',
                borderRight: 'none',
            },
            // form: {
            //     display: 'block'
            // }
        })

        const existingAttachments = this.state.existingAttachments;
        let readOnly = this.props.shift_status == 5 || this.props.shift_status == 3 ? true : false;
        let tsLabel = this.props.shift_status == 5 || this.props.shift_status == 3 ? "View" : "Update";
        return (
            <React.Fragment>
                <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                    {this.props.shift_status != 3 &&
                        <Card
                            headerActions={
                                <React.Fragment>
                                    <Button label={this.props.shift_status == 5 ? "View" : "Update"} title={`Update the timesheet`} onClick={e => this.props.onClickEdit(e, readOnly)} />
                                </React.Fragment>
                            }
                            heading={'Actuals and Timesheet'}
                            style={styles.card}
                            icon={
                                <Icon
                                    category="standard"
                                    name="document"
                                    size="small"
                                />
                            }
                        >
                            {existingAttachments.filename &&
                                <div className="slds-box checking" style={styles.rowParent}>
                                    <div className="row">

                                        <div className="col col-sm-12 " style={{ marginBottom: 15 }}>
                                            <Tile
                                                client_name={existingAttachments.filename}
                                                file_size={0}
                                                is_image={[1, '1'].indexOf(existingAttachments.is_image) >= 0}
                                                url={window.location.origin + '/' + COMMON_DOC_DOWNLOAD_URL + 'shift/' + existingAttachments.file_show_url + '&ptype=shift'}
                                                // url={existingAttachments.file_show_url}
                                                details={this.determineDetailsForAttachment(existingAttachments)}
                                            />
                                        </div>

                                    </div>
                                </div>

                            }
                        </Card>
                    }
                    {this.props.shift_status != 3 &&
                    <Card
                        headerActions={
                            <React.Fragment>
                                <Button label={tsLabel} title={`${tsLabel} the timesheet`} onClick={(e) => this.props.onClickNotes(e, readOnly)} />
                            </React.Fragment>
                        }
                        heading={'Shift Notes and Goals'}
                        style={styles.card}
                        icon={
                            <Icon
                                category="standard"
                                name="document"
                                size="small"
                            />
                        }
                    />
                    }
                </IconSettings>
            </React.Fragment>
        )
    }
}

export default ShiftAttachmentCard