import React, { Component } from 'react';
import jQuery from "jquery";
import 'react-select-plus/dist/react-select-plus.css';
import { checkItsNotLoggedIn, postData, toastMessageShow, css, handleChange, handleShareholderNameChangeShift, handleRemoveShareholder, handleDecimalChange, getApplicantId, getMemberId, getLoginToken, calculate_shift_duration, postImageData } from 'service/common.js';
import { Icon, ExpandableSection } from '@salesforce/design-system-react';
import 'react-block-ui/style.css';
import Modal from '@salesforce/design-system-react/lib/components/modal';
import Button from '@salesforce/design-system-react/lib/components/button';
import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import 'service/jquery.validate.js';
import "service/custom_script.js";
import { SLDSISODatePicker } from '../salesforce/lightning/SLDSISODatePicker';
import SLDSReactSelect from '../salesforce/lightning/SLDSReactSelect.jsx';
import { Input, Timepicker } from '@salesforce/design-system-react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { ToastUndo } from 'service/ToastUndo.js';
import Text from '../oncallui-react-framework/input/Text';
import Label from '../oncallui-react-framework/input/Label';

/**
 * get upload file limit
 */
const getUploadFileLimit = () => {
    return new Promise((resolve, reject) => {
        postData("common/Common/max_upload_file_limit").then(result => {
            if (result.status) {
                let raData = result.data;
                const res = {
                    data: raData,
                };
                resolve(res);
            } else {
                const res = {
                    data: [],
                };
                resolve(res);
            }
        });
    });
}

class TimesheetShiftModal extends Component {

    /**
     * setting the initial prop values
     */
    constructor(props) {
        super(props);
        checkItsNotLoggedIn();

        const TIME_FORMAT = 'hh:mm A';

        const GOAL_TYPE = [
            { id: 'not_relevant', label: 'Not Attempted:Not relevant to this shift', value: '1' },
            { id: 'customer_choice', label: "Not Attempted:Customer's Choice", value: '2' },
            { id: 'verbal_prompt', label: 'Verbal Prompt', value: '3' },
            { id: 'physical_assistance', label: 'Physical Assistance', value: '4' },
            { id: 'independent', label: 'Independent', value: '5' },
        ];

        this.state = {
            loading: false,
            id: '',
            shift_no: '',
            time_format: TIME_FORMAT,
            scheduled_reimbursement: '',
            scheduled_travel: '',
            actual_start_date: '',
            actual_end_date: '',
            actual_start_time: '',
            actual_end_time: '',
            actual_travel: '',
            actual_duration: '',
            actual_reimbursement: '',
            actual_travel_distance: '',
            actual_travel_duration: '',
            actual_travel_duration_hr: '',
            actual_travel_duration_min: '',
            notes: '',
            time_options: [],
            actual_rows: [],
            break_types: [],
            status: 1,
            goal_type: GOAL_TYPE,
            goalDetails: [],
            goalSelection: [],
            snapShotList: [],
            files: [],
            max_post: 0,
            max_upload: 0,
            memory_limit: 0,
            upload_mb: 0,
            byte: 1048576, // 1 MB in bytes
            uploaded_total_bytes: 0,
            max_total_bytes: 0,
            attachments: [],
            attachment_details: [],
            actual_support_type_duration: [],
            actual_st_duration_error: {
                error: false,
                errorMsg: ''
            },
            is_support_mixed: false,
        }

        this.inputFile = React.createRef();
        // check the server side for supported exts
        this.allowedExtensions = [
            'jpg',
            'jpeg',
            'png',            
            'pdf',
        ];

        // will be used in html accept attribute
        this.allowedFileTypes = [            
            '.pdf',           
            'application/pdf',
            'image/jpg',
            'image/jpeg',
            'image/png'
        ]

        // we'll use these refs to fix toggling slds datepicker issues
        this.datepickers = {
            actual_start_date: React.createRef(),
            actual_end_date: React.createRef(),
        }
    }

    /**
    * Call callUploadFileLimit api 
    */
    callUploadFileLimit = () => {
        getUploadFileLimit().then(res => {
            var ra_data = res.data;
            if (ra_data && ra_data.upload_mb) {
                let max_upload = ra_data.max_upload;
                /**
                 * calculate the total allowed file size
                 * ex 
                 * this.state.byte = 1048576
                 * max_upload = 10
                 * max_total_bytes = 10 * 1048576 = 10485760
                 *  
                */
                let max_total_bytes = max_upload * this.state.byte;
                this.setState({
                    upload_mb: ra_data.upload_mb,
                    max_post: ra_data.max_post,
                    max_upload: ra_data.max_upload,
                    memory_limit: ra_data.memory_limit,
                    max_total_bytes: max_total_bytes,
                });
            }
        });
    }

    /**
     * Get support type duration date & format
     */
     get_support_type_ndis_duration = (index) => {

        let start_date = this.state[index + '_start_date'];
        let start_time = this.state[index + '_start_time'];
        let end_date = this.state[index + '_end_date'];
        let end_time = this.state[index + '_end_time'];
        let duration = this.state[index + '_duration'];
        let support_type_duration = this.state[index + '_support_type_duration'];
        let rows = this.state[index + '_rows'];
        let req = {
            start_date: start_date,
            start_time: start_time,
            end_date: end_date,
            end_time: end_time,
            duration: duration,
            full_account_address: (this.state.account_address) ? this.state.account_address : '',
            account_address: (this.state.account_address) ? this.state.account_address.value : '',
            section: index,
            support_type_duration: this.state.is_support_mixed ? support_type_duration : [],
            rows: rows
        };

        let support_duration_list = [];
        postData("schedule/NdisPayments/get_support_type_ndis_duration", req).then((res) => {
            support_duration_list = (res.data) ? res.data : [];
            if (!res.status && res.error !== 'API ERROR') {
                this.setErrors(res.error);
            }
            this.setState({
                [index + '_support_type_duration']: support_duration_list
            })
            if(index=='scheduled' && this.state.status>3)
            {
                // this.setState({'actual_support_type_duration':ndis_line_item_list})
            }
        });
    }

    /**
     * fetching the reference data (skills and skill levels) of member's object
     */
    get_shift_break_types = () => {
        var req = { applicant_id: getApplicantId(), member_id: getMemberId() }
        postData("recruitment/Recruitment_member/get_shift_break_types", req).then((res) => {
            if (res.status) {
                this.setState({
                    break_types: (res.data) ? res.data : []
                })
            }
        });
    }

    /**
     * handling the change event of the data picker fields
     */
    handleChangeDatePicker = key => (dateYmdHis, e, data) => {
        let newState = {}
        newState[key] = dateYmdHis
        this.setState(newState)
        this.calcActiveDuration(2, key, dateYmdHis);
    }

    /**
     * tinker with internal Datepicker state to
     * fix calendar toggling issue with multiple datepickers
     */
    handleDatePickerOpened = k => () => {
        Object.keys(this.datepickers).forEach(refKey => {
            const { current } = this.datepickers[refKey] || {}
            if (refKey !== k && current && 'instanceRef' in current) {
                current.instanceRef.setState({ isOpen: false })
            }
        })
    }

    /**
     * handling the status change event
     */
    handleChange = (value, key) => {

        if (key === 'incident_occur_today' && value == "1") {
            jQuery('#incident_report').attr('required', true);
        }
        else if (key === 'incident_occur_today' && value == "2") {
            jQuery('#incident_report').removeAttr('required');
        }

        this.setState({ [key]: value });
    }

    handleRadioBtnSelect = (value, goal_id) => {
        let data = this.state.goalSelection;
        let tempArr = [];
        jQuery('#snap_shot_' + goal_id).removeAttr('required');
        jQuery('#snap_shot_required_' + goal_id).hide();
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                tempArr.push(data[i]);
                if (data[i].goal_id && data[i].goal_id == goal_id) {

                    //Remove the row based on the index
                    tempArr.splice(i, 1);
                }
            }
        }

        //Add required validation to text area
        if (value != 1) {
            jQuery('#snap_shot_' + goal_id).attr('required', true);
            jQuery('#snap_shot_required_' + goal_id).show();
        }

        tempArr.push({ goal_id: goal_id, type: value });

        this.setState({ goalSelection: tempArr });
    }

    /**
     *
     * @param {string} value - snapshot text area value
     * @param {*} goal_id - Goal id of the snapshot
     */
    handleSnapshotList = (value, goal_id) => {

        let snapData = this.state.snapShotList;
        let snaptempArr = [];
        if (snapData.length > 0) {
            for (let i = 0; i < snapData.length; i++) {
                snaptempArr.push(snapData[i]);
                if (snapData[i].goal_id && snapData[i].goal_id == goal_id) {
                    snaptempArr.splice(i, 1);
                }
            }
        }
        snaptempArr.push({ goal_id: goal_id, value: value });

        this.setState({ snapShotList: snaptempArr });
    }

    handleOnBlur = (event, statetxt) => {
        if (parseInt(event.target.value) < 10 && event.target.value.length == 1) {
            this.setState({ [statetxt]: "0" + event.target.value });
        }
    }

    /**
     * update type option if sleepover selected
     * @param {str} section 
     */
     updateBreakOption = (section) => {
        let data = this.state[section+'_rows'];
        let breaks = this.state.break_types;
        data.map((row, idx) => {

            let findIntrruptBreak = breaks.find(x => x.key_name === 'interrupted_sleepover');
            let intrruptBreakIndex = breaks.findIndex(x => x.key_name === 'interrupted_sleepover');
            if (findIntrruptBreak && intrruptBreakIndex > -1 && section === 'scheduled') {
                breaks[intrruptBreakIndex].disabled = true;
                breaks.splice(intrruptBreakIndex, 1);
            }

            // s/o 
            let findSleepBreak = breaks.find(x => x.key_name === 'sleepover');
            let findSleepBreakIdx = breaks.findIndex(x => x.key_name === 'sleepover');
            if (findSleepBreak && findSleepBreak.value) {
                let sleepValue = findSleepBreak.value;
                let sleepBreak = data.find(x => Number(x.break_type) === Number(sleepValue));
                let sleepBreakIndex = data.findIndex(x => Number(x.break_type) === Number(sleepValue));

                // disable or enable sleepover
                if (sleepBreakIndex > -1 && sleepBreakIndex !== idx) {
                    breaks[findSleepBreakIdx].disabled = true;
                } else {
                    breaks[findSleepBreakIdx].disabled = false;
                }
                if (section === 'actual') {
                    let findInSleepBreak = breaks.find(x => x.key_name === 'interrupted_sleepover');
                    let findInSleepBreakIdx = breaks.findIndex(x => x.key_name === 'interrupted_sleepover');
                    // disable or enable intrrepted sleepover
                    if (sleepBreak && sleepBreakIndex > -1 && Number(sleepBreakIndex) !== Number(idx)) {                        
                        // if interrupt s/o added
                        if (findInSleepBreak && findInSleepBreak.value) {
                            let inSleepValue = findInSleepBreak.value;
                            let inSleepBreak = data.find(x => Number(x.break_type) === Number(inSleepValue));
                            let inSleepBreakIndex = data.findIndex(x => Number(x.break_type) === Number(inSleepValue));
                            if (inSleepBreak && inSleepBreakIndex > -1 && Number(inSleepBreakIndex) !== Number(idx)) {
                                breaks[findInSleepBreakIdx].disabled = false;
                            } else {
                                if (Number(inSleepBreakIndex) !== Number(idx)) {
                                    breaks[findInSleepBreakIdx].disabled = false;
                                }                                
                            }
                        } else {
                            breaks[findInSleepBreakIdx].disabled = false;
                        }                        
                    } else {
                        if (findInSleepBreakIdx > -1 ) {
                            breaks[findInSleepBreakIdx].disabled = true;
                        }                        
                    }
                }
            }
            
            this.setState({
                [section+'_break_option_'+idx]: '' 
            },() => {
                this.setState({
                    [section+'_break_option_'+idx]: breaks
                });
            });
        });
    }


    /**
     * update duration option if sleepover selected
     * @param {str} section 
     */
     updateDurationField = (section, idx, e) => {
         // s/o 
        let breaks = this.state.break_types;
        let findSleepBreak = breaks.find(x => x.key_name === 'sleepover');

        if (findSleepBreak && findSleepBreak.value) {
            let sleepValue = findSleepBreak.value;
            let data = this.state[section+'_rows'];
            if (Number(e) === Number(sleepValue)) {                
                data[idx].duration_disabled = true;
                data[idx].break_duration = '';
                data[idx].timing_disabled = false;
            } else {
                data[idx].duration_disabled = false;                
            }

            this.setState({
                [section+'_rows']: data 
            });
        }

        // Interrupt sleepover
        let findInSleepBreak = breaks.find(x => x.key_name === 'interrupted_sleepover');
        if (findInSleepBreak && findInSleepBreak.value) {
            let inSleepValue = findInSleepBreak.value;
            let dataIn = this.state[section+'_rows'];
            if (Number(e) === Number(inSleepValue)) {                
                dataIn[idx].duration_disabled = true;
                dataIn[idx].break_duration = '';
                dataIn[idx].timing_disabled = false;
            } else {
                // dataIn[idx].duration_disabled = false;                
            }
            
            this.setState({
                [section+'_rows']: dataIn 
            });
        }
     }

    /**
     * Adding a new row in the table for user to insert data of registered member
     */
    addRow = () => {
        var actrows = this.state.actual_rows;
        var firstid = this.state.break_types[0].value;
        actrows.push({ id: "", break_type: firstid, break_start_time: "", break_end_time: "", break_duration: "", duration_disabled: false, timing_disabled: false });
        let act_break_length = actrows.length;
        act_break_length = act_break_length > 0 ? act_break_length-1 : act_break_length;
        this.setState({ 'actual_rows': actrows, ['actual_break_option_'+act_break_length]: this.state.break_types }, () => {
            this.updateBreakOption('actual');
        });
    }

    /**
     * Removing a new row in the table
     */
    removeRow = (idx) => {
        var actrows = this.state.actual_rows;
        
        // s/o 
        let breaks = this.state.break_types;
        let findSleepBreak = breaks.find(x => x.key_name === 'sleepover');
        let findInSleepBreak = breaks.find(x => x.key_name === 'interrupted_sleepover');

        let sleepValue = '';
        if (findSleepBreak && findSleepBreak.value) {
            sleepValue = findSleepBreak.value;
        }
        let inSleepValue = '';
        if (findInSleepBreak && findInSleepBreak.value) {
            inSleepValue = findInSleepBreak.value;
        }

        let break_type = actrows[idx].break_type;
        let inSleepBreakIdx = actrows.findIndex(x => Number(x.break_type) === Number(inSleepValue));
        if (Number(break_type) === Number(sleepValue) && inSleepBreakIdx > -1 ) {
            toastMessageShow("Delete Interrupted S/O timing before deleting S/O break", "e");
            return false;
        }
        actrows.splice(idx, 1);
        this.setState({ 'actual_rows': actrows }, () => {
            this.updateBreakOption('actual');
        });
        this.calcActiveDuration(2);
    }

    /**
     * calculating shift's active duration
     */
    async calcActiveDuration(cat, key, val) {
        var final_req = await calculate_shift_duration(cat, this.state, '', key, val);

        if (cat == 2) {
            this.setState({
                actual_duration: (final_req) ? final_req : ''
            }, () => {
                if (this.state.is_support_mixed) {
                    this.get_support_type_ndis_duration('actual');
                }
            })
        }
        else {
            this.setState({
                scheduled_duration: (final_req) ? final_req : ''
            })
        }
    }

 
   
    /**
     * Call the create api when user save file type
     * Method - POST
     * @param {Obj} e
     */
    onSubmit = (e) => {
        e.preventDefault();
        jQuery("#create_shift").validate({ /* */ });
        var url = 'recruitment/Recruitment_member/create_update_shift_portal';
        var validator = jQuery("#create_shift").validate({ ignore: [] });
        let errors = false;
        if (this.state.is_support_mixed) {
            let act_st_error = false

            if (this.state.actual_duration && this.state.actual_duration != '00:00') {
                act_st_error = this.supportDurationFormat(this, 'actual_support_type_duration', 0, 'duration', 'actual', false, false, true, '');
                if (act_st_error) {
                    errors = true;
                }
            }            
        }
        // Allow only validation is passed
        if (!this.state.loading && !errors && jQuery("#create_shift").valid()) {           
            const formData = new FormData()
            
            if(this.state.attachment_details && this.state.attachment_details.length > 0){
                formData.append('attachment_id', this.state.attachment_details ? this.state.attachment_details[0].id : '');
            }
            
            this.state.files.map((val, index) => {
                formData.append('attachments[]', val);
            });            

            formData.append('id', this.state.id);
            formData.append('applicant_id', getApplicantId());
            formData.append('member_id', getMemberId());
            formData.append('shift_no', this.state.shift_no);
            formData.append('actual_start_date', this.state.actual_start_date ? moment(this.state.actual_start_date).format('YYYY-MM-DD') : '');
            formData.append('actual_end_date', this.state.actual_end_date ? moment(this.state.actual_end_date).format('YYYY-MM-DD') : '');
            formData.append('actual_start_time', this.state.actual_start_time);
            formData.append('actual_end_time', this.state.actual_end_time);
            formData.append('actual_travel', this.state.actual_travel ? this.state.actual_travel : 0);
            formData.append('actual_reimbursement', this.state.actual_reimbursement ? this.state.actual_reimbursement : 0);
            formData.append('actual_travel_distance', this.state.actual_travel_distance);
            formData.append('actual_travel_duration', this.state.actual_travel_duration);
            formData.append('actual_travel_duration_hr', this.state.actual_travel_duration_hr);
            formData.append('actual_travel_duration_min', this.state.actual_travel_duration_min);
            formData.append('actual_rows', JSON.stringify(this.state.actual_rows));
            formData.append('notes', this.state.notes);
            formData.append('created_user_type', 2);
            formData.append('account', JSON.stringify(this.state.account_person));
            formData.append('account_type', this.state.account_type);
            formData.append('account_address', JSON.stringify(this.state.account_address));
            formData.append('scheduled_support_type', this.state.scheduled_support_type);
            formData.append('actual_support_type', this.state.actual_support_type);
            let actual_support_type_duration = [];
            if (this.state.is_support_mixed && this.state.actual_duration &&  this.state.actual_duration != '00:00') {
                actual_support_type_duration = this.state.actual_support_type_duration;
            }
            formData.append('actual_support_type_duration', JSON.stringify(actual_support_type_duration));

            var formdata_size = 0;
            var res = Array.from(formData.entries(), ([key, prop]) => (
                {
                    "ContentLength":
                        typeof prop === "string"
                            ? formdata_size = formdata_size + prop.length
                            : formdata_size = formdata_size + prop.size
                }));

            /**
                * calcutlate the post content limit
                * ex 
                * this.state.byte = 1048576
                * this.state.max_post = 10
                * max_post = 10 * 1048576 = 10485760
                *  
            */
            var post_limit = this.state.byte * this.state.max_post;
            if (formdata_size > post_limit) {
                var error_type = 4;
                this.extensionError(error_type);
                return false;
            }
            this.setState({ loading: true });
            // Call Api
            postImageData(url, formData).then((result) => {
                if (result.status) {
                    // Trigger success pop 
                    toastMessageShow(result.msg, 's');
                    this.props.closeModal(true);

                } else {
                    if(typeof result.error == 'string') {
                        toastMessageShow(result.error, "e");
                    }else {
                    // Trigger error pop 
                    toastMessageShow(this.setErrors(result.error), "e");
                    }
                    
                }
                this.setState({ loading: false });
            });
        } else {
            // Validation is failed
            validator.focusInvalid();
        }
    }

    //Set error message @todo need to replace this function with inline error validation message like admin portal in future
    setErrors(errors) {
        let error = [];
        if (errors && typeof errors === "object") {
            Object.keys(errors).map(key => {                    
                let err = JSON.parse(errors[key]);
                let msg = err.msg || "";
                if (msg) {
                    error.push(msg);
                }
            });
            if (error) {
                error = error.join(', ');
            }
        } else if(errors && typeof errors === "string") {
            error = errors;
        } else {
            console.log(errors)
        }        
        return error;
    }

    /**
     * Validate the uploaded file extension & file size
     */
    extensionError = (error_type, invalidFileNames) => {
        var error = <p>Sorry we are only supported <br /> jpg, jpeg, png, xlx, xls, doc, docx, pdf, odt, rtf</p>
        switch (error_type) {
            case 1:
                const firstInvalidFileName = invalidFileNames.find(Boolean)
                const reason = <>Cannot upload this file <i style={{ fontSize: 'inherit' }}>{firstInvalidFileName}</i></>
                const msg = `File types supported are ${this.allowedExtensions.join(`, `)}`

                const msgComponent = <span style={{ textAlign: 'left' }}>
                    {firstInvalidFileName && reason}
                    <br />
                    {msg}
                </span>
                error = msgComponent;
                break;
            case 2:
                error = <p>Maximum file upload size exceed.. <br /> Allowed size limit is - {this.state.max_upload} MB only</p>;
                break;
            case 3:
                error = <p>Maximum file upload size exceed.. <br /> Total allowed size limit is - {this.state.upload_mb} MB only</p>;
                break;
            case 4:
                error = <p>Post Content limit exceed.. <br /> Allowed size limit is - {this.state.max_post} MB only</p>;
                break;
            default:
                break;
        }
        toast.error(<ToastUndo message={error} showType={'e'} />, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true
        });
    }

    /**
     * mounting all the components
     */
    componentDidMount() {
        if (this.props.id) {
            this.get_shift_details(this.props.id);
            this.get_shift_timesheet_attachment_details(this.props.id);
        }
        this.callUploadFileLimit();
        this.get_shift_break_types();
    }

    /**
     * fetching the member details if the modal is opened in the edit mode
     */
    get_shift_details = (passid) => {
        var req = { id: passid, applicant_id: getApplicantId(), member_id: getMemberId() }
        postData('recruitment/Recruitment_member/get_shift_details', req).then((result) => {
            if (result.status) {
                let actual_travel_duration = result.data.actual_travel_duration;

                if (actual_travel_duration && actual_travel_duration.indexOf(":") !== -1) {
                    let actDuration = actual_travel_duration.split(":");
                    result.data.actual_travel_duration_hr = actDuration[0];
                    result.data.actual_travel_duration_min = actDuration[1];
                }
                //Get goal details against participant
                if (result.data.account_type == 1) {
                    this.get_goal_details(result.data.account_id);
                }
                let is_support_mixed = false; 
                if (result.data.actual_support_type_key_name === "mixed") {
                    is_support_mixed = true;
                }
                result.data.is_support_mixed = is_support_mixed;
                this.setState(result.data,()=>{
                    this.setState(
                        {actual_start_date:!result.data.actual_start_date?result.data.scheduled_start_date:result.data.actual_start_date,
                        actual_end_date:!result.data.actual_end_date?result.data.scheduled_end_date:result.data.actual_end_date,
                        actual_start_time:!result.data.actual_start_time?result.data.scheduled_start_time:result.data.actual_start_time,
                        actual_end_time:!result.data.actual_end_time ?result.data.scheduled_end_time:result.data.actual_end_time,
                        actual_duration:!result.data.actual_duration ?result.data.scheduled_duration:result.data.actual_duration })
                        setTimeout(() => {
                            this.updateBreakOption('actual');
                        }, 1000);
                });
            } else {
                toastMessageShow(result.error, "e");
            }
        });
    }

    // Get the shift attachment details
    get_shift_timesheet_attachment_details = (id) => {
        var req = { id: id, member_id: getMemberId() }
        postData('recruitment/Recruitment_member/get_shift_timesheet_attachment_details', req).then((result) => {
            if (result.status) {
                this.setState({ attachment_details: result.data, showAttachment: true });
            } else {
                toastMessageShow(result.error, "e");
            }
            this.setState({ loading: false });
        });
    }

    /**
     * Fetching the particiapnt goal details
     */
    get_goal_details = (participantId) => {
        let req = { applicant_id: getApplicantId(), member_id: getMemberId(), participant_id: participantId, pageSize: 9999, page: 0 }
        postData('recruitment/Recruitment_member/get_goals_list', req).then((result) => {
            if (result.status) {
                this.setState({ goalDetails: result.data });
            }
        });
    }

    /**
     * Fires when 'Upload files' button is clicked
     */
    handleClickAddMoreFiles = () => {
        const { current } = this.inputFile
        current.click()
    }

    /**
     * Determine allowed file types
     */
    determineAcceptableFileTypes() {
        return this.allowedFileTypes.join(', ')
    }

    /**
     * Fires after OS file manager was closed
     */
    handleChangeUpload = e => {

        if (e) {
            e.preventDefault()
        }

        let attachments = [...this.state.files]
        let error = false;
        let files = e.target.files

        let invalidFileNames = []
        var error_type = 1;
        Object.keys(files).map((key, i) => {
            let filename = files[key].name
            let ext = files[key].name.replace(/^.*\./, '');
            ext = ext.toLowerCase();
            let file_size_bytes = files[key].size;
            let uploaded_total_bytes = this.state.uploaded_total_bytes;
            uploaded_total_bytes = uploaded_total_bytes + file_size_bytes;
            if (this.allowedExtensions.includes(ext)) {
                // check the upload file size is exceed or not
                if (file_size_bytes > this.state.max_total_bytes) {
                    error = true;
                    error_type = 2;
                } else {
                    attachments[0] = files[key];
                    attachments[0]['ext'] = ext;
                    this.setState({ uploaded_total_bytes });
                }
            } else {
                invalidFileNames.push(filename);
                error_type = 1;
                error = true;
            }
            return;
        });

        if (error) {
            this.extensionError(error_type, invalidFileNames);
        } else {
            const newFiles = attachments.filter(Boolean)
            this.setState({ files: newFiles }, this.handleAfterStateChanged);
        }

    }

    /**
     * rendering general part of the schedule form
     */
    RenderGeneralSection() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row py-2">
                        <div className="col-lg-6 col-sm-6">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" >
                                    <abbr className="slds-required" title="required">* </abbr>Shift No:
                                </label>
                                <div className="slds-form-element__control">
                                    {this.state.shift_no || ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    /**
     * rendering actual timing part of the schedule form
     */
    RenderActualSection() {
        return (
            <React.Fragment>
                <ExpandableSection
                    id="default-expandable-section"
                    title="Actual Times"
                >
                    <div className="container-fluid">
                        <div className="row py-2">
                            <div className="col-sm-3">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" htmlFor="text-input-id-1"><abbr class="slds-required" title="required">* </abbr>
                                    Actual Start Date</label>
                                    <div className="slds-form-element__control">
                                        <div className="SLDS_date_picker_width">
                                            <SLDSISODatePicker
                                                ref={this.datepickers.actual_start_date} // !important: this is needed by this custom SLDSISODatePicker
                                                className="customer_signed_date"
                                                placeholder="Start Date"
                                                onChange={this.handleChangeDatePicker('actual_start_date')}
                                                onOpen={this.handleDatePickerOpened('actual_start_date')}
                                                onClear={this.handleChangeDatePicker('actual_start_date')}
                                                value={this.state.actual_start_date}
                                                input={<Input name="actual_start_date" />}
                                                inputProps={{
                                                    name: "actual_start_date",
                                                    required: true
                                                }}
                                                disabled={this.props.readOnly}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="slds-form-element">
                                    <div className="slds-form-element__control">
                                        <Timepicker
                                            label={<span>Start Time</span>}
                                            stepInMinutes={30}
                                            name="actual_start_time"
                                            id="actual_start_time"
                                            menuPosition="relative"
                                            formatter={(date) => {
                                                if (date) {
                                                    return moment(date).format(this.state.time_format);
                                                }
                                                return null;
                                            }}
                                            required={true}
                                            strValue={this.state.actual_start_time}
                                            onDateChange={(date, inputStr) => {
                                                this.handleChange(inputStr, "actual_start_time")
                                                this.calcActiveDuration(2, "actual_start_time", inputStr);
                                            }}
                                            disabled={this.props.readOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" htmlFor="text-input-id-1"><abbr class="slds-required" title="required">* </abbr>Actual End Date</label>
                                    <div className="slds-form-element__control">
                                        <div className="SLDS_date_picker_width">
                                            <SLDSISODatePicker
                                                ref={this.datepickers.actual_end_date} // !important: this is needed by this custom SLDSISODatePicker
                                                className="customer_signed_date"
                                                placeholder="End Date"
                                                onChange={this.handleChangeDatePicker('actual_end_date')}
                                                onOpen={this.handleDatePickerOpened('actual_end_date')}
                                                onClear={this.handleChangeDatePicker('actual_end_date')}
                                                value={this.state.actual_end_date}
                                                input={<Input name="actual_end_date" />}
                                                inputProps={{
                                                    name: "actual_end_date",
                                                    required: true
                                                }}
                                                disabled={this.props.readOnly}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="slds-form-element">
                                    <div className="slds-form-element__control">
                                        <Timepicker
                                            label={<span>End Time</span>}
                                            stepInMinutes={30}
                                            name="actual_end_time"
                                            id="actual_end_time"
                                            menuPosition="relative"
                                            formatter={(date) => {
                                                if (date) {
                                                    return moment(date).format(this.state.time_format);
                                                }
                                                return null;
                                            }}
                                            required={true}
                                            strValue={this.state.actual_end_time}
                                            onDateChange={(date, inputStr) => {
                                                this.handleChange(inputStr, "actual_end_time")
                                                this.calcActiveDuration(2, "actual_end_time", inputStr);
                                            }}
                                            disabled={this.props.readOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" >Duration</label>
                                    <div className="slds-form-element__control">
                                        <input type="text"
                                            name="actual_duration"
                                            disabled={true}
                                            placeholder="HH:MM"
                                            value={this.state.actual_duration || ''}
                                            data-rule-maxlength="6"
                                            className="slds-input" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(this.state.actual_rows.length > 0) ?
                            this.state.actual_rows.map((row, idx) => {
                                var break_type_val = row.break_type;
                                return (
                                    <div className="row py-2">
                                        <div className="col-sm-3">
                                            <label className="slds-form-element__label" htmlFor="select-01">
                                                <abbr class="slds-required" title="required">* </abbr>Break/Sleepover - {(idx + 1)}
                                            </label>
                                            <div className="slds-form-element">
                                                <div className="slds-form-element__control">
                                                    <SLDSReactSelect
                                                        simpleValue={true}
                                                        className="SLDS_custom_Select default_validation slds-select"
                                                        searchable={false}
                                                        placeholder="Please Select"
                                                        clearable={false}
                                                        required={true}
                                                        id={'break_type_' + idx}
                                                        options={this.state['actual_break_option_'+idx]}
                                                        onChange={(e) => {
                                                            handleShareholderNameChangeShift(this, 'actual_rows', idx, 'break_type', e);
                                                            this.updateDurationField('actual', idx, e);
                                                        }}
                                                        value={row.break_type}
                                                        disabled={this.props.readOnly}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="slds-form-element">
                                                <div className="slds-form-element__control">
                                                    <Timepicker
                                                        label={<span>&nbsp;Start Time</span>}
                                                        stepInMinutes={30}
                                                        name="actbreak_start_time"
                                                        id="actbreak_start_time"
                                                        disabled={row.timing_disabled}
                                                        menuPosition="relative"
                                                        formatter={(date) => {
                                                            if (date) {
                                                                return moment(date).format(this.state.time_format);
                                                            }
                                                            return null;
                                                        }}
                                                        required={false}
                                                        strValue={row.break_start_time}
                                                        onDateChange={(date, inputStr) => handleShareholderNameChangeShift(this, 'actual_rows', idx, 'break_start_time', inputStr)}
                                                        disabled={this.props.readOnly}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="slds-form-element">
                                                <div className="slds-form-element__control">
                                                    <Timepicker
                                                        label={<span>&nbsp;End Time</span>}
                                                        stepInMinutes={30}
                                                        disabled={row.timing_disabled}
                                                        name="break_end_time"
                                                        id="break_end_time"
                                                        menuPosition="relative"
                                                        formatter={(date) => {
                                                            if (date) {
                                                                return moment(date).format(this.state.time_format);
                                                            }
                                                            return null;
                                                        }}
                                                        required={false}
                                                        strValue={row.break_end_time}
                                                        onDateChange={(date, inputStr) => handleShareholderNameChangeShift(this, 'actual_rows', idx, 'break_end_time', inputStr)}
                                                        disabled={this.props.readOnly}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" >Duration</label>
                                                <div className="slds-form-element__control">
                                                    <input type="text"
                                                        name="break_duration"
                                                        placeholder="HH:MM"
                                                        disabled={this.props.readOnly || row.duration_disabled}
                                                        onChange={(e) => handleShareholderNameChangeShift(this, 'actual_rows', idx, 'break_duration', e.target.value)}
                                                        onBlur={(e) => this.get_support_type_ndis_duration('actual')}
                                                        value={row.break_duration || ''}
                                                        data-rule-maxlength="6"
                                                        maxLength="6"
                                                        data-msg-number="Please enter valid number"
                                                        className="slds-input" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-1 pt-4">
                                            {idx >= 0 ? <Button
                                                category="reset"
                                                iconSize="large"
                                                iconName="delete"
                                                title="Remove break"
                                                variant="icon"
                                                iconClassName={`slds-button__icon_delete`}
                                                onClick={() => this.removeRow(idx)}
                                                disabled={this.props.readOnly}
                                            /> : ''}
                                        </div>
                                    </div>
                                )
                            }) : ''}
                        <div className="row">
                            <div className="col-sm-9">&nbsp;</div>
                            <div className="col-sm-3">
                                <Button
                                    category="reset"
                                    iconSize="medium"
                                    iconName="new"
                                    label="Add a break"
                                    onClick={() => this.addRow()}
                                    disabled={this.props.readOnly}
                                />
                            </div>
                        </div>
                        {this.renderSupportDuration('actual')}
                        <div className="row py-2">
                            <div className="col-lg-6 col-sm-6">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" >{this.state.scheduled_travel ? <abbr class="slds-required" title="required">* </abbr> : ''}Travel (KMs)</label>
                                    <div className="slds-form-element__control">
                                        <input type="text"
                                            name="actual_travel"
                                            placeholder="KMs"
                                            onChange={(e) => {
                                                let rgx = /^[0-9]*\.?[0-9]*$/;
                                                if (e.target.value.match(rgx)) {
                                                    handleChange(this, e)
                                                }
                                            }}
                                            value={this.state.actual_travel || ''}
                                            required={this.state.scheduled_travel ? true : false}
                                            data-rule-maxlength="6"
                                            maxLength="6"
                                            data-msg-number="Please enter valid number"
                                            className="slds-input" 
                                            disabled={this.props.readOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" >{this.state.scheduled_reimbursement ? <abbr class="slds-required" title="required">* </abbr> : ''}Reimbursements ($)</label>
                                    <div className="slds-form-element__control">
                                        <input type="text"
                                            name="actual_reimbursement"
                                            placeholder="Reimbursements (in $)"
                                            onChange={(e) => {
                                                if (!isNaN(e.target.value)) {
                                                    handleDecimalChange(this, e);
                                                }
                                            }}
                                            required={this.state.scheduled_reimbursement ? true : false}
                                            maxLength="9"
                                            value={this.state.actual_reimbursement || ''}
                                            className="slds-input" 
                                            disabled={this.props.readOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-lg-6 col-sm-6">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" >Commuting Travel Allowance (Distance KMs)</label>
                                    <div className="slds-form-element__control">
                                        <input type="text"
                                            name="actual_travel_distance"
                                            placeholder="KMs"
                                            onChange={(e) => {
                                                var regx = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;
                                                if (regx.test(e.target.value) === true || e.target.value === '') {
                                                    handleChange(this, e)
                                                    this.setState({ changedValue: true });
                                                }
                                            }}
                                            value={this.state.actual_travel_distance || ''}
                                            data-msg-number="Please enter valid number"
                                            className="slds-input" 
                                            disabled={this.props.readOnly}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-sm-6">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" >Commuting Travel Allowance (Duration HH:MM)</label>
                                    <div className="slds-form-element__control">
                                        <div className="col-lg-3 col-sm-3">
                                            <input type="text"
                                                name="actual_travel_duration_hr"
                                                placeholder="0 to 24"
                                                onChange={(e) => {
                                                    var regx = /^([0-1]?[0-9]|2[0-4])$/;
                                                    if (regx.test(e.target.value) === true || e.target.value === '') {
                                                        handleChange(this, e)
                                                        this.setState({ changedValue: true });
                                                    }
                                                }}

                                                onBlur={(e) => this.handleOnBlur(e, 'actual_travel_duration_hr')}
                                                value={this.state.actual_travel_duration_hr || ''}
                                                data-msg-number="Please enter valid number"
                                                className="slds-input" 
                                                disabled={this.props.readOnly}
                                            />

                                        </div>
                                        <div className="col-lg-1 col-sm-1" style={{ paddingTop: "7px" }}>:</div>
                                        <div className="col-lg-3 col-sm-3">
                                            <input type="text"
                                                name="actual_travel_duration_min"
                                                placeholder="0 to 59"
                                                onChange={(e) => {
                                                    var regx = /^[0-5]?[0-9]$/;
                                                    if (regx.test(e.target.value) === true || e.target.value === '') {
                                                        handleChange(this, e)
                                                        this.setState({ changedValue: true });
                                                    }
                                                }}
                                                onBlur={(e) => this.handleOnBlur(e, 'actual_travel_duration_min')}
                                                value={this.state.actual_travel_duration_min || ''}
                                                data-msg-number="Please enter valid number"
                                                className="slds-input" 
                                                disabled={this.props.readOnly}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-lg-6 col-sm-6">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" htmlFor="select-01">Shift Description</label>
                                    <div className="slds-form-element__control">
                                        <textarea
                                            className="slds-textarea"
                                            name="notes"
                                            placeholder="Notes"
                                            onChange={(e) => this.handleChange(e.target.value, "notes")}
                                            value={this.state.notes ? this.state.notes : ''}
                                            disabled={this.props.readOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ExpandableSection>
            </React.Fragment>
        )
    }

    /**
     * Render Support Duration if support type is mixed
     * @param {str} index
     */
     renderSupportDuration = (index) => {
        let mixedType = this.state.is_support_mixed;
        let support_type_duration = this.state[index + '_support_type_duration'];
        let support_type_duration_error = this.state[index + '_st_duration_error'];
      
        if (mixedType && support_type_duration) {
            let durationContent = [];
            support_type_duration.map((sup_dur, idx) => {
                let duration = sup_dur.duration;
                let day_count = sup_dur.day_count;
                let durationContentTemp = this.renderDuration(duration, index, idx, day_count, sup_dur.day);
                let tempDiv = (
                    <div>
                        <div className="row py-2">
                            {day_count > 0 &&
                                <div className="col-sm-3">
                                    <label className="slds-form-element__label" htmlFor="text-input-id-1">
                                    {sup_dur.required && <abbr className="slds-required" title="required">* </abbr> }
                                    {sup_dur.label}</label>
                                </div>
                            }
                            {durationContentTemp}
                        </div>
                        <div className="row">
                            {sup_dur.error && <Label errorText={true} text={sup_dur.errorTxt} />}
                        </div>
                    </div>
                );
                durationContent.push(tempDiv);
            });
            return (
                <React.Fragment>                    
                    {durationContent}
                    {support_type_duration_error.error && <div className="row"><Label errorText={true} text={support_type_duration_error.errorTxt} /></div>}
                </React.Fragment>
            );
        }
        return <React.Fragment />;
    }

    /**
     * Render Support type input duration
     * @param {array} support_type_duration 
     * @param {str} index 
     * @param {str} p_index (Parent Index)
     * @returns 
     */
     renderDuration = (support_type_duration, section, p_index, day_count, day) => {
        let durationContent = [];
        support_type_duration.map((sup_dur, idx) => {
            let label = '';
            if (Number(sup_dur.support_type) === 1) {
                label = 'Self Care';
            } else {
                label = 'Comm Access';
            }
            let tempDiv = (
                <div className={day_count > 0 ? "col col-sm-4" : "col col-sm-6"}>
                    <Text
                        label={label +" Duration (hh:mm)"}
                        name={section + "_support_type_duration_"+idx}
                        id={section + "_support_type_duration_"+idx}
                        value={sup_dur.duration || ''}
                        placeholder="HH:MM"
                        onChange={(e) => {
                            this.setState({ changedValue: true });
                            let value = e.target.value;
                            var regex=/[0-9:]/;                            
                            this.supportDurationUpdate(this, section+'_support_type_duration', idx, 'duration', value, p_index).then((status) => {
                            })
                        }}
                        value={sup_dur.duration || ''}
                        maxLength="5"
                        required={this.state[section+'_duration'] && this.state[section+'_duration'] != '00:00' && sup_dur.required ? true : false}
                        data-msg-number="Please enter valid number"
                        onBlur={(e) => {
                            this.supportDurationFormat(this, section+'_support_type_duration', idx, 'duration', section, true, true, false, day);
                        }}
                    />
                    {sup_dur.error && <Label errorText={true} text={sup_dur.errorTxt} />}
                </div>
            );
            durationContent.push(tempDiv);
        });

        return durationContent;
    }

    /**
     * Support type duration format
     * @param {object} obj 
     * @param {str} stateName - state variable name
     * @param {int} idx - index of array
     * @param {str} fieldName - child index name
     * @param {str} section - section str
     * @param {boolean} apiCall - used to call the line item api if true
     * @param {int} p_index - parent index
     * @returns 
     */
     supportDurationFormat = (obj, stateName, idx, fieldName, section, apiCall, day_focus, save_error, day) => {
        let error = false;
        let support_type_duration = this.state[stateName];
        let duration = this.state[section+'_duration'] || '';
        let support_type = this.state[section+'_support_type'] || '';
        let support_type_duration_error = this.state[section + '_st_duration_error'];
        support_type_duration_error.error = false;
        support_type_duration_error.errorTxt = '';
        let total_duration = 0;
        let self_care = [];
        let comm_access = [];
        let se_in = 0;
        let com_in = 0;

        support_type_duration.map((sup_dur, p_idx) => {
            let durationIn = sup_dur.duration;
            let duration_min = Number(sup_dur.duration_min);
            let total_day_duration = 0;
            let day_error = false;
            let required_error = [];
            let req_err_in = 0;
            let day_count = Number(sup_dur.day_count);
            let duration_break_count = Number(sup_dur.duration_break_count);
            
            if ((day_focus && day === sup_dur.day) || save_error) {                
                sup_dur.error = false;
                sup_dur.errorTxt ='';
                durationIn.map((dur, idx) => {
                    dur.error = false;
                    dur.errorTxt ='';
                    let timeValue = dur.duration || '';
                    if(timeValue != "" && timeValue.indexOf(":") > 0)
                    {
                        let sHours = timeValue.split(':')[0];
                        let sMinutes = timeValue.split(':')[1];
                        let minute = parseInt(sHours) * 60;

                        if(parseInt(sHours) == 0 || sHours.length === 0)
                            sHours = "00";
                        
                        if (sHours <10 && sHours.length === 1)
                            sHours = "0"+sHours;

                        if(parseInt(sMinutes) == 0 || sMinutes.length === 0)
                            sMinutes = "00";
                        
                        if (sMinutes <10 && sMinutes.length === 1)
                            sMinutes = "0"+sMinutes;

                        timeValue = sHours + ":" + sMinutes;
                        dur.duration = timeValue;
                        let isValid_dur = /^\d?\d:\d{2}$/.test(timeValue);
                        if (isValid_dur) {
                            dur.error = false;
                            dur.errorTxt = '';
                        }

                        if (timeValue === '00:00' && day_count < 1) {
                            error = true;
                            dur.error = true;
                            dur.errorTxt = 'Duration must be greater than 00:00';
                        }

                        total_day_duration = total_day_duration+ (minute+parseInt(sMinutes));
                        total_duration = total_duration + (minute+parseInt(sMinutes));
                    } else {
                        let isValid = /^\d?\d:\d{2}$/.test(timeValue);
                        if (timeValue ==='' && day_count < 1) {
                            day_error = true;
                            error = true;
                            dur.error = true;
                            dur.errorTxt = 'Please enter duration (hh:mm)';
                        } else if (timeValue === '' && day_count > 0) {
                            required_error[req_err_in++] = true;
                        } else 
                        if (!isValid && timeValue !='' ) {
                            day_error = true;
                            error = true;
                            dur.error = true;
                            dur.errorTxt = 'Provide valid duration (hh:mm)';
                        } else {
                            day_error = false;
                            dur.error = false;
                            dur.errorTxt = '';
                        }                
                    }

                });
            } else {
                day_error = true;
                if (sup_dur.error) {
                    error = true;
                }
                durationIn.map((dur, idx) => {
                    dur.error = false;
                    dur.errorTxt ='';
                    let timeValue = dur.duration || '';
                    if(timeValue != "" && timeValue.indexOf(":") > 0)
                    {
                        let sHours = timeValue.split(':')[0];
                        let sMinutes = timeValue.split(':')[1];
                        let minute = parseInt(sHours) * 60;

                        if(parseInt(sHours) == 0 || sHours.length === 0)
                            sHours = "00";
                        
                        if (sHours <10 && sHours.length === 1)
                            sHours = "0"+sHours;

                        if(parseInt(sMinutes) == 0 || sMinutes.length === 0)
                            sMinutes = "00";
                        
                        if (sMinutes <10 && sMinutes.length === 1)
                            sMinutes = "0"+sMinutes;

                        total_day_duration = total_day_duration+ (minute+parseInt(sMinutes));
                        total_duration = total_duration + (minute+parseInt(sMinutes));
                    }
                });
            }

            // Self Care Duration’ (or) ‘Comm Access Duration is mandatory for a day
            if (required_error && required_error.length > 1) {
                error = true;
                sup_dur.error = true;
                sup_dur.errorTxt = 'Either the ‘Self Care Duration’ (or) ‘Comm Access Duration’ is Mandatory';
            } 
            // Per day active duration validation
            else 
            if (total_day_duration !== duration_min && day_error === false && day_count > 0 && duration_break_count < 1) {
                error = true;
                sup_dur.error = true;
                sup_dur.errorTxt = 'The specified duration for Self Care/Comm Access on '+sup_dur.day_label+' should be equal to '+sup_dur.duration_time;
            } else 
            if (total_day_duration > duration_min && day_error === false && day_count > 0 && duration_break_count > 0) {
                error = true;
                sup_dur.error = true;
                sup_dur.errorTxt = 'The specified duration for Self Care/Comm Access on '+sup_dur.day_label+' should not be more than '+sup_dur.duration_time;
            }
        });
        
        if (support_type_duration && support_type_duration.length > 0) {            
            support_type_duration.map((sup_dur, p_idx) => {
                let durationIn = sup_dur.duration;
                durationIn.map((dur, idx) => {
                    if (dur.error) {
                        error = true;
                    }
                    let timeValue = dur.duration || '';
                    if ((dur.error === false && Number(dur.support_type) === 1 ) && (timeValue === '00:00' || timeValue === '' || timeValue.indexOf(":") < 1)) {
                        let finSelfIn = self_care.findIndex(x => x === sup_dur.day_label);
                        if (finSelfIn < 0) {
                            self_care[se_in++] = sup_dur.day_label;
                        }
                        
                    }

                    if ((dur.error === false && Number(dur.support_type) === 2 ) && (timeValue === '00:00' || timeValue === '' || timeValue.indexOf(":") < 1)) {
                        let finCAIn = comm_access.findIndex(x => x === sup_dur.day_label);
                        if (finCAIn < 0) {
                            comm_access[com_in++] = sup_dur.day_label;
                        }
                    }
                });
            });

            // Both self care and comm access is mandatory 
            if (self_care.length > 1 && comm_access.length > 1  && error === false && (day_focus || save_error)) {
                error = true;
                support_type_duration_error.error = true;
                support_type_duration_error.errorTxt = 'The duration for both Self Care and Comm Access is specified at least in any one of the days';
            } else 
            if (self_care.length < 1 && comm_access.length > 1 && error === false) {
                let labelCAJoin = comm_access.join(' or ');
                error = true;
                support_type_duration_error.error = true;
                support_type_duration_error.errorTxt = 'Please specify Comm Access duration for '+labelCAJoin;
            } else if (comm_access.length < 1 && self_care.length > 1 && error === false) {
                let labelSelfJoin = self_care.join(' or ');
                error = true;
                support_type_duration_error.error = true;
                support_type_duration_error.errorTxt = 'Please specify Self Care duration for '+labelSelfJoin;
            }
        }

        // Compare duration
        if(duration != "" && duration.indexOf(":") > 0 && error === false)
        {
            let dHours = duration.split(':')[0];
            let dMinutes = duration.split(':')[1];
            let min = parseInt(dHours) * 60;
            let duration_min = (min + parseInt(dMinutes));
            if (( ((duration_min > total_duration || duration_min < total_duration) && total_duration > 0) && (support_type_duration.length === 0 || (support_type_duration && support_type_duration.length > 0 && self_care.length < 2 && comm_access.length < 2 && total_duration > 0) )  )) {
                error = true;
                support_type_duration_error.error = true;
                support_type_duration_error.errorTxt = 'The entered support type duration doesn’t match the total active duration. Please enter the correct values';
            }
        } else {
            if (total_duration > 0 && error === false) {
                error = true;
                support_type_duration_error.error = true;
                support_type_duration_error.errorTxt = 'The entered support type duration doesn’t match the total active duration. Please enter the correct values';
            }       
        }

        obj.setState({ [stateName]: support_type_duration, [section + '_st_duration_error']: support_type_duration_error });
        return error;
    }

    /**
     * Validate Duration
     * @param {str} value 
     * @returns 
     */
    validateTime = (value) => {
        let timeValue = value;
        if(timeValue == "" || timeValue.indexOf(":")<0)
        {
            return false;
        }
        else
        {
            var sHours = timeValue.split(':')[0];
            var sMinutes = timeValue.split(':')[1];

            if(sHours == "" || isNaN(sHours) || parseInt(sHours)>23)
            {
                return false;
            }
            else if(parseInt(sHours) == 0)
                sHours = "00";
            else if (sHours <10)
                sHours = "0"+sHours;

            if(sMinutes == "" || isNaN(sMinutes) || parseInt(sMinutes)>59)
            {
                return false;
            }
            else if(parseInt(sMinutes) == 0)
                sMinutes = "00";
            else if (sMinutes <10)
                sMinutes = "0"+sMinutes;    

            value = sHours + ":" + sMinutes;   
        }
        return value;    
    }

    /**
     * Handle Support type duration input 
     * @param {obj} obj 
     * @param {str} stateName 
     * @param {int} index 
     * @param {str} fieldName 
     * @param {str} value 
     * @param {event} e 
     */
     supportDurationUpdate = (obj, stateName, index, fieldName, value, p_index) => {
        return new Promise(async (resolve, reject) => {
            let List = obj.state[stateName];

            let state = {};
            List[p_index][fieldName][index][fieldName] = value;

            if (fieldName === 'duration') {
                let errTxt = this.validateTime(value);
                if (errTxt === false) {
                    // Todo
                } else {
                    List[p_index][fieldName][index]['error'] = false; 
                    List[p_index][fieldName][index]['errorTxt'] = '';
                }
            }

            state[stateName] = Object.assign([], List);
            obj.setState(state, () => {
                resolve({ status: true });
            });
        });
    }

    /**
     * Get error count 
     * @param {str} index 
     * @returns 
     */
    getErrorCountSupprtType = (index) => {
        let error = false;
        let support_type_duration = this.state[index + '_support_type_duration'];
        let support_type_duration_error = this.state[index + '_st_duration_error'];
        let total_error = 0;
        support_type_duration.map((sup_dur, idx) => {
            if (sup_dur.error) {
                total_error++;
            }
            let duration = sup_dur.duration;
            duration.map((dur) => {
                if (dur.error) {
                    total_error++;
                }
            });
        });

        if (support_type_duration_error.error) {
            total_error++;
        }

        return total_error;
    }
    
    /**
    * rendering actual timing part of the schedule form
    */
    RenderTimesheetSubmissionSection() {

        const styles = css({
            inputFile: {
                display: 'inline-block',
                border: 'unset',
                lineHeight: 'initial',
                height: 'initial',
                visibility: 'hidden',
                width: '0px',
                padding: '0px',
                marginTop: '0px',
            },
            uploadingCursor: {
                cursor: 'auto',
            },
            btnPadTop: {
                paddingTop: '1.25rem'
            }
        })
        return (
            <React.Fragment>
                <ExpandableSection
                    id="default-expandable-section"
                    title="Timesheet Submission"
                >
                    <div className="container-fluid">
                        <div className="row py-2">
                            <div className="col-lg-6 col-sm-6">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" >{this.state.scheduled_travel ? <abbr class="slds-required" title="required">* </abbr> : ''}Account</label>
                                    <div className="slds-form-element__control">
                                        <input type="text"
                                            name="accountlabel"
                                            placeholder="Account"
                                            disabled={true}
                                            value={this.state.accountlabel || ''}
                                            required={true}
                                            className="slds-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className="slds-form-element" >
                                    <label className="slds-form-element__label" htmlFor="text-input-id-6">File</label>
                                    <div className="row">
                                        {!this.props.readOnly && <div className="col-sm-3">
                                            <input
                                                type="file"
                                                name="file-uploader"
                                                accept={this.determineAcceptableFileTypes()}
                                                ref={this.inputFile}
                                                onChange={this.handleChangeUpload}
                                                value=""
                                                style={styles.inputFile}
                                                disabled={this.props.readOnly}
                                            />
                                            {

                                                
                                            this.state.isUploading ?
                                                    (
                                                        <Button
                                                            label={'Uploading...'}
                                                            style={[styles.uploadingCursor]}
                                                            title={`Accepts .pdf, .jpg, jpeg, .png`}
                                                        />
                                                    )
                                                    :
                                                    (
                                                        <Button
                                                            disabled={this.state.isUploading}
                                                            label={`Upload`}
                                                            title={`Accepts .pdf, .jpg, jpeg, .png`}
                                                            onClick={this.handleClickAddMoreFiles}
                                                        />
                                                    )
                                            }
                                        </div>
                                        }
                                        <div className="col-sm-9">
                                            {
                                                this.state.files.map((val, index) => (



                                                    <div key={index + 1} className="attach_txt pt-1">
                                                        <span>{((val.name).length > 30) ? (((val.name).substring(0, 30 - 3)) + '...' + '  (.' + val.ext + ')') : (val.name)}</span>
                                                        {!this.props.readOnly && <i onClick={(e) => {
                                                            handleRemoveShareholder(this, e, index, 'attachments').then(() => {
                                                                this.setState({ files: [], attachment_details: [] })
                                                            });
                                                        }} className="icon icon-cross-icons-1"></i>}
                                                    </div>
                                                ))}

                                            {this.state.attachment_details.length > 0 && this.state.files.length == 0 &&
                                                this.state.attachment_details.map((val, index) => (

                                                    <div key={index + 1} className="attach_txt pt-1">
                                                        <span>{((val.filename).length > 30) ? (((val.filename).substring(0, 30 - 3)) + '...' + '  (.' + val.file_ext + ')') : (val.filename)}</span>
                                                        {!this.props.readOnly && <i onClick={(e) => {
                                                            handleRemoveShareholder(this, e, index, 'attachments').then(() => {
                                                                this.setState({ files: [], attachment_details: [] })
                                                            });
                                                        }} className="icon icon-cross-icons-1"></i>}
                                                    </div>
                                                ))}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!this.props.readOnly && <div className="row py-2">
                            <div className="col-lg-6 col-sm-6"></div>
                            <div className="col-lg-6 col-sm-6"><Icon
                                assistiveText={{ label: 'User' }}
                                category="utility"
                                name="info"
                                size="x-small"
                            /> Upload the snapshot of the signed timesheet</div>
                        </div>}

                    </div>
                </ExpandableSection>
            </React.Fragment>
        )
    }
    /**
     * rendering components
     */
    render() {
        return (
            <IconSettings iconPath={"/assets/salesforce-lightning-design-system/assets/icons"}>
                <div>
                    <Modal
                        isOpen={this.props.showModal}
                        footer={[
                            <Button disabled={this.state.loading} label="Cancel" onClick={() => this.props.closeModal(false)} />,
                            !this.props.readOnly && <Button disabled={this.state.loading} label="Save" variant="brand" onClick={this.onSubmit} />
                        ]}
                        onRequestClose={this.toggleOpen}
                        heading={this.props.id ? this.props.title : " "}
                        size="small"
                        className="slds_custom_modal add_edit_shift_modal"
                        id="add_edit_shift"
                        onRequestClose={() => this.props.closeModal(false)}
                        dismissOnClickOutside={false}
                    >

                        <section className="manage_top" >
                            <form id="create_shift" autoComplete="off" className="slds_form">
                                {this.RenderGeneralSection()}
                                {this.RenderActualSection()}
                                {this.RenderTimesheetSubmissionSection()}
                            </form>
                        </section>

                    </Modal>
                </div>
            </IconSettings>
        );
    }
}

export default TimesheetShiftModal;
