import React, { Component } from 'react';
import _ from 'lodash'
import 'react-select-plus/dist/react-select-plus.css';

import { ROUTER_PATH } from 'config.js';
import { postData, css, AjaxConfirm, toastMessageShow, getApplicantId, getMemberId, getLoginToken, checkItsNotLoggedIn } from 'service/common.js';
import { Link, Redirect } from 'react-router-dom';
import moment from "moment";
import 'react-block-ui/style.css';
import 'service/jquery.validate.js';
import "service/custom_script.js";
import { connect } from 'react-redux';
import {
    Icon,
    Dropdown,Modal,Button
} from '@salesforce/design-system-react';
import {showStatusChangeConfirm, openAddEditShiftModal, updateShiftStatus} from './MemberCommon';
import SystemAlertPopup from './SystemAlertPopup';

/**
 * Renders the shifts cards in the member tab
 */
class MemberShifts extends Component {

    /**
     * default constructor
     */
    constructor(props) {
        super(props);
        checkItsNotLoggedIn();

        this.state = {
            loading: false,
            shifts: this.props.shifts,
            type: this.props.type,
            openCreateModal: false,
            shift_id: '',
            selected_option_id:'',
            selected_shift_id:'',
            selected_shift_no: '',
            showAlert:false,
            alertMessage:''
        }
    }

    /**
     * mounting all the components
     */
    componentDidMount() {

    }

    /**
     * Open create shift modal
     */
    showModal(shift_id, modal_name, status) {
        this.setState({ openCreateModal: true, shift_id: shift_id,  modal_name: modal_name, shift_status: status});
    }
    /**
     * Close the modal when user save the shift and refresh the table
     */
    closeAddEditShiftModal = (status) => {
        this.setState({openCreateModal: false});

        if(status){
            this.props.refAcceptedShifts(this.props.type);
        }
    }
    /**
     * 
     * display  System Alert
     */
     showAlert=(message='',status=null)=>{
      this.setState({showAlert:true,alertMessage:message,shiftStatus:status})
   }
    /**system alert close */
 
   closeAlert=()=>{
      this.setState({showAlert:false})
    }
    /**
     * rendering components
     */
    render() {
        if (this.props.shifts.length == 0 || !this.props.shifts) {
            return <React.Fragment />
        }

        return (
            <React.Fragment>
            <div className="slds-card__body slds-card__body_inner">
                <ul className="slds-grid slds-wrap slds-grid_pull-padded">
                    {this.props.shifts.map((value, idx) => (
                    <li className="slds-p-horizontal_small slds-size_1-of-1 slds-medium-size_1-of-3" key={idx}>
                    <article className="slds-tile slds-media slds-card__tile slds-hint-parent">
                        <div className="slds-media__figure">
                        {<Icon category="standard" name="date_input" size="small" />}
                        </div>
                        <div className="slds-media__body">
                            <div className="slds-grid slds-grid_align-spread slds-has-flexi-truncate">
                                <h2 className="slds-tile__title slds-truncate" title={value.task_name}><Link to={ROUTER_PATH + `shift/` + value.shift_id} title={`View shift information`} style={{ color: '#0070d2' }}>{value.shift_no}</Link></h2>
                                <div className="slds-shrink-none">
                                    <Dropdown
                                        align="right"
                                        assistiveText={{ icon: 'More Options' }}
                                        buttonVariant="icon"
                                        iconCategory="utility"
                                        iconName="down"
                                        iconVariant="border-filled"
                                        iconSize="x-small"
                                        width="xx-small"
                                        onSelect={(e) => {
                                            if(this.state.type == "available" || this.state.type == "available_all") {
                                                showStatusChangeConfirm(value.id, value.shift_id, value.shift_no, e.value, moment(value.scheduled_start_datetime).format("DD/MM/YYYY hh:mm A"), this.props.refAvailableShifts, this.props.refAcceptedShifts)
                                            }
                                            else if(e.id == "timesheet" || e.id == "shift_notes" ) {
                                                if(e.disabled == false) {
                                                    this.setState({selected_option_id:e.id,
                                                    selected_shift_id:value.shift_id,
                                                    selected_shift_no: value.shift_no},()=>{
                                                        this.showModal(value.shift_id,  e.id, value.status);
                                                    })
                                                }
                                            }
                                            else {
                                                if(e.disabled == false && (e.id=='complete' || e.id=='start')){
                                                    updateShiftStatus(e.id, value.shift_id, value.shift_no, this.props.refAvailableShifts, this.props.refAcceptedShifts,this.showAlert);
                                                }
                                            }
                                        }}
                                        openOn="click"
                                        options={(this.state.type == "available" || this.state.type == "available_all") ? [
                                            { label: 'Accept', value: '1' },
                                            { label: 'Decline', value: '2' },
                                        ] : [
                                            { id: 'start', label: 'Start', value: '1', disabled: (value.status == 3) ? false : true },
                                            { id: 'timesheet', label: 'Timesheet', value: '1', disabled: (value.status == 4) ? false : true },
                                            { id: 'shift_notes', label: 'Shift Notes', value: '1', disabled: (value.status == 4) ? false : true },
                                            { id: 'complete', label: 'Complete', value: '2', disabled: (value.status == 4) ? false : true },
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className="slds-tile__detail">
                                <dl className="slds-list_horizontal slds-wrap">
                                    <dt className="slds-item_label slds-text-color_weak slds-truncate" title="Account">Account:</dt>
                                    <dd className="slds-item_detail slds-truncate" title={value.account_fullname}>{value.account_fullname}</dd>

                                    {(this.state.type == "accepted" || this.state.type == "accepted_all") ? <React.Fragment><dt className="slds-item_label slds-text-color_weak slds-truncate" title="Status">Status:</dt>
                                    <dd className="slds-item_detail slds-truncate" title={value.status_label}>{value.status_label}</dd></React.Fragment> : ''}

                                    <dt className="slds-item_label slds-text-color_weak slds-truncate" title="Scheduled Start Time">Scheduled Start Time:</dt>
                                    <dd className="slds-item_detail slds-truncate" title={moment(value.scheduled_start_datetime).format("DD/MM/YYYY hh:mm A")}>{moment(value.scheduled_start_datetime).format("DD/MM/YYYY hh:mm A")}</dd>
                                    <dt className="slds-item_label slds-text-color_weak slds-truncate" title="Duration (h)">Duration (h):</dt>
                                    <dd className="slds-item_detail slds-truncate" title={value.scheduled_duration}>{value.scheduled_duration}</dd>
                                    {<React.Fragment><dt className="slds-item_label slds-text-color_weak slds-truncate" title="Address">Address:</dt>
                                    <dd className="slds-item_detail slds-truncate" title={value.address}>{value.address}</dd></React.Fragment>}
                                </dl>
                            </div>
                        </div>
                    </article>
                    </li>
                    ))}
                </ul>
            </div>
            {(this.state.type == "available" || this.state.type == "accepted" || this.state.type == "completed") ?
            <div className="slds-media__footer" style={{'border-top': '1px solid #dddbda'}}>
                <Link to={ROUTER_PATH + this.state.type + `_shifts`} className="pt-3 slds-align_absolute-center reset" title={`View all ` + this.state.type + ` shifts`} style={{ color: '#0070d2' }}>View All</Link>
            </div> : ''}

            {openAddEditShiftModal(this.state.shift_id, this.state.openCreateModal, this.closeAddEditShiftModal, this.state.modal_name, this.state.shift_status)}      
            {this.state.showAlert&&( <SystemAlertPopup  
                                        shiftStatus={this.state.shiftStatus}
                                         closeAlert ={this.closeAlert}
                                         alertMessage={this.state.alertMessage}/>)}             
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({})
const mapDispatchtoProps = (dispach) => {}

export default connect(mapStateToProps, mapDispatchtoProps)(MemberShifts);