import '../admin/scss/details.scss';
import 'react-select-plus/dist/react-select-plus.css';
import 'react-table/react-table.css';
import React, { Component } from 'react';
import { css, getApplicantId, getMemberId, postData } from 'service/common.js';
import { BASE_URL } from '../../config.js';
import jQuery from 'jquery';

const requestData = (post_url, member_id, applicant_id, doc_id, file_path, url, page, participant_id) => {

    return new Promise((resolve, reject) => {
        // request json
        var Request = { member_id: member_id, applicant_id: applicant_id, doc_id: doc_id, file_path: file_path, page: page, ac: participant_id};
        postData(post_url, Request).then((result) => {
            if (result.status) {
                window.setTimeout(function(){
                     window.location.href = url + '&link=' + result.token;            
                }, 2000);            
            } else {               
                resolve(false);            
            }
           
        });

    });
};
class CommonDocumentDownload extends Component {
    constructor(props) { 
        super(props);
        this.state = {
            applicant_id: getApplicantId(),
            member_id: getMemberId(),            
            message: ''
            
        }
    }
    componentDidMount() {        
        jQuery('#navbarheader, footer').hide();
        var sub_folder = '';
        
        const params = new URLSearchParams(this.props.props.location.search);
        
        switch (this.props.props.match.params.page) {
            case 'document':
                sub_folder = 'mediaShow/m/';
                break;                
            case 'shift': //Time sheet file upload
            case 'NA': // Need Assessment                
                sub_folder = 'mediaShow/EA/';
                break;
            default:
                break;
                
        }
        
       this.setState({base_url: BASE_URL + sub_folder + this.props.props.match.params.id + '/' + this.props.props.match.params.type + this.props.props.location.search,participant_id: params.get('ac') != undefined ? params.get('ac') : ''}, () => {
            this.fetchDocumentData(this.state);
        });
       
    }

    fetchDocumentData = (state, instance) => {
       // this.props.props.location.search - "download_as=Resume_fess_dee.docx&s3=true"
        requestData(
            'member/MemberDocumentMemberPortal/verifydocumentDownload',
            this.state.member_id,
            this.state.applicant_id,
            this.props.props.match.params.id,
            this.props.props.match.params.type.replace('%3D', ''),
            this.state.base_url,            
            this.props.props.match.params.page,
            this.state.participant_id
        ).then(res => {
            if(!res) {
               this.setState({
                    message: 'Invalid Request!',                   
                });
            }
            
        });
    }
   
    /**
     * Render Page Header
     */
   
    render() {
        const styles = css({
            root: {
                fontFamily: "Salesforce Sans, Arial, Helvetica, sans-serif",
                marginRight: -15,
                fontWeight: 'bold',
                fontSize: '14px',
                textAlign: 'center'
            }
        })
        return (
            <React.Fragment>
                <div className="container-fluid fixed_size">
                    <div className="slds slds-grid slds-grid_horizontal" style={styles.root}>
                        <div className="slds-col col-lg-12 custom_page_header max-width-res">
                           
                            <h2 > {this.state.message} </h2>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default CommonDocumentDownload;
