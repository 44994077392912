import React from 'react';
import jQuery from 'jquery';
import 'react-select-plus/dist/react-select-plus.css';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from "moment";
import { ROUTER_PATH } from 'config.js';

import { DataTableColumn, DataTableCell, PageHeader, PageHeaderControl,Icon,Dropdown,DropdownTrigger,Input,InputIcon, Button,DataTableRowActions } from '@salesforce/design-system-react'
import DataTable from '../../salesforce/components/data-table';
import { reFreshReactTable, toastMessageShow, css } from '../../services/common.js';
import { postData, getApplicantId, getMemberId } from 'service/common.js';
import { get_list_view_default_pinned, get_list_view_related_type, get_list_view_by_id} from '../../services/common_filter';

import ListViewControls from './ListViewControls';
import CommonHeaderListViewControls from './CommonHeaderListViewControls';
import CommonDataTableHeaderFilter from './CommonDataTableHeaderFilter';
import CommonDataTableCutomHeaderFilter from './CommonDataTableCutomHeaderFilter';
import { get_list_view_controls_by_default_pinned,get_list_view_controls_by_related_type, get_list_view_controls_by_id, setKeyValue } from './actions/ListViewAction'

import {IconSettings} from '@salesforce/design-system-react';

import '../../salesforce/scss/react-data-table.scss'
import { min } from 'moment';
import condition from '@salesforce/design-system-react/lib/components/expression/condition';

const listControlOption = [
	{ label: 'LIST VIEW CONTROLS', type: 'header' },
	{ label: 'All', value: 'all' },
];

/** To Customize data table column record */
const CustomDataTableCell = ({ children, ...props }) => {

    let link, dynamicProp, customvalue, status = '';

    //Note should pass the props like 'CustomDateFormat' in <DataTableColumn>
    if(props.CustomDateFormat !== undefined) {
        if (!children) {
            return <DataTableCell title={children} {...props}>
                    <span className="vcenter slds-truncate"></span>
                </DataTableCell>
        }

        const dateMoment = moment(children)
        if (!dateMoment.isValid()) {
            return <DataTableCell title={children} {...props}>
                    <span className="vcenter slds-truncate"></span>
                </DataTableCell>
        }

        children = moment(children).format(props.CustomDateFormat);
        return(
            <DataTableCell title={children} {...props}>
                <span className="vcenter slds-truncate">{children}</span>
            </DataTableCell>
        )

    }
    /*
	* Here we generate the Custom link using ...props, this props contains both <Datatable> and <Datacolumns> data's
	* For doing any other customization we can console props(console.log(props)) and then we can do the customization	*
	*/
    else if(props.CustomUrl !== undefined) {
        for(let i = 0; i <= props.CustomUrl.length; i++) {

            if (props.CustomUrl[i].url !== undefined) {

                link = props.CustomUrl[i].url;
                continue;
            }

            else if(props.CustomUrl[i].custom_value !== undefined && props.CustomUrl[i].custom_value === 'shift_no') {

                if(props.item.is_shift_locked) {
                    customvalue = <Icon
                                assistiveText={{ label: 'Lock' }}
                                category="utility"
                                colorVariant="default"
                                name="lock"
                                size="x-small"
                                className='shift-lock-icon'
                                />;
                }

            }
            if(props.CustomUrl[i].param !== undefined) {

                let id = 1;
                for(let j = 0; j< props.CustomUrl[i].param.length; j++) {
                    /**
                     * Get the dynamic params from parent page "[props.CustomUrl[i].param[i]]"
                     * this will helps to get the dynamic id. Based on the 'PARAM' we will replace and generate
                     * the URL
                     * */
                    dynamicProp = props.item[props.CustomUrl[i].param[j]];
                    link = link.replace('PARAM' + (id + j), dynamicProp);

                }
                return( <DataTableCell ClassName={customvalue ? 'data-custom-col' : ''}title={children} {...props}>
                    <a
                        href={link} class ='vcenter default-underlined slds-truncate' style={{ color: '#0070d2' }}
                    >
                        {children}
                    </a> {customvalue}
                </DataTableCell>
                )
            }

        }
    }
    else if(props.multipleCustomUrl !== undefined) {

        let isNotificationLink = false;
        switch (props.multipleCustomUrl) {
            case 'shift_account_fullname':
                link = 'admin/item/participant/details/' + props.item.account_id;

                if(props.item.account_type == 2) {
                    link = 'admin/crm/organisation/details/' + props.item.account_id;
                }
                break;
             case 'notification_title':
                isNotificationLink = true;
                if(props.item.entity_type == 8) {
                    link = 'shift/' + props.item.entity_id;
                } else {
                    link = '';
                }
                break;

            default:
                break;
        }

        if(!link) {
            return( <DataTableCell title={children} {...props}>
                    {children} 
                    
            </DataTableCell>
            );
        }

        return( <DataTableCell title={children} {...props} >
         { isNotificationLink ? 
           
                <React.Fragment>
                <a
                href={"#"} onClick = {()=>{notificationMarkAsRead(props.item.id, ROUTER_PATH + link)}} class ='vcenter default-underlined slds-truncate' style={{ color: '#0070d2' }}
            >
                {children}
            </a></React.Fragment>
            :
            
                 <a
                 href={ROUTER_PATH + link} class ='vcenter default-underlined slds-truncate' style={{ color: '#0070d2' }}
             >
                 {children}
             </a>
            }
        </DataTableCell>
        )

    }
    else if(props.property !== undefined && props.property === 'actions') {
        let disabled = false;

        if(props.item.is_shift_locked || props.item.notification_status == 1) {
            disabled = true;
        }
        return( <DataTableCell className="dt-action-dropdown" title={" "}>
                    <Dropdown
                        assistiveText={{ icon: 'More Options' }}
                        iconCategory="utility"
                        iconName="down"
                        align="right"
                        iconSize="x-small"
                        iconVariant="border-filled"
                        onSelect={(e) => {
                            props.showModal(props.item, e.key);
                        }}
                        width="xx-small"
                        disabled={disabled}
                        options={props.actionList}
                    />
                </DataTableCell>);
    }
    else if(props.col_modification !== undefined) {
        if(props.item.notification_status == 1) {
            children = "Read";
        } else if(props.item.notification_status == 0) {
            children = "Unread";
        }
        return <DataTableCell title={children} {...props}>{children}</DataTableCell>;
    }
    else {
        return <DataTableCell title={children} {...props}>{children}</DataTableCell>;
    }
}

CustomDataTableCell.displayName = DataTableCell.displayName;

//Resize table columns
const createResizableTable = function(table) {
	const cols = table.querySelectorAll('th');
	[].forEach.call(cols, function(col) {
		// Add a resizer element to the column
		const resizer = document.createElement('div');
		resizer.classList.add('resizer');

		col.appendChild(resizer);

		createResizableColumn(col, resizer);
	});
};

const createResizableColumn = function(col, resizer) {
	let x = 0;
	let w = 0;

	const mouseDownHandler = function(e) {
		x = e.clientX;

		const styles = window.getComputedStyle(col);
		w = parseInt(styles.width, 10);

		document.addEventListener('mousemove', mouseMoveHandler);
		document.addEventListener('mouseup', mouseUpHandler);

		resizer.classList.add('resizing');
	};

	const mouseMoveHandler = function(e) {
		const dx = e.clientX - x;
		col.style.width = `${w + dx}px`;
	};

	const mouseUpHandler = function() {
		resizer.classList.remove('resizing');
		document.removeEventListener('mousemove', mouseMoveHandler);
		document.removeEventListener('mouseup', mouseUpHandler);
	};

	resizer.addEventListener('mousedown', mouseDownHandler);
};

//Display No Record found
const customNoData = function(status, noDataText) {
    let noData = document.getElementById('dt-noData')

    //Remove old Text
    if(noData){
        noData.innerHTML = '';
    }

	if(status === true) {
		document.getElementById("DataTableListView-FixedHeaders").insertAdjacentHTML('afterend','<div id="dt-noData">'+ noDataText +'</div>');
		document.body.style.overflow = "inherit";
		return;
	}
}

const notificationMarkAsRead = (notification_id, url) => {

    return new Promise((resolve, reject) => {
        // request json
        var Request = { notification_id: notification_id };
        postData('admin/Notification/update_member_notification_as_readed', Request).then((result) => {
            if (result) {
                window.location.href=url;
                resolve(result);
            }
        });
    });
};


class DataTableListView extends React.Component {

	static displayName = 'DataTableListView';
	constructor(props) {
		super();
        let listViewRelatedType = this.listViewRelatedType();
		this.state = {
            sortColumn: props.sortColumn,
            sortColumnLabel: props.sortColumnLabel,
			sortColumnDirection: {},
			items: [],
			prevItems: [],
			selection: [],
			hasMore: true,
			list_api_url: props.list_api_url,
			pageSize: props.pageSize ? props.pageSize : 20,
			page: props.page ? props.page : 0,
			pages: 0,
			sorted: [],
			filtered: props.filtered,
			isLoading: false,
            noDataFlag: false,
            refreshTable: false,
            isNewFilter: false,
            totalItem: 0,
            lastUpdated: 'a few seconds',
            lastUpdatedValue: 60,

			searchVal: '',
            filterVal: '',
            dataRows: [],
            filter_status: props.filter_status || 'all',
            default_displayed_columns: Object.keys(props.displayed_columns).filter(k => !!props.displayed_columns[k]),
            displayed_columns: Object.keys(props.displayed_columns).filter(k => !!props.displayed_columns[k]),
            selectfilteroptions: props.filter_options,
            showselectedfilters: false,
            showselectfilters: false,
            selectfiltercreatedbyoptions:[],
            selectedfilval: [],
            default_filter_logic: props.default_filter_logic || '1 AND 2',
            filter_logic: '',
            list_control_option:props.list_control_option || listControlOption,
            filter_title : props.filter_title,
            filter_list_id : '',
            showFilter: props.show_filter || false,
            checkdefault: props.check_default || 'all',
            filter_related_type: listViewRelatedType[props.related_type],
            filter_error_msg: '',
            is_any_data_pinned: props.is_any_data_pinned || false,
            pinned_id: "0",
            filter_list_length: '0',
            is_own_list: false,
            user_view_by: '1',
            list_control_data:[],
            showFilterOption: false,
            refresh_filters: false,
            loading: props.loading || false,
            current_list_id: 0,
            checkedItem: 0,
            member_id: props.member_id,
            applicant_id: props.applicant_id
		};
		this.reactTable = React.createRef();
		this.rootRef = React.createRef()
	}

	listViewRelatedType() {
        return {
            contact: '1',
            organisation: '2',
            tasks: '3',
            leads: '4',
            opportunity: '5',
            need_assessment: '6',
            risk_assessment: '7',
            service_agreements: '8',
            shift: '9',
            participant: '10',
            application: '11',
            interviews: '12',
            notification: '13'
        };
	}

	componentWillReceiveProps(props) {
        const { refresh } = this.props;
        //This will helps to refresh notification table
        if(this.props.get_default_pinned_data === false && props.refresh !== refresh) {
            this.setState({refreshTable: true, sorted: true} , () => {
                this.fetchData(this.state);
            });
        }
        else if (props.refresh !== refresh) {
          this.get_default_pinned_data(this.state.filter_related_type)
        }
	}

	componentDidMount() {
		jQuery(this.rootRef.current).parent(`.col-lg-11`).removeClass(`col-lg-11`).addClass(`col-lg-12`);
        let list_id = 0;
        window.current_list_id = 0;
        if (window.location.hash.length) {
            let hash = window.location.hash;
            list_id = hash.replace('#', '');
            this.addListNameToUrlHash(hash, list_id);
            window.current_list_id = list_id;
        }

        if(this.props.get_default_pinned_data !== false) {

            this.get_default_pinned_data(this.state.filter_related_type, list_id);
        }else if(this.props.get_default_pinned_data === false) {
            this.setState({refreshTable: true, sorted: true} , () => {
                this.fetchData(this.state);
            });
        } else {
            this.refreshListView()
            this.setState({refreshTable: true, sorted: true} , () => {
                this.fetchData(this.state);
            });
        }

		document.body.className += 'datatablelist-view';
		//Trigger resize
		if(this.props.resizable !== false) {
			createResizableTable(document.getElementById('DataTableListView-FixedHeaders'));
        }

        //Update time info
        setInterval(() => {

            this.setState({lastUpdated: this.handleUpdatedTime(this.state.lastUpdatedValue),
                    lastUpdatedValue: this.state.lastUpdatedValue} , () => {
                this.setState({ lastUpdatedValue: this.state.lastUpdatedValue + 60});
            });

        }, 60000); //Update time every one minute
    }
    
    

    handleUpdatedTime = (timestamp) => {
        let hours = Math.floor(timestamp / 3600);

        let minutes = Math.floor(timestamp / 60);

        if(hours > 0) {
            let str = '';
            str = (hours > 1) ? 's' : '';
            return hours + ' hour' + str;
        } else if (minutes == 1){
            return 'a minute';
        }
        else {
            return minutes + ' minutes';
        }
    }

	/**
     * To get list view controls by pinned data and fetch value to state
     * * @param {*} related_type
     */
    get_default_pinned_data = (filter_related_type, list_id = 0) => {

        this.props.get_list_view_controls_by_default_pinned(filter_related_type, list_id)
            .then(() => {
                get_list_view_default_pinned(this, this.props.list_view_control);
                this.props.get_list_view_controls_by_related_type(filter_related_type).then(() => {
                    get_list_view_related_type(this, this.props.list_view_control_by_related_type, this.props.page_name);
                    this.get_selectedfilter_data(this.props.list_view_control.data)
                    this.setState({showFilterOption : true}, () => {

                    });
                }).catch((error) => {
                    console.log(error);
                })
            }).catch((error) => {
                console.log(error);
            })
    }
     /**
     * To get list view controls by related type
     * * @param {*} related_type
     */
    get_list_view_related_type = (filter_related_type) => {
        this.props.get_list_view_controls_by_related_type(filter_related_type).then(() => {
            get_list_view_related_type(this, this.props.list_view_control_by_related_type, this.props.page_name);
        }).catch((error) => {
            console.log(error);
        })
    }
     /**
     * To get list view controls by id
     * @param {*} related_type, id, event
     */
    get_list_view_controls_by_id = (related_type, id, event, action) => {
        var req = { related_type: related_type, filter_list_id: id }
            this.props.get_list_view_controls_by_id(req)
            .then(() => {
                get_list_view_by_id(this, this.props.list_view_control_by_id, event);
                if(this.state.list_control_data && action!='save' && action!='pin_unpin'){
                    this.get_selectedfilter_data(this.state.list_control_data);
                    this.setState({showFilterOption : true}, () => {

                    });
                }
            })
    }

    componentWillUnmount() {
        jQuery(this.rootRef.current).parent(`.col-lg-12`).removeClass(`col-lg-12`).addClass(`col-lg-11`);
        window.removeEventListener("popstate", this.onBackButtonEvent);
    }

    onBackButtonEvent = (e) => {
        let hash = window.location.hash;
        if (hash.length) {
            let list_id = hash.replace('#', '', hash);
            this.get_default_pinned_data(this.state.filter_related_type, list_id);
        } else {
            window.current_list_id = "all";
            this.get_default_pinned_data(this.state.filter_related_type, "all");
        }
    }
    showselectedfilters = (type) => {
        this.setState({ showselectfilters: false, showselectedfilters: !type }, () => {
            this.tableWidthResize();
        });
	}

	handleChanged = (event, data) => {
        let dataSelection = data.selection;
        let selection_count = dataSelection.length;
		this.setState({ selection: data.selection, checkedItem: selection_count });
	};

    //Resize Table width while open the filter dropdown
    tableWidthResize = () => {
        let tbl = document.getElementsByClassName("slds-table_header-fixed_scroller");

        tbl[0].classList.remove("shrink_table_width");

        if(this.state.showselectedfilters === true) {
            tbl[0].className += " shrink_table_width";
        }
    }

	//Sorting function
	handleSort = (sortColumn, ...rest) => {
		if (this.props.log) {
			this.props.log('sort')(sortColumn, ...rest);
		}
		const sortProperty = sortColumn.property;
		const { sortDirection } = sortColumn;
		const newState = {
			sortColumn: sortProperty,
			sortColumnDirection: {
				[sortProperty]: sortDirection,
			},
			items: [...this.state.items]

        };

        let sortColumnName = '';
        let displayedColumns = this.props.determine_columns();
        for (let i =0; i < displayedColumns.length; i++) {

            if(displayedColumns[i].accessor == sortColumn.property) {
                sortColumnName = displayedColumns[i]._label;
                break;
            }
        }

		// needs to work in both directions
		newState.items = newState.items.sort((a, b) => {
            if( a[sortProperty] &&  b[sortProperty]){
                if (sortDirection !== 'desc') {
                    return (a[sortProperty].toLowerCase().trim() > b[sortProperty].toLowerCase().trim()) ? 1 : -1;
                } else {
                    return (a[sortProperty].toLowerCase().trim() < b[sortProperty].toLowerCase().trim()) ? 1 : -1;
                }
            }else{
                var va = (a[sortProperty] === null) ? "" : "" + a[sortProperty],
                vb = (b[sortProperty] === null) ? "" : "" + b[sortProperty];
                if (sortDirection !== 'desc') {
                     return (va > vb) ? 1 : -1;
                } else {
                    return (va < vb) ? 1 : -1;
                }
            }

        });

		//Reset old state and then set new state
		this.setState({ items: [], sortColumnLabel: sortColumnName,lastUpdated: 'a few seconds', lastUpdatedValue: 60}, () => {
			this.setState(newState);
		});

    };

    refreshListView() {
        this.setState({refreshTable: !this.state.refreshTable},()=>{this.fetchData(this.state)})

    }


	//Load more Data
	handleLoadMore = () => {
		if (!this.state.isLoading) {
			setTimeout(() => {
				//Since Current page index starts with 0 so add +1 to skip the extra one hit
				if ((this.state.page + 1) >= this.state.pages) {
					this.setState({ hasMore: false });
					return false;
				}

				let moreItems = [];

				moreItems = this.state.items.map((item
				) => {
					const copy = { ...item };
					return copy;
				});

				this.setState({ page: this.state.page + 1, prevItems: moreItems, hasMore: this.state.page !== this.state.pages, refreshTable: true, lastUpdated: 'a few seconds', lastUpdatedValue: 60,sortColumnLabel: this.props.sortColumnLabel, sortColumn: this.props.sortColumn}, () => {
                    this.fetchData(this.state);
                    this.setState({ isLoading: false });
				});

            }, 1000);

		}
		this.setState({ isLoading: true });
	};

	fetchData = (state) => {
		if(state.sorted.length > 0 || this.state.refreshTable) {
            let tobefilterdata = JSON.parse(sessionStorage.getItem("filterarray"));
			if ((state.filter_list && state.filter_list.value=='all') || (this.state.filter_list && this.state.filter_list.value=='all')) {
				tobefilterdata=false;
			}

			this.setState({
				fil_pageSize: state.pageSize,
				fil_page: state.page,
				fil_sorted: state.sorted,
				fil_filtered: state.filtered,
				isloading: true
			});

			this.requestData(
				state,
				tobefilterdata,
			).then(res => {
				this.setState({
					dataRows: res.rows,
					pages: res.pages,
					isloading: true,
					noDataFlag: res.rows.length == 0 ? true : false,
                    refreshTable: false,
                    items:[],
                    totalItem: res.totalItem,
                    lastUpdated: 'a few seconds',
                    lastUpdatedValue: 60,
                    sortColumnLabel: this.props.sortColumnLabel,
                    sortColumn: this.props.sortColumn
				}, () => {

						if (this.state.page != 0) {
							this.pushPreviousRecord()
						} else {
                            let hasmore = true;
                            //Total data is less than page limit means then stop the infinite scroller
                            if(res.rows.length < this.state.pageSize) {
                                hasmore = false;
                            }
							//Set the Items state for loading data in DataTable
							this.setState({
                                items: res.rows,
                                hasMore: hasmore
							});
							//Display no record text message only on page load
							let noDataText= this.props.noDataText ? this.props.noDataText : 'No records Found';
							customNoData(this.state.noDataFlag, noDataText);
						}
					});

				if (this.props.list_api_callback) {
					this.props.list_api_callback(res);
				}
			});

		}

	}

	//Append the new page record with old record
	pushPreviousRecord() {

		let prevData = this.state.prevItems;

		const newItems = [...prevData, ...this.state.dataRows]

		//Set the Items state for loading data in DataTable on lazy loading
		this.setState({ items: newItems }, () => {
            //Scroll the table to bottom once Infinite loading is completed
            var div = document.getElementsByClassName('slds-table_header-fixed_scroller');
            div[0].scrollTop = ((div[0].scrollHeight - div[0].clientHeight) - 100);
        });

	}

	requestData(state, tobefilterdata) {
		return new Promise((resolve) => {
			// request json
			var Request = { pageSize: state.pageSize, page: state.page, sorted: state.sorted, filtered: state.filtered, save_filter_logic: false, filter_logic: this.state.filter_logic, filter_operand_length: this.state.list_control_data.filter_operand, filter_list_id: this.state.filter_list_id,
                member_id:this.state.member_id, applicant_id: this.state.applicant_id };
			if (tobefilterdata) {
				Request.tobefilterdata = tobefilterdata;
			}
			postData(state.list_api_url, Request).then((result) => {

				if (result.status) {
					let filteredData = result.data;
					const res = {
						rows: filteredData,
                        pages: (result.count),
                        totalItem: result.total_item,
					};
					resolve(res);
				} else {
					const res = {
						rows: [],
						pages: 0
					};
					resolve(res);
				}

			});

		});
	}

	submitSearch = (e) => {
        e.preventDefault();
        this.setTableParams();
    }

    setTableParams = () => {
        var search_re = { search: this.state.search, filter_status: this.state.filter_status };

        this.setState({filtered: search_re, refreshTable: true, isLoading:false ,items:[], page: 0,
            lastUpdated: 'a few seconds', lastUpdatedValue: 60, sortColumnLabel: this.props.sortColumnLabel, sortColumn:this.props.sortColumn}, () =>
        { this.fetchData(this.state)});
    }
    /**
     * To close the create or update modal
     */
    closeModal = (status) => {
        this.setState({ openCreateModal: false });

        if (status) {
            this.setState({refreshTable: true},()=>{ reFreshReactTable(this, 'fetchData');})
        }
    }
    /**
     * To close the filter option modal
     */
    closeFilter=()=>{
        this.setState({ showselectedfilters: false }, () => {
            this.tableWidthResize();
        });
    }
    handleChangeSelectFilterValue = (key, value) => {
        if(key=='filter_logic' && value==''){
            this.setState({ default_filter_logic: ''})
        }
        this.setState({ [key]: value, filter_error_msg: '' }, () => {

        })
    }

    addListNameToUrlHash(listName, listId) {
        window.removeEventListener("popstate", this.onBackButtonEvent);
        let page_name = this.props && this.props.page_name || '';
        document.title = "Healthcare Manager " + page_name + " - " + listName;
        let href = window.location.href;
        let hash = window.location.hash;
        let new_href = href;
        if (hash.length) {
            new_href = href.replace(hash, `#${listId}`);
            window.location.href = new_href;
        } else {
            window.location.href = new_href + '#' + listId;
        }
        window.addEventListener("popstate", this.onBackButtonEvent);
    }

    /**
    * On select the particular list view from list view control
    * @param {*} event
    *
    */
    onListViewChange = (e) => {
        window.removeEventListener("popstate", this.onBackButtonEvent);
        this.addListNameToUrlHash(e.label, e.value);
        window.addEventListener("popstate", this.onBackButtonEvent);
        if (e.value == 'all') {
            this.filter_list = e;
            this.setState({ filter_list:e, showFilter: false, showselectfilters: false,
                showselectedfilters: false, filter_title: this.props.filter_title, checkdefault: 'all', list_control_data: [], refreshTable: true, isLoading: false }, () => {
                    // let newState = reFreshReactDataTable(this.state);
                    let newState = { filtered: {filter_status: "all"},page: this.state.page, pageSize:this. state.pageSize,sorted: [], items: [] };
                    this.setState({newState},()=> {
                        this.fetchData(this.state);
                    });

                });
        } else {
            this.get_list_view_controls_by_id(this.state.filter_related_type, e.value, 'onChange','get');
            this.setState({ filter_list:e, hasMore:true});
        }
        this.setState({ showselectedfilters: false} , () =>{
            this.tableWidthResize();
        });
    }
    /**
    * fetch the filtered data
    */
    get_selectedfilter_data(f_data){
        let newState = {};
        if(f_data){
            var req = {
                tobefilterdata: f_data.filter_data ? JSON.parse(f_data.filter_data) : '', pageSize: this.state.fil_pageSize ? this.state.fil_pageSize : this.state.pageSize,
                page: this.state.fil_page ? this.state.fil_page: 0,
                filter_logic: f_data.filter_logic,
                filter_operand_length: f_data.filter_operand,
                filter_list_id: f_data.value,
                save_filter_logic: false
            };
            window.current_list_id = req.filter_list_id;
            if(f_data.filter_data){
                this.save_and_get_selectedfilter_data(req, 'get').then((res) => {
                    if (f_data.value && window.location.hash !== '#'+f_data.value) {
                        window.removeEventListener("popstate", this.onBackButtonEvent);
                        this.addListNameToUrlHash(f_data.label, f_data.value);
                        window.addEventListener("popstate", this.onBackButtonEvent);
                    }
                });
            }else {
                // let newState = reFreshReactDataTable(this.state);
                newState = { filtered: {filter_status: "all"},page: 0, pageSize: this.state.pageSize,sorted: [],items: [] };
                this.setState({refreshTable: true, isLoading: false}, ()=>{
                    this.setState({newState}, () => {
                        this.fetchData(this.state)});
                });

            }
        }else { //This will call while page load
			window.current_list_id = "all";
            if(this.props.is_any_action || f_data==null){

                // let newState = reFreshReactDataTable(this.state);
                newState = { filtered: {filter_status: "all"}, page: 0, pageSize: this.state.pageSize, sorted: [], items: [] };
				this.setState({refreshTable: true, isLoading: false}, ()=>{
                    this.setState({newState}, ()=> {
                        //This will triggered on page load
                        this.fetchData(this.state);});
                });

            }
        }


    }
    /**
    * save the selected filter logic based on related type
    */
    save_and_get_selectedfilter_data = (req, event) => {
        return new Promise((resolve) => {
            req.api=this.props.filter_api_url;
            let filter_api_url = this.props.filter_api_url || this.props.list_api_url;

            //This will trigger on first time load on list view control dropdown select, since we reset page number as 0
            req.page = 0;
            this.setState({ isloading: false}, () => {
                postData(filter_api_url, req).then((result) => {
                    if (result.status) {
                        let filteredData = result.data;
                        const res = {
                            rows: filteredData,
                            pages: (result.count)
                        };

                        this.setState({
                            dataRows: res.rows,
                            pages: res.pages,
                            isLoading: false,
                            showselectedfilters: false,
                            showselectfilters: false,
                            filter_error_msg:'',
                            noDataFlag: res.rows.length == 0 ? true : false,
                            refreshTable: false,
                            totalItem: result.total_item,
                            lastUpdated: 'a few seconds',
                            lastUpdatedValue: 60,
                            sortColumnLabel: this.props.sortColumnLabel,
                            sortColumn: this.props.sortColumn
                        });

                        if(event == 'save'){
                            this.get_list_view_related_type(this.state.filter_related_type);
                            this.get_list_view_controls_by_id(this.state.filter_related_type,req.filter_list_id,'update','save');
                            this.tableWidthResize();
                        }else if(event == 'custom_advanced_filter') {
                            this.setState({filter_logic: result.filter_logic ? result.filter_logic : this.state.filter_logic, filtered: true})
                            this.tableWidthResize();
                        }

                        let hasmore = true;
                        //Total data is less than page limit means then stop the infinite scroller
                        if(filteredData.length < this.state.pageSize) {
                            hasmore = false;
                        }
                        this.setState({items: []}, () => {
                            //Set the Items state for loading data in DataTable
                            this.setState({items: res.rows, hasMore: hasmore}, () => {
                                resolve(res);
                            });
                        });
                    } else {
                        this.setState({ isLoading: false, filter_error_msg:'' });
                        if(result.error){
                            if (result.msg && result.msg == 'filter_error') {
                                this.setState({ isLoading: false, filter_error_msg:result.error, refresh_filters: !this.state.refresh_filters });
                            }else{

                                toastMessageShow(result.error, "e");
                            }
                        }else {
                            const res = {
                                rows: [],
                                pages: 0
                            };
                            resolve(res);
                        }
                    }
                    //Display no record text message only on page load
                    let noDataText= this.props.noDataText ? this.props.noDataText : 'No records Found';
                    customNoData(this.state.noDataFlag, noDataText);
                });
            });
        });
    }
   
    /**
    * Fetch the common header filter
    */
    get_common_header_filter(){
        return (
            <CommonDataTableHeaderFilter
                {...this.state}
                {...this.props}
                showselectedfilters={this.state.showselectedfilters}
                closeFilter={this.closeFilter}
                get_list_view_related_type={this.get_list_view_related_type}
                save_and_get_selectedfilter_data={(req, action) => this.save_and_get_selectedfilter_data(req, action)}
                filter_error_msg={this.state.filter_error_msg}
                filter_logic={this.state.filter_logic}
                filter_related_type={this.state.filter_related_type}
                refresh_filters={this.state.refresh_filters}
            />
        )
    }

    get_common_custom_header_filter() {
        return (
            <CommonDataTableCutomHeaderFilter
                {...this.state}
                {...this.props}
                showselectedfilters={this.state.showselectedfilters}
                closeFilter={this.closeFilter}
                get_list_view_related_type={this.get_list_view_related_type}
                save_and_get_selectedfilter_data={(req, action) => this.save_and_get_selectedfilter_data(req, action)}
                filter_error_msg={this.state.filter_error_msg}
                filter_logic={this.state.filter_logic}
                filter_related_type={this.state.filter_related_type}
                refresh_filters={this.state.refresh_filters}

            />
        )
    }

    /**
    * Datatable Row action helper function
    */
    handleRowAction = (item, action) => {
        this.props.showModal(item, action.key);
    }

	render() {
		//Get the columns
		const columns = this.props.determine_columns();
		const displayedColumns = columns.filter(col => this.state.displayed_columns.indexOf(col.id || col.accessor) >= 0)
		let newcolumns = [];

        // count selected
        let checkItemLabel = '';
        if (this.props.checkedItem && this.props.checkedItem > 0) {
            let str_checked = this.props.checkedItem < 2 ? ' Item selected' : ' Items selected';
            checkItemLabel = '• '+ this.props.checkedItem + str_checked;
        }
        if (this.state.checkedItem && this.state.checkedItem > 0) {
            let str_checked = this.state.checkedItem < 2 ? ' Item selected' : ' Items selected';
            checkItemLabel = '• '+ this.state.checkedItem + str_checked;
        }

		for (let i = 0; i < displayedColumns.length; i++) {

			//Form the table td columns as per DataTable format
            newcolumns.push(
                <DataTableColumn
					key={displayedColumns[i].accessor}
                    label={displayedColumns[i]._label}
                    header={displayedColumns[i]._label}
					property={displayedColumns[i].accessor}
                    isSorted={this.state.sortColumn === displayedColumns[i].id}
					sortable={displayedColumns[i].sortable === false ? false : true}
                    CustomUrl={displayedColumns[i].CustomUrl}
                    multipleCustomUrl={displayedColumns[i].multipleCustomUrl}
                    CustomDateFormat={displayedColumns[i].CustomDateFormat}
                    actionList={displayedColumns[i].actionList}
                    showModal={this.props.showModal}
                    col_modification={displayedColumns[i].col_modification}
                    width={displayedColumns[i].width ? displayedColumns[i].width : ''}
                >
					{/* For doing col data customization we should use CustomDataTableCell */}
					<CustomDataTableCell/>
				</DataTableColumn>);

        }

		return (
            <div className={this.props.selectRows ? '': 'wocheckbox'}>
			<div className="slds" id="listViewDiv" ref={this.rootRef}
				style={{
					height: 'calc(100vh - 280px)',
					fontFamily: "Salesforce Sans, Arial, Helvetica, sans-serif",
				}}
			>
				<IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                <PageHeader
                    icon={ this.props.get_default_pinned_data === false ? <Icon
                        category="standard" name = {this.props.header_icon}/> :
                        <Icon
                            assistiveText={{
                                label: this.props.page_name,
                            }}
                            category="standard"
                            name={this.props.header_icon || "opportunity"}
                            style={this.props.icon_style || {
                                backgroundColor: '#ea7600',
                                fill: '#ffffff',
                            }}
                            title={this.props.page_name}
                        />
                    }
                    info=  {this.props.is_header_info === false ? "" : `${this.state.totalItem !== undefined ? this.state.totalItem : 0 } items • Sorted by ${this.state.sortColumnLabel} • Filtered by ${this.state.filter_title} • Updated ${this.state.lastUpdated} ago ${checkItemLabel} `}
                    label={this.props.get_default_pinned_data !== false ? this.props.page_name : ''}
                    //Fetch the common list view controls pin and unpin modal
                    nameSwitcherDropdown={ this.props.get_default_pinned_data !== false ?
                        <CommonHeaderListViewControls
                        filter_list_id={this.state.filter_list_id}
                        list_control_option={this.state.list_control_option}
                        onListViewChange={(e) => this.onListViewChange(e)}
                        get_list_view_controls_by_id={this.get_list_view_controls_by_id}
                        filter_related_type={this.state.filter_related_type}
                        get_default_pinned_data={(related_type) => this.get_default_pinned_data(related_type)}
                        filter_title={this.state.filter_title}
                        is_any_data_pinned={this.state.is_any_data_pinned || false}
                        pinned_id={this.state.pinned_id}
                        checkdefault={this.state.checkdefault}
                        /> : ''
                    }
                    onRenderActions={() => {
                        return this.props.on_render_actions()
                    }
                    }
                    onRenderControls={() => {
                        return (
                            <React.Fragment>
                                <PageHeaderControl>
                                    <form
                                        autoComplete="off"
                                        onSubmit={(e) => this.submitSearch(e)}
                                        method="post"
                                        className="slds-col_padded"
                                        style={{ display: 'block' }}
                                    >
                                        <Input
                                            iconLeft={
                                                <InputIcon
                                                    assistiveText={{
                                                        icon: 'Search',
                                                    }}
                                                    name="search"
                                                    category="utility"
                                                />
                                            }
                                            onChange={(e) => this.setState({ search: e.target.value })}
                                            id="ListContact-search-1"
                                            placeholder={`Search ${this.props.page_name}`}
                                        />
                                    </form>
                                </PageHeaderControl>
                                <PageHeaderControl>
                                {/* Fetch the common list view vontrols */}
                                    {this.props.is_list_view_control !== false ? <ListViewControls
                                    get_default_pinned_data={this.get_default_pinned_data}
                                    get_list_view_controls_by_id ={this.get_list_view_controls_by_id}
                                    get_list_view_related_type={this.get_list_view_related_type}
                                    {...this.state}
                                    addListNameToUrlHash={this.addListNameToUrlHash}
                                    onBackButtonEvent={this.onBackButtonEvent}
                                    /> : ''}
                                </PageHeaderControl>
                                <PageHeaderControl>
                                    {
                                        (() => {
                                            const mapColumnsToOptions = columns.map(col => ({
                                                value: 'id' in col ? col.id : col.accessor,
                                                label: col._label,
                                            }))
                                            return (
                                                <Dropdown
                                                    align="right"
                                                    checkmark
                                                    multiple
                                                    assistiveText={{ icon: 'More' }}
                                                    iconCategory="utility"
                                                    iconName="settings"
                                                    iconVariant="more"
                                                    options={mapColumnsToOptions}
                                                    value={this.state.default_displayed_columns}
                                                    onSelect={option => {
                                                        const value = option.value

                                                        let cols = [...this.state.displayed_columns]
                                                        if (cols.indexOf(value) >= 0) {
                                                            cols = cols.filter(col => col !== value)
                                                        } else {
                                                            cols = [...this.state.displayed_columns, value]
                                                        }

                                                        this.setState({ displayed_columns: cols,lastUpdated: 'a few seconds',
                                                            lastUpdatedValue: 60, sortColumnLabel: this.props.sortColumnLabel, sortColumn:this.props.sortColumn })
                                                    }}
                                                >
                                                    <DropdownTrigger>
                                                        <Button
                                                            title={`Show/hide columns`}
                                                            assistiveText={{ icon: 'Show/hide columns' }}
                                                            iconCategory="utility"
                                                            iconName="table"
                                                            iconVariant="more"
                                                            variant="icon"
                                                        />
                                                    </DropdownTrigger>
                                                </Dropdown>
                                            )
                                        })()
                                    }
                                </PageHeaderControl>

                                <PageHeaderControl>
                                    {this.state.showFilter ? <button onClick={() => this.showselectedfilters(this.state.showselectedfilters)} className="slds-button slds-button_icon-more ignore-click-lWJnKo2QxH" tabIndex="0" title="Filter by status" type="button" aria-expanded="false" aria-haspopup="true">
                                        <svg aria-hidden="true" className="slds-button__icon">
                                            <use href="/assets/salesforce-lightning-design-system/assets/icons/utility-sprite/svg/symbols.svg#filterList"></use>
                                        </svg>
                                        <svg aria-hidden="true" className="slds-button__icon slds-button__icon_x-small">
                                            <use href="/assets/salesforce-lightning-design-system/assets/icons/utility-sprite/svg/symbols.svg#down">
                                            </use>
                                        </svg>
                                        <span className="slds-assistive-text">Filter</span>
                                    </button> : <Button
                                            title={`Filter`}
                                            assistiveText={{ icon: 'Filter' }}
                                            iconCategory="utility"
                                            iconName="filterList"
                                            iconVariant="border-filled"
                                            variant="icon"
                                            disabled={true}
                                        />}
                                </PageHeaderControl>
                                    {this.state.showselectedfilters &&
                                    <PageHeaderControl>
                                        {this.props.custom_header_filter ? this.get_common_custom_header_filter() :
                                         this.get_common_header_filter()}
                                    </PageHeaderControl>
                                }
                            </React.Fragment>

                        )
                    }}
                    title={this.state.filter_title}
                    truncate
                    variant="object-home"
                    buttonIcon={
                        <Button
                            title={`To unpin, pin another list view`}
                            iconCategory="utility"
                            iconName="pinned"
                            variant="icon"
                            iconSize="large"
                        />
                    }
                />

					<DataTable
						assistiveText={{
							actionsHeader: 'actions',
							columnSort: 'sort this column',
							columnSortedAscending: 'asc',
							columnSortedDescending: 'desc',
							selectAllRows: 'Select all rows',
							selectRow: 'Select this row',
						}}
						columnBordered={this.props.columnBordered ? this.props.columnBordered : false}
						fixedHeader={this.props.fixedHeader ? this.props.fixedHeader : true}
						fixedLayout={this.props.fixedLayout ? this.props.fixedLayout : true}
						items={this.state.items}
						id="DataTableListView-FixedHeaders"
						onRowChange={this.props.selectionHandleChange?this.props.selectionHandleChange:this.handleChanged}
						onSort={this.handleSort}
						selection={this.props.selection}
						selectRows={this.props.selectRows ? this.props.selectRows : ''}
                        hasMore={this.state.items.length > 0 ? this.state.hasMore : false}
						onLoadMore={this.handleLoadMore}
                        loadMoreOffset={this.props.loadMoreOffset ? this.props.loadMoreOffset : 20}
                        key="DataTableListView-FixedHeaders"
					>
						{/* Render Table columns */}
						{newcolumns}

					</DataTable>

				</IconSettings>
                </div>
			</div>
		);
	}
}

ReactTable.PropTypes = {
    defaultFiltered: PropTypes.object
}
const mapStateToProps = state => ({

    // showPageTitle: state.DepartmentReducer.activePage.pageTitle,
    // showTypePage: state.DepartmentReducer.activePage.pageType,
    // list_view_control: state.ListViewControlActivityReducer.list_view_control,
    // loading: state.ListViewControlActivityReducer.activity_loading,
    // list_view_control_by_related_type: state.ListViewControlActivityReducer.list_view_control_by_related_type,
    // list_view_control_by_id: state.ListViewControlActivityReducer.list_view_control_by_id,
})

const mapDispatchtoProps = (dispatch) => {
    return {
        get_list_view_controls_by_default_pinned: (request, list_id) => dispatch(get_list_view_controls_by_default_pinned(request, list_id)),
        get_list_view_controls_by_related_type: (request) => dispatch(get_list_view_controls_by_related_type(request)),
        get_list_view_controls_by_id: (request) => dispatch(get_list_view_controls_by_id(request)),
        setKeyValue: (request) => dispatch(setKeyValue(request)),
    }
}
// export default DataTableListView;
export default connect(mapStateToProps, mapDispatchtoProps)(DataTableListView);