import React from 'react';
import { toastMessageShow, postData } from '../../../../../service/common';

/**
 * @typedef {typeof AttachmentCard.defaultProps} Props
 * 
 * Card to display uploaded attachments
 * 
 * @extends {React.Component<Props>}
 */
class MobilityCard extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        existingAttachments : 0
      }
    }
  
    componentDidMount() {
      if(this.props.need_assessment_id){
          this.getSelectedMobility(this.props.need_assessment_id);
      }
    }
  
    getSelectedMobility = (id)=> {
      postData("sales/NeedAssessment/get_selected_mobility", {need_assessment_id: id}).then((res) => {
        if (res.status) {
            this.setState(res.data)
        }
      });
    }    
  
    showInfoMsg = (obj, e) => {
      toastMessageShow('Please upload Manual Handling Plan in Attachments', "w");
    } 
  
    /** Get selected aid count */
    get_aid_selected_count = ()=> {
      let aid_cnt = 0;
      if(this.state.inout_bed == "5") {
        aid_cnt = aid_cnt + 1;
      }
      if(this.state.inout_shower == "5") {
        aid_cnt = aid_cnt + 1;
      }
      if(this.state.onoff_toilet == "5") {
        aid_cnt = aid_cnt + 1;
      }
      if(this.state.inout_chair == "5" ) {
        aid_cnt = aid_cnt + 1;
      }
      if(this.state.inout_vehicle == "5") {
        aid_cnt = aid_cnt + 1;
      }
  
      return aid_cnt;
    }
  
    render() {
      return (
        <React.Fragment>
          <div className="slds-grid">
              <div className="slds-panel slds-size_full slds-is-open" aria-hidden="false">
                <form id="mobility_form" autoComplete="off" className="col-md-12 slds_form">
                  <div className="slds-panel__header">
                    <h2 className="slds-panel__header-title slds-text-heading_small slds-truncate" title="Panel Header">Mobility</h2>
                  </div>
                  <div className="slds-panel__body">
                    <div className="slds-form-element">
                      <div className="slds-form-element__control">
                        <div className="slds-checkbox">
                          <input type="checkbox" name="not_applicable" id="not_applicable_chkbox"  checked={(this.state.not_applicable && this.state.not_applicable=='1')?true:false} disabled/>
                          <label className="slds-checkbox__label" htmlFor="not_applicable_chkbox">
                            <span className="slds-checkbox_faux"></span>
                            <span className="slds-form-element__label">Not applicable</span>
                          </label>
                        </div>
                      </div>
                    </div>
  
                    <fieldset className="slds-form-element">
                      <legend className="slds-form-element__legend slds-form-element__label">Can you Mobilize?</legend>
                      <div className="slds-form-element__control">
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="can_mobilize_2" value="1" name="can_mobilize" disabled checked={(this.state.can_mobilize && this.state.can_mobilize == 1)?true:false}/>
                          <label className="slds-radio__label" htmlFor="can_mobilize_2">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">No</span>
                          </label>
                        </span>
  
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="can_mobilize_1" value="2" name="can_mobilize" disabled checked={(this.state.can_mobilize && this.state.can_mobilize == 2)? true : false}/>
                          <label className="slds-radio__label" htmlFor="can_mobilize_1">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">Yes</span>
                          </label>
                        </span>
                      </div>
                    </fieldset>
  
                    <fieldset className="slds-form-element">
                      <legend className="slds-form-element__legend slds-form-element__label">Short distances?</legend>
                      <div className="slds-form-element__control">
  
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="short_distances_2" value="1" name="short_distances" disabled checked={(this.state.short_distances &&this.state.short_distances == 1) ? true : false}/>
                          <label className="slds-radio__label" htmlFor="short_distances_2">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">No</span>
                          </label>
                        </span>
  
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="short_distances_1" value="2" name="short_distances" disabled checked={(this.state.short_distances && this.state.short_distances == 2)?true:false}/>
                          <label className="slds-radio__label" htmlFor="short_distances_1">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">Yes</span>
                          </label>
                        </span>
                      </div>
                    </fieldset>
  
                    <fieldset className="slds-form-element">
                      <legend className="slds-form-element__legend slds-form-element__label">Long distances?</legend>
                      <div className="slds-form-element__control">
  
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="long_distances_2" value="1" name="long_distances" disabled checked={(this.state.long_distances &&this.state.long_distances == 1)?true:false}/>
                          <label className="slds-radio__label" htmlFor="long_distances_2">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">No</span>
                          </label>
                        </span>
  
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="long_distances_1" value="2" name="long_distances" disabled checked={(this.state.long_distances && this.state.long_distances == 2)?true:false}/>
                          <label className="slds-radio__label" htmlFor="long_distances_1">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">Yes</span>
                          </label>
                        </span>
                      </div>
                    </fieldset>
  
                    <fieldset className="slds-form-element">
                      <legend className="slds-form-element__legend slds-form-element__label">Up/down stairs?</legend>
                      <div className="slds-form-element__control">
  
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="up_down_stairs_2" value="1" name="up_down_stairs" disabled checked={(this.state.up_down_stairs &&this.state.up_down_stairs == 1)?true:false}/>
                          <label className="slds-radio__label" htmlFor="up_down_stairs_2">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">No</span>
                          </label>
                        </span>
  
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="up_down_stairs_1" value="2" name="up_down_stairs" disabled checked={(this.state.up_down_stairs && this.state.up_down_stairs == 2)?true:false}/>
                          <label className="slds-radio__label" htmlFor="up_down_stairs_1">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">Yes</span>
                          </label>
                        </span>
                      </div>
                    </fieldset>
  
                    <fieldset className="slds-form-element">
                      <legend className="slds-form-element__legend slds-form-element__label">Uneven surfaces?</legend>
                      <div className="slds-form-element__control">
  
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="uneven_surfaces_2" value="1" name="uneven_surfaces" disabled checked={(this.state.uneven_surfaces &&this.state.uneven_surfaces == 1) ? true:false}/>
                          <label className="slds-radio__label" htmlFor="uneven_surfaces_2">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">No</span>
                          </label>
                        </span>
  
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="uneven_surfaces_1" value="2" name="uneven_surfaces" disabled checked={(this.state.uneven_surfaces && this.state.uneven_surfaces == 2)?true:false}/>
                          <label className="slds-radio__label" htmlFor="uneven_surfaces_1">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">Yes</span>
                          </label>
                        </span>
                      </div>
                    </fieldset>
  
                    <fieldset className="slds-form-element">
                      <legend className="slds-form-element__legend slds-form-element__label">In/out of bed</legend>
                      <div className="slds-form-element__control">
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="inout_bed_1" value="1" name="inout_bed" disabled  checked={(this.state.inout_bed && this.state.inout_bed == 1)?true:false}/>
                          <label className="slds-radio__label" htmlFor="inout_bed_1">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">Not applicable</span>
                          </label>
                        </span>
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="inout_bed_5" value="5" name="inout_bed" disabled checked={(this.state.inout_bed &&this.state.inout_bed == 5) ? true : false}/>
                          <label className="slds-radio__label" htmlFor="inout_bed_5">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">With Aids and Equipment</span>
                          </label>
                        </span>
  
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="inout_bed_2" value="2" name="inout_bed" disabled  checked={(this.state.inout_bed &&this.state.inout_bed == 2)?true:false}/>
                          <label className="slds-radio__label" htmlFor="inout_bed_2">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">With assistance</span>
                          </label>
                        </span>
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="inout_bed_3" value="3" name="inout_bed" disabled checked={(this.state.inout_bed &&this.state.inout_bed == 3)?true:false}/>
                          <label className="slds-radio__label" htmlFor="inout_bed_3">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">With supervision</span>
                          </label>
                        </span>
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="inout_bed_4" value="4" name="inout_bed" disabled  checked={(this.state.inout_bed &&this.state.inout_bed == 4)?true:false}/>
                          <label className="slds-radio__label" htmlFor="inout_bed_4">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">Independant</span>
                          </label>
                        </span>
                      </div>
                    </fieldset>
  
                    {
                      (this.state.inout_bed && this.state.inout_bed == '5') ?
                        <fieldset className="slds-form-element mb-3">
                          <div className="col-md-6">
                            <div className="slds-form-element__control">
                              <div className="SLDS_date_picker_width">
                                <label style={{fontWeight: "bold", padding: "3px", fontSize: "12px"}}>Please, enter type of equipment used</label>
                                <div>{this.state.inout_bed_equipment_used}</div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      : ''
                    }
  
                    <fieldset className="slds-form-element">
                      <legend className="slds-form-element__legend slds-form-element__label">In/out of shower/bath</legend>
                      <div className="slds-form-element__control">
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="inout_shower_1" value="1" name="inout_shower" disabled  checked={(this.state.inout_shower && this.state.inout_shower == 1)?true:false}/>
                          <label className="slds-radio__label" htmlFor="inout_shower_1">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">Not applicable</span>
                          </label>
                        </span>
  
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="inout_shower_5" value="5" name="inout_shower" disabled checked={(this.state.inout_shower &&this.state.inout_shower == 5)?true:false}/>
                          <label className="slds-radio__label" htmlFor="inout_shower_5">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">With Aids and Equipment</span>
                          </label>
                        </span>
  
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="inout_shower_2" value="2" name="inout_shower" disabled checked={(this.state.inout_shower &&this.state.inout_shower == 2)?true:false}/>
                          <label className="slds-radio__label" htmlFor="inout_shower_2">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">With assistance</span>
                          </label>
                        </span>
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="inout_shower_3" value="3" name="inout_shower" disabled  checked={(this.state.inout_shower &&this.state.inout_shower == 3)?true:false}/>
                          <label className="slds-radio__label" htmlFor="inout_shower_3">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">With supervision</span>
                          </label>
                        </span>
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="inout_shower_4" value="4" name="inout_shower" disabled checked={(this.state.inout_shower &&this.state.inout_shower == 4)?true:false}/>
                          <label className="slds-radio__label" htmlFor="inout_shower_4">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">Independant</span>
                          </label>
                        </span>
                      </div>
                    </fieldset>
  
                    {
                      (this.state.inout_shower && this.state.inout_shower == '5') ?
                        <fieldset className="slds-form-element mb-3">
                          <div className="col-md-6">
                            <div className="slds-form-element__control">
                              <div className="SLDS_date_picker_width">
                                <label style={{fontWeight: "bold", padding: "3px", fontSize: "12px"}}>Please, enter type of equipment used</label>
                                <div>{this.state.inout_shower_equipment_used}</div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      : ''
                    }
  
                    <fieldset className="slds-form-element">
                      <legend className="slds-form-element__legend slds-form-element__label">On/off toilet</legend>
                      <div className="slds-form-element__control">
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="onoff_toilet_1" value="1" name="onoff_toilet" disabled  checked={(this.state.onoff_toilet && this.state.onoff_toilet == 1)?true:false}/>
                          <label className="slds-radio__label" htmlFor="onoff_toilet_1">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">Not applicable</span>
                          </label>
                        </span>
  
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="onoff_toilet_5" value="5" name="onoff_toilet" disabled checked={(this.state.onoff_toilet &&this.state.onoff_toilet == 5)?true:false}/>
                          <label className="slds-radio__label" htmlFor="onoff_toilet_5">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">With Aids and Equipment</span>
                          </label>
                        </span>
  
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="onoff_toilet_2" value="2" name="onoff_toilet" disabled checked={(this.state.onoff_toilet &&this.state.onoff_toilet == 2)?true:false}/>
                          <label className="slds-radio__label" htmlFor="onoff_toilet_2">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">With assistance</span>
                          </label>
                        </span>
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="onoff_toilet_3" value="3" name="onoff_toilet" disabled checked={(this.state.onoff_toilet &&this.state.onoff_toilet == 3)?true:false}/>
                          <label className="slds-radio__label" htmlFor="onoff_toilet_3">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">With supervision</span>
                          </label>
                        </span>
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="onoff_toilet_4" value="4" name="onoff_toilet" disabled checked={(this.state.onoff_toilet &&this.state.onoff_toilet == 4)?true:false}/>
                          <label className="slds-radio__label" htmlFor="onoff_toilet_4">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">Independant</span>
                          </label>
                        </span>
                      </div>
                    </fieldset>
  
                    {
                      (this.state.onoff_toilet && this.state.onoff_toilet == '5') ?
                        <fieldset className="slds-form-element mb-3">
                          <div className="col-md-6">
                            <div className="slds-form-element__control">
                              <div className="SLDS_date_picker_width">
                                <label style={{fontWeight: "bold", padding: "3px", fontSize: "12px"}}>Please, enter type of equipment used</label>
                                <div>{this.state.onoff_toilet_equipment_used}</div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      : ''
                    }
  
                    <fieldset className="slds-form-element">
                      <legend className="slds-form-element__legend slds-form-element__label">In/out of chair/wheelchair</legend>
                      <div className="slds-form-element__control">
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="inout_chair_1" value="1" name="inout_chair" disabled  checked={(this.state.inout_chair && this.state.inout_chair == 1)?true:false}/>
                          <label className="slds-radio__label" htmlFor="inout_chair_1">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">Not applicable</span>
                          </label>
                        </span>
  
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="inout_chair_5" value="5" name="inout_chair" disabled checked={(this.state.inout_chair &&this.state.inout_chair == 5)?true:false}/>
                          <label className="slds-radio__label" htmlFor="inout_chair_5">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">With Aids and Equipment</span>
                          </label>
                        </span>
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="inout_chair_2" value="2" name="inout_chair" disabled checked={(this.state.inout_chair &&this.state.inout_chair == 2)?true:false}/>
                          <label className="slds-radio__label" htmlFor="inout_chair_2">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">With assistance</span>
                          </label>
                        </span>
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="inout_chair_3" value="3" name="inout_chair" disabled checked={(this.state.inout_chair &&this.state.inout_chair == 3)?true:false}/>
                          <label className="slds-radio__label" htmlFor="inout_chair_3">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">With supervision</span>
                          </label>
                        </span>
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="inout_chair_4" value="4" name="inout_chair" disabled checked={(this.state.inout_chair &&this.state.inout_chair == 4)?true:false}/>
                          <label className="slds-radio__label" htmlFor="inout_chair_4">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">Independant</span>
                          </label>
                        </span>
                      </div>
                    </fieldset>
  
                    {
                      (this.state.inout_chair && this.state.inout_chair == '5') ?
                        <fieldset className="slds-form-element mb-3">
                          <div className="col-md-6">
                            <div className="slds-form-element__control">
                              <div className="SLDS_date_picker_width">
                                <label style={{fontWeight: "bold", padding: "3px", fontSize: "12px"}}>Please, enter type of equipment used</label>
                                <div>{this.state.inout_chair_equipment_used}</div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      : ''
                    }
  
                    <fieldset className="slds-form-element">
                      <legend className="slds-form-element__legend slds-form-element__label">In/out of vehicle</legend>
                      <div className="slds-form-element__control">
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="inout_vehicle_1" value="1" name="inout_vehicle" disabled  checked={(this.state.inout_vehicle && this.state.inout_vehicle == 1)?true:false}/>
                          <label className="slds-radio__label" htmlFor="inout_vehicle_1">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">Not applicable</span>
                          </label>
                        </span>
  
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="inout_vehicle_5" value="5" name="inout_vehicle" disabled checked={(this.state.inout_vehicle &&this.state.inout_vehicle == 5)?true:false}/>
                          <label className="slds-radio__label" htmlFor="inout_vehicle_5">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">With Aids and Equipment</span>
                          </label>
                        </span>
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="inout_vehicle_2" value="2" name="inout_vehicle" disabled checked={(this.state.inout_vehicle &&this.state.inout_vehicle == 2)?true:false}/>
                          <label className="slds-radio__label" htmlFor="inout_vehicle_2">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">With assistance</span>
                          </label>
                        </span>
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="inout_vehicle_3" value="3" name="inout_vehicle" disabled checked={(this.state.inout_vehicle &&this.state.inout_vehicle == 3)?true:false}/>
                          <label className="slds-radio__label" htmlFor="inout_vehicle_3">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">With supervision</span>
                          </label>
                        </span>
                        <span className="slds-radio slds-float_left">
                          <input type="radio" id="inout_vehicle_4" value="4" name="inout_vehicle" disabled checked={(this.state.inout_vehicle &&this.state.inout_vehicle == 4)?true:false}/>
                          <label className="slds-radio__label" htmlFor="inout_vehicle_4">
                            <span className="slds-radio_faux"></span>
                            <span className="slds-form-element__label">Independant</span>
                          </label>
                        </span>
                      </div>
                    </fieldset>
  
                    {
                      (this.state.inout_vehicle && this.state.inout_vehicle == '5') ?
                        <fieldset className="slds-form-element mb-3">
                          <div className="col-md-6">
                            <div className="slds-form-element__control">
                              <div className="SLDS_date_picker_width">
                                <label style={{fontWeight: "bold", padding: "3px", fontSize: "12px"}}>Please, enter type of equipment used</label>
                                <div> {this.state.inout_vehicle_equipment_used}</div>                               
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      : ''
                    }
                  </div>                  
                </form>
              </div>
          </div>
        </React.Fragment >
      );
    }
  
}

export default MobilityCard