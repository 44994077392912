
/**
 * @typedef {keyof crmActiveTitle} CrmActiveTitleKey
 */


export const ListViewRelatedType = {
    contact: '1',
    organisation: '2',
    tasks: '3',
    leads: '4',
    opportunity: '5',
    need_assessment: '6',
    risk_assessment: '7',
    service_agreements: '8',
    shift: '9',
    application: '11'
};

export const selectFilterOperatorOptions = [
    { value: "equals", label: "equals", symbol: "=" },
    { value: "not equal to", label: "not equal to", symbol: "<>" },
    { value: "less than", label: "less than", symbol: "<" },
    { value: "greater than", label: "greater than", symbol: ">" },
    { value: "greater or equal", label: "greater or equal", symbol: ">=" },
    { value: "less or equal", label: "less or equal", symbol: "<=" },
    { value: "contains", label: "contains", symbol: "" },
    { value: "does not contains", label: "does not contains", symbol: "" },
    { value: "starts with", label: "starts with", symbol: "" }
]
// for all page - 1-contact
export const selectFilterTypeOptions = function (filter_related_type) {
    filter_related_type = parseInt(filter_related_type);
    switch (filter_related_type) {
        case 1:
            return [
                { value: "Applicant", label: "Applicant" },
                { value: "Lead", label: "Lead" },
                { value: "Participant", label: "Participant" },
                { value: "Booker", label: "Booker" },
                { value: "Agent", label: "Agent" },
                { value: "Organisation", label: "Organisation" }
            ];
          case 13:
            return [
                { value: "9", label: "Email" },
                { value: "in-app", label: "In-App" },
            ];
        default:
            return []
    }
}

export const selectContactFilterOptions = [
    { value: "ID", label: "ID", field: "contact_code" },
    { value: "Full Name", label: "Full Name", field: "fullname" },
    { value: "Type", label: "Type", field: "type" },
    { value: "Status", label: "Status", field: "status" },
    { value: "Created Date", label: "Created Date", field: "created" },
    { value: "Created By", label: "Created By", field: "created_by" }
]

export const selectFilterStatusoptions = function(filter_related_type) {
  switch(filter_related_type) {
    //9-shifts ,11- Applicants
    case '9':
      return [
        { value: "1", label: "Open" },
        { value: "2", label: "Published" },
        { value: "3", label: "Scheduled" },
        { value: "4", label: "In Progress" },
        { value: "5", label: "Completed" },
        { value: "6", label: "Cancelled" }
      ];
    case '11':
      return [
        { value: "0", label: "New" },
        { value: "1", label: "Screening" },
        { value: "2", label: "Interviews" },
        { value: "3", label: "References" },
        { value: "4", label: "Documents" },
        { value: "5", label: "CAB" },
        { value: "6", label: "Offer" },
        { value: "7", label: "Hired" },
        { value: "8", label: "Unsuccessful" }
      ];
    case 'member_status':
      return [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ];
    case '13':
      return [
        { value: "1", label: "Read" },
        { value: "0", label: "Unread" },
      ];
    default:
      return [
        { value: "Active", label: "Active" },
        { value: "In Active", label: "In Active" }
      ]
  }
}
// contact page ends here
// organisation page starts here

export const selectOrganisationFilterOptions = [
    { value: "Organisation ID", label: "ID", field: "contact_code" },
    { value: "Organisation Name", label: "Full Name", field: "fullname" },
    { value: "Status", label: "Status", field: "status" },
    { value: "Created Date", label: "Created Date", field: "created" },
]
// organisation page ends here