import React, { Component } from 'react';
import jQuery from 'jquery'
import Select from 'react-select-plus';
import 'react-select-plus/dist/react-select-plus.css';
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import { postData, reFreashReactTable, AjaxConfirm, toastMessageShow, css, getApplicantId, getTaskApplicationId, gettaskId, getInterviewTypeId } from 'service/common.js';
import { connect } from 'react-redux'
import { defaultSpaceInTable } from 'service/custom_value_data.js';
import { ROUTER_PATH } from 'config.js';
import PropTypes from 'prop-types';
import moment from "moment";
import './scss/components/admin/crm/pages/contact/ListContact.scss'

import {
    IconSettings,
    PageHeaderControl,
    ButtonGroup,
    Button,
    Icon,
    PageHeader,
    Tabs,
    TabsPanel,
    Card,
    MediaObject,
    Dropdown
} from '@salesforce/design-system-react';
import SLDSReactTable from './salesforce/lightning/SLDSReactTable'
import '../admin/scss/details.scss'
import QuizModal from './QuizModal.jsx'

const requestData = (pageSize, page, sorted, filtered) => {

    return new Promise((resolve, reject) => {
        // request json
        var Request = { pageSize: pageSize, page: page, sorted: sorted, filtered: filtered };
        let filteredData = [{
            contact_code: "Quiz 1",
            created: "12/08/2020",
            created_by: null,
            fullname: "12/08/2020 ,15:00",
            id: "281",
            status: "Scheduled",
            type: "12/08/2020 ,15:00",
            first_name: '',
            last_name: '',
            showStartBtn: false
        }];
        const res = {
            rows: filteredData,
            pages: 1
        };
        resolve(res);

    });
};

class ListQuizzes extends Component {

    static defaultProps = {
        displayed_columns: {
            "contact_code": true,
            "fullname": true,
            "type": true,
            "status": true,
            "created": true,
            "created_by": true,
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            open_quiz: false,
            loading: false,
            searchVal: '',
            filterVal: '',
            contactList: [],
            filter_status: 'all',
            quiz_list: [],
            quiz_list_count: 0,
            application_id: '',
            applicant_id: '',
            task_id: '',
            default_displayed_columns: Object.keys(props.displayed_columns).filter(k => !!props.displayed_columns[k]),
            displayed_columns: Object.keys(props.displayed_columns).filter(k => !!props.displayed_columns[k]),
            int_status: "",
            button_title: "Start",
        }

        this.reactTable = React.createRef();
        this.rootRef = React.createRef()
    }

    componentDidMount() {
        jQuery(this.rootRef.current).parent(`.col-lg-11`).removeClass(`col-lg-11`).addClass(`col-lg-12`)
        this.get_applicant_details(getApplicantId())
        this.get_quiz_details()
    }

    componentWillUnmount() {
        jQuery(this.rootRef.current).parent(`.col-lg-12`).removeClass(`col-lg-12`).addClass(`col-lg-11`)
    }

    /**
     * Get  Applicant Details of Current Login User
    */
    get_applicant_details = (id) => {
        postData('recruitment/Recruitment_member/get_applicant_details', { applicant_id: id }).then((result) => {
            if (result.status) {
                let name = result.data.applicant_name;
                this.setState(result.data, () => {
                })

            } else {
                toastMessageShow('something went wrong', "e");
            }
        });
    }

    /**
     * Get Quiz Details of Current Login User
    */
    get_quiz_details = () => {
        postData('recruitment/Recruitment_member/get_quiz_list', { applicant_id: getApplicantId(), interview_type_id: getInterviewTypeId(), task_id: gettaskId(), task_applicant_id: getTaskApplicationId() }).then((result) => {
            if (result.status) {
                console.log("result", result)
                this.setState({ quiz_list: result.data});
                this.setState({ quiz_list_count: this.state.quiz_list.length})
            } else {
                toastMessageShow('something went wrong', "e");
            }
        });
    }

    closeModal = (status) => {
        this.setState({ open_quiz: false, button_title: 'Start' });
        this.get_quiz_details();
    }

    openQuizModal(task_id,value) {
        if(value.int_status == 'Open'){	
            this.update_applicant_quiz_open_status(value.id)	
        }
        this.setState(
            {
                application_id: value.application_id, 
                applicant_id: value.applicant_id, 
                task_id: value.id, 
                open_quiz: true, 
                button_title: 'In-Progress'
            }
        );
    }

    /**	
     * update quiz status	
    */	
   update_applicant_quiz_open_status = (id) => {	
    postData('recruitment/RecruitmentQuiz/update_applicant_quiz_status', { task_id: id, applicant_id: getApplicantId() }).then((result) => {	
        if (result.status) {	
            this.get_quiz_details();	
        } else {	
            toastMessageShow('something went wrong', "e");	
        }	
        	
    });	
}

    renderQuizCard = () => {
        if(this.state.loading == true) {
            return (
            <React.Fragment></React.Fragment>
            )
        }

        return (
            <React.Fragment>
                 <div class="slds_my_card">
                <Card
                headerActions={""}
                heading={"Quiz ("+ this.state.quiz_list_count +")"}
                className="slds-card-bor"
                icon={<Icon category="standard" name="question_feed" size="small" />}
                >
                
                <div className="slds-card__body slds-card__body_inner">
                    <ul className="slds-grid slds-wrap slds-grid_pull-padded">
                        {(this.state.quiz_list.length > 0) ?
                        this.state.quiz_list.map((value, idx) => (
                            <li className="slds-p-horizontal_small slds-size_1-of-1 slds-medium-size_1-of-3" key={idx}>
                            <article className="slds-tile slds-media slds-card__tile slds-hint-parent">
                                <div className="slds-media__figure">
                                {<Icon category="standard" name="question_feed" size="small" />}
                                </div>
                                <div className="slds-media__body">
                                    <div className="slds-grid slds-grid_align-spread slds-has-flexi-truncate">
                                        <h2 className="slds-tile__title slds-truncate" title={value.task_name}>
                                        {(value.int_status == 'Open' || value.int_status == 'In-Progress') ? 
                                                    <a href="javascript:void(0);" onClick={() => this.openQuizModal(getApplicantId(), value)}>{value.task_name}</a> :
                                                    <span>{value.task_name}</span>
                                        }
                                        </h2>
                                    </div>
                                    <div className="slds-tile__detail">
                                        <dl className="slds-list_horizontal slds-wrap">
                                            <dt className="slds-item_label slds-text-color_weak slds-truncate" title="Start Date & Time">Start Date & Time:</dt>
                                            <dd className="slds-item_detail slds-truncate" title="Start Date & Time">{moment(value.start_datetime).format("DD/MM/YYYY HH:mm:ss")}</dd>
                                            <dt className="slds-item_label slds-text-color_weak slds-truncate" title="End Date  & Time">End Date & Time:</dt>
                                            <dd className="slds-item_detail slds-truncate" title="End Date  & Time">{moment(value.end_datetime).format("DD/MM/YYYY HH:mm:ss")}</dd>
                                            <dt className="slds-item_label slds-text-color_weak slds-truncate" title="Status">Status:</dt>
                                            <dd className="slds-item_detail slds-truncate" title="Status">{value.int_status}</dd>                                           
                                        </dl>
                                    </div>
                                </div>
                            </article>
                            </li>
                        )) : ''}
                    </ul >
                </div>
                </Card>
                </div>
                </React.Fragment>
        )
    }

    render() {
        const styles = css({
            root: {
                fontFamily: "Salesforce Sans, Arial, Helvetica, sans-serif",
                marginRight: -15,
            }
        })

        return (
            <React.Fragment>
                <div className="container-fluid fixed_size">
                    <div className="ListContact slds" style={styles.root} ref={this.rootRef}>
                        <div className="slds-col col-lg-12 custom_page_header">
                            <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">

                                <PageHeader
                                    icon={
                                        <Icon
                                            assistiveText={{ label: 'User' }}
                                            category="standard"
                                            name="lead"
                                        />
                                    }
                                    onRenderActions={this.actions}
                                    title={this.state.applicant_name}
                                    variant="record-home"
                                    className="mydetails"
                                />

                                <div className="slds-col detailspage">
                                    <div className="slds-grid slds-wrap">
                                        <div className="slds-col slds-m-top_medium">
                                            <div className="white_bg_color slds-box ">
                                                <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                                                    <Tabs id="tabs-example-default">
                                                        <TabsPanel label="Related">
                                                            {this.renderQuizCard()}
                                                        </TabsPanel >
                                                    </Tabs >
                                                </IconSettings >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {(this.state.open_quiz) ?
                                    <QuizModal
                                        application_id={this.state.application_id}
                                        applicant_id={getApplicantId()}
                                        task_id={this.state.task_id}
                                        isOpen={this.state.open_quiz}
                                        onClose={() => this.closeModal(true)}
                                    />
                                    : ''
                                }
                            </IconSettings>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

ReactTable.PropTypes = {
    defaultFiltered: PropTypes.object
}
const mapStateToProps = state => ({
})

const mapDispatchtoProps = (dispach) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchtoProps)(ListQuizzes);