import React, { Component } from 'react';
import jQuery from "jquery";
import 'react-select-plus/dist/react-select-plus.css';
import 'react-block-ui/style.css';
import 'service/jquery.validate.js';
import "service/custom_script.js";
import { postData, toastMessageShow, queryOptionData, getApplicantId, getMemberId } from 'service/common.js';
import '../../scss/components/admin/salesforce/lightning/salesforce-lightning-design-system.scss';
import SLDSReactSelect from 'components/admin/salesforce/lightning/SLDSReactSelect.jsx';
import {
    Modal,
    Checkbox,
    Button,
    IconSettings,
    Timepicker,
} from '@salesforce/design-system-react';
import moment from 'moment';
import { SLDSISODatePicker } from '../../salesforce/lightning/SLDSISODatePicker';
import { Input } from '@salesforce/design-system-react';


/**
 * Class: CreateMemberUnavailabilityModel
 */
class CreateMemberUnavailabilityModel extends Component {

    /**
     * setting the initial prop values
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            applicant_id: getApplicantId(),
            member_id: getMemberId(),
            id: this.props.id ? this.props.id : '',
            unavailability_options: [],
            unavailability_id: '',
            start_date: '',
            start_time: '',
            end_date: '',
            end_time: '',
            time_options: []
        }
        // we'll use these refs to fix toggling slds datepicker issues
        this.datepickers = {            
            start_date: React.createRef(),
            end_date: React.createRef(),
        }
    }

    /**
     * fetching the member unavailability details if the modal is opened in the edit mode
     */
    get_member_unavailability_details = (id) => {
        var Request = { id: id, applicant_id: this.state.applicant_id, member_id: this.state.member_id};
        postData('recruitment/Recruitment_member/get_member_unavailability_details', Request).then((result) => {
            if (result.status) {
                this.setState(result.data);
            } else {
                toastMessageShow(result.error, "e");
            }
        });
    }

    /**
     * fetching the reference data (unavailability type) of member's object
     */
    getReferenceData = () => {
        var Request = { applicant_id: this.state.applicant_id, member_id: this.state.member_id};
		postData("recruitment/Recruitment_member/get_unavailability_type_data", Request).then((res) => {
			if (res.status) {
				this.setState({ 
                    type_options: (res.data.unavailability_types)?res.data.unavailability_types:[]
				})
			}
		});
    }

    /**
     * fetching the timing listing
     */
    getTimeSlots = () => {
        var Request = { applicant_id: this.state.applicant_id, member_id: this.state.member_id};
		postData("recruitment/Recruitment_member/get_time_slots_half_hour", Request).then((res) => {
			if (res.status) {
				this.setState({ 
					time_options: (res.data)?res.data:[]
				})
			}
		});
    }
    
    /**
     * handling the status change event
     */
    handleChange = (value, key) => {
        this.setState({ [key]: value });
    }

    /**
     * mounting all the components
     */
    componentDidMount() {
        this.setState({ loading: true });
        this.getReferenceData();
        this.getTimeSlots();
        if (this.props.member_id) {
            this.setState({ member_id: this.props.member_id });
        }

        if(this.props.id) {
            this.setState({ id: this.props.id });
            this.get_member_unavailability_details(this.props.id);
        }
        
        this.setState({ loading: false });
    }

    /**
     * handling the change event of the data picker fields
     */
    handleChangeDatePicker = key => (dateYmdHis, e, data) => {
        let newState = {}
        newState[key] = dateYmdHis
        this.setState(newState)
    }

    /**
     * tinker with internal Datepicker state to
     * fix calendar toggling issue with multiple datepickers
     */
    handleDatePickerOpened = k => () => {
        Object.keys(this.datepickers).forEach(refKey => {
            const { current } = this.datepickers[refKey] || {}
            if (refKey !== k && current && 'instanceRef' in current) {
                current.instanceRef.setState({ isOpen: false })
            }
        })
    }

    /**
     * Calling the API to create/update the member unavailability
     * Method - POST
     * @param {Obj} e
     */
    submit = (e) => {
        e.preventDefault();
        jQuery("#create_member_unavailability").validate({ /* */ });        
        var url = 'recruitment/Recruitment_member/create_update_member_unavailability';
        var validator = jQuery("#create_member_unavailability").validate({ ignore: [] });
        
        // Allow only if validation is passed
        if (!this.state.loading && jQuery("#create_member_unavailability").valid()) {
            this.setState({ loading: true });
            var req = {
                ...this.state,
                start_date: this.state.start_date ? moment(this.state.start_date).format('YYYY-MM-DD') : '',
                end_date: this.state.end_date ? moment(this.state.end_date).format('YYYY-MM-DD') : ''
            };
            // Call Api
            postData(url, req).then((result) => {
                if (result.status) {
                    let msg = result.hasOwnProperty('msg') ? result.msg : '';
                    // Trigger success pop 
                    toastMessageShow(result.msg, 's');
                    this.props.closeModal(true);
                } else {
                    // Trigger error pop 
                    toastMessageShow(result.error, "e");
                }
                this.setState({ loading: false });
            });           
        } else {
            // Validation is failed
            validator.focusInvalid();
        }
    }
    
    /**
     * Render the display content
     */
    render() {
        return (
            <React.Fragment>
                <IconSettings iconPath={"/assets/salesforce-lightning-design-system/assets/icons"}>
                    <Modal
                        isOpen={this.props.showModal}
                        footer={[
                            <Button disabled={this.state.loading} key={0} label="Cancel" onClick={() => this.props.closeModal(false)} />,
                            <Button disabled={this.state.loading} key={1} label="Save" variant="brand" onClick={this.submit} />,
                        ]}
                        heading={this.props.id ? "Update Unavailability" : "New Unavailability"}
                        size="small"
                        className="slds-modal slds_custom_modal slds_custom_modal_cont_visible"
                        onRequestClose={() => this.props.closeModal(false)}
                        ariaHideApp={false}
                        dismissOnClickOutside={false}
                    >
                        <section className="manage_top" >
                            <div className="container-fluid">
                                <form id="create_member_unavailability" autoComplete="off" className="slds_form">
                                    
                                    <div className="row pt-4 py-2">
                                        <div className="col-sm-3">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" htmlFor="text-input-id-1">
                                                    <abbr className="slds-required" title="required">* </abbr>Start Date</label>
                                                <div className="slds-form-element__control">
                                                    <div className="SLDS_date_picker_width">
                                                         <SLDSISODatePicker
                                                                ref={this.datepickers.start_date} // !important: this is needed by this custom SLDSISODatePicker
                                                                className="customer_signed_date"
                                                                placeholder="Start Date"
                                                                onChange={this.handleChangeDatePicker('start_date')}
                                                                onOpen={this.handleDatePickerOpened('start_date')}
                                                                onClear={this.handleChangeDatePicker('start_date')}
                                                                value={this.state.start_date}
                                                                input={<Input name="start_date"/>}
                                                                inputProps={{
                                                                    name: "start_date",
                                                                    required: true
                                                                }}
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        
                                        <div className="col-sm-3">
                                            <div className="slds-form-element">
                                                <div className="slds-form-element__control">
                                                    <Timepicker
                                                        label={<span>&nbsp;Time</span>}
                                                        stepInMinutes={30}
                                                        required={true}
                                                        name="start_time"
                                                        id="start_time"
                                                        menuPosition="relative"
                                                        formatter={(date) => {
                                                            if (date) {
                                                                return moment(date).format('hh:mm A');
                                                            }
                                                            return null;
                                                        }}
                                                        strValue={this.state.start_time}
                                                        onDateChange={(date, inputStr) => {
                                                            this.handleChange(inputStr, "start_time")
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="slds-form-element">
                                            <label className="slds-form-element__label" htmlFor="text-input-id-1"><abbr className="slds-required" title="required">* </abbr>End Date</label>
                                              
                                                <div className="slds-form-element__control">
                                                    <div className="SLDS_date_picker_width">
                                                        <SLDSISODatePicker
                                                                ref={this.datepickers.end_date} // !important: this is needed by this custom SLDSISODatePicker
                                                                className="customer_signed_date"
                                                                placeholder="End Date"
                                                                onChange={this.handleChangeDatePicker('end_date')}
                                                                onOpen={this.handleDatePickerOpened('end_date')}
                                                                onClear={this.handleChangeDatePicker('end_date')}
                                                                value={this.state.end_date}
                                                                input={<Input name="end_date"/>}
                                                                inputProps={{
                                                                    name: "end_date",
                                                                    required: true
                                                                }}
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        
                                        <div className="col-sm-3">
                                            <div className="slds-form-element">
                                                <div className="slds-form-element__control">
                                                    <Timepicker
                                                        label={<span>&nbsp;Time</span>}
                                                        stepInMinutes={30}
                                                        required={false}
                                                        name="end_time"
                                                        id="end_time"
                                                        menuPosition="relative"
                                                        formatter={(date) => {
                                                            if (date) {
                                                                return moment(date).format('hh:mm A');
                                                            }
                                                            return null;
                                                        }}
                                                        strValue={this.state.end_time}
                                                        onDateChange={(date, inputStr) => {
                                                            this.handleChange(inputStr, "end_time")
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row py-2 mb-5">
                                        <div className="col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" htmlFor="text-input-id-1">Type</label>
                                                <div className="slds-form-element__control">
                                                    <SLDSReactSelect
                                                        simpleValue={true}
                                                        className="custom_select default_validation"
                                                        options={this.state.type_options}
                                                        onChange={(e) => this.handleChange(e, 'type_id')}
                                                        value={this.state.type_id || ''}
                                                        clearable={true}
                                                        searchable={true}
                                                        placeholder="Please Select"
                                                        name="Type"
                                                        required={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row py-2 mb-5">&nbsp;</div>
                                </form>
                            </div>
                        </section>
                    </Modal>
                </IconSettings>
            </React.Fragment>
        );
    }
}

export default CreateMemberUnavailabilityModel;
