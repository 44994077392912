import React, { Component } from 'react';
import 'react-select-plus/dist/react-select-plus.css';
import { Link } from 'react-router-dom';
import 'react-table/react-table.css'
import { getApplicantId, getMemberId, checkItsNotLoggedIn } from 'service/common.js';
import { connect } from 'react-redux'
import { ROUTER_PATH } from 'config.js';
import moment from "moment";
import '../../scss/components/admin/member/member.scss'
import { showArchiveMemberUnavailabilityModal, openAddEditMemberUnavailabilityModal } from '../MemberCommon';
import {
    Button,
    Icon,
    Card,
    Dropdown
} from '@salesforce/design-system-react';

/**
 * Class: MemberUnavailability
 */
class MemberUnavailability extends Component {
    
    /**
     * setting the initial prop values
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        checkItsNotLoggedIn();
        
        // Initialize state
        this.state = {
            applicant_id: getApplicantId(),
            member_id: getMemberId(),
            member_unavailability_id: '',
            member_unavailability: this.props.member_unavailability,
            member_unavailability_count: this.props.member_unavailability_count,
            openCreateModal: false,
        }
        // Initilize react table
        this.reactTable = React.createRef();
        this.rootRef = React.createRef()
    }

    /*
     * method runs after the component output has been rendered to the DOM
     */
    componentDidMount() {

    }

    /**
     * Open create member unavailability modal
     */
    showModal(member_unavailability_id) {
        this.setState({ openCreateModal: true, member_unavailability_id: member_unavailability_id });
    }

    /**
     * Open archive member unavailability modal
     */
    showArchiveModal(member_unavailability_id) {
        showArchiveMemberUnavailabilityModal(member_unavailability_id, this.props.get_member_unavailability);
    }

    /**
     * Close the modal when user save the member unavailability and refresh the unavailability table
     */
    closeAddEditMemberUnavailabilityModal = (status) => {
        this.setState({openCreateModal: false});

        if(status){
            this.props.get_member_unavailability();
        }
    }

    /**
     * Render the member unavailability table
     */
    renderTable() {

        if (this.props.member_unavailability.length == 0) {
            return <React.Fragment />
        }

        return (
            <React.Fragment>
            <div className="slds-card__body slds-card__body_inner">
                <ul className="slds-grid slds-wrap slds-grid_pull-padded">
                    {this.props.member_unavailability.map((value, idx) => (
                    <li className="slds-p-horizontal_small slds-size_1-of-1 slds-medium-size_1-of-3" key={idx}>
                    <article className="slds-tile slds-media slds-card__tile slds-hint-parent">
                        <div className="slds-media__figure">
                        {<Icon category="standard" name="date_input" size="small" />}
                        </div>
                        <div className="slds-media__body">
                            <div className="slds-grid slds-grid_align-spread slds-has-flexi-truncate">
                                <h2 className="slds-tile__title slds-truncate" title={moment(value.start_date).format("DD/MM/YYYY hh:mm A")}>
                                <a href="javascript:void(0);">{moment(value.start_date).format("DD/MM/YYYY hh:mm A")}</a>
                                </h2>
                                
                                <div className="slds-shrink-none">
                                    <Dropdown
                                        align="right"
                                        assistiveText={{ icon: 'More Options' }}
                                        buttonVariant="icon"
                                        iconCategory="utility"
                                        iconName="down"
                                        iconVariant="border-filled"
                                        iconSize="x-small"
                                        width="xx-small"
                                        openOn="click"
                                        onSelect={(e) => {
                                            if(e.value == 1){ //edit
                                                this.showModal(value.id)
                                            }
                                            else { // delete
                                                this.showArchiveModal(value.id)
                                            }
                                        }}
                                        className={'slds-more-action-dropdown'}
                                        options={[
                                            { label: 'Edit', value: '1' },
                                            { label: 'Delete', value: '2' },
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className="slds-tile__detail">
                                <dl className="slds-list_horizontal slds-wrap">
                                    <dt className="slds-item_label slds-text-color_weak slds-truncate" title="Scheduled Start Time">End Time:</dt>
                                    <dd className="slds-item_detail slds-truncate" title={moment(value.end_date).format("DD/MM/YYYY hh:mm A")}>{moment(value.end_date).format("DD/MM/YYYY hh:mm A")}</dd>

                                    <dt className="slds-item_label slds-text-color_weak slds-truncate" title="Status">Type:</dt>
                                    <dd className="slds-item_detail slds-truncate" title={value.unavailability_type}>{value.unavailability_type}</dd>
                                </dl>
                            </div>
                        </div>
                    </article>
                    </li>
                    ))}
                </ul>
            </div>
            {(this.props.type == "withcard") ? 
            <div className="slds-media__footer" style={{'border-top': '1px solid #dddbda'}}>
                <Link to={`unavailability`} className="pt-3 slds-align_absolute-center reset" title={`View all unavailability`} style={{ color: '#0070d2' }}>View All</Link>
            </div> : ''}
            </React.Fragment>
        );
    }
    
    /**
     * Render the display content
     */
    render() {

        if(this.props.type == "withoutcard") {
            // return
            return (
                <React.Fragment>
                {this.renderTable()}
                {openAddEditMemberUnavailabilityModal(this.state.member_unavailability_id, this.state.member_id, this.state.openCreateModal, this.closeAddEditMemberUnavailabilityModal)}
                </React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                    <Card
                    headerActions={<Button label="New" onClick={e => this.showModal()} />}
                    heading={"Unavailability ("+ this.props.member_unavailability_count +")"}
                    className="slds-card-bor"
                    icon={<Icon category="standard" name="date_time" size="small" />}
                    >
                    {this.renderTable()}
                    </Card>
                    {openAddEditMemberUnavailabilityModal(this.state.member_unavailability_id, this.state.member_id, this.state.openCreateModal, this.closeAddEditMemberUnavailabilityModal)}
                </React.Fragment>
            )
        }
    }
}

// Get the page title and type from reducer
const mapStateToProps = state => ({})
const mapDispatchtoProps = (dispach) => { return { } }

export default connect(mapStateToProps, mapDispatchtoProps)(MemberUnavailability);
