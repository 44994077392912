import React, { Component } from 'react';
import 'react-select-plus/dist/react-select-plus.css';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import { postData, css, AjaxConfirm, toastMessageShow, reFreashReactTable, getApplicantId, getMemberId, setMemberId } from 'service/common.js';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import '../../admin/scss/details.scss'
import {
    IconSettings,
    PageHeaderControl,
    ButtonGroup,
    Button,
    Icon,
    PageHeader,
    Tabs,
    TabsPanel,
    Card,
    Dropdown
} from '@salesforce/design-system-react';
import EditMemberModal from './EditMemberModal';
import '../../admin/scss/details.scss'
import DocumentCard from './document/DocumentCard';
import MemberShifts from './MemberShifts';
import MemberUnavailability from './unavailability/MemberUnavailability';
import { SplitContainer } from '../salesforce/lightning/DetailsComponent';
import { DetailsTitle } from '../salesforce/lightning/DetailsTitleCard';

/**
 * Renders the view member component
 */
class MemberDetail extends Component {

    /**
     * default constructor
     */
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            activeTab: 'related',
            account_person: {},
            applicant_id: getApplicantId(),
            member_id: getMemberId(),
            owner: {},
            contacts: [],
            opportunityStage: [],
            opportunity_status: '',            
            address_details: '',
            member_unavailability: [],
            member_unavailability_count: 0,
            available_shifts: [],
            available_shifts_count: 0,
            accepted_shifts: [],
            accepted_shifts_count: 0,
            completed_shifts: [],
            completed_shifts_count: 0,
            cancelled_shifts: [],
            cancelled_shifts_count: 0,
            pageSize: 6,
            page: 0,
            sorted: [],
            filtered: []
        }
    }

    get_applicant_member_details_by_id = () => {
        let req = {};
        if(this.state.applicant_id && this.state.applicant_id!='0'){
            req = {applicant_id: this.state.applicant_id}
        }else{
            req = {member_id: this.state.member_id}
        }        
        postData('recruitment/Recruitment_member/get_applicant_member_details_by_id', req).then((result) => {
            if (result.status) {
                var id = result.data.id;
                var member_id = this.state.member_id;
                this.setState(result.data, () => {      
                    var stateTemp = {};
                    stateTemp.page = 0;
                    stateTemp.pageSize = 6;
                    var mem_id = result.data.member_id || '';
                    setMemberId(mem_id);
                    if (member_id == '' || member_id == 'null') {
                        this.get_available_shifts_member();
                        this.get_shifts_member('accepted');
                        this.get_shifts_member('completed');
                        this.get_shifts_member('cancelled');
                        this.get_member_unavailability();
                    }
                });
            }
            return result;
        });
    }

    /**
     * fetching the member shifts that are available
     */
    get_available_shifts_member = () => {
        var Request = { type: "available", applicant_id: this.state.applicant_id, member_id: this.state.member_id, pageSize: this.state.pageSize, page: this.state.page, sorted: this.state.sorted, filtered: this.state.filtered };
        postData('recruitment/Recruitment_member/get_shifts_member', Request).then((result) => {
            if (result.status) {
                this.setState({available_shifts: result.data, available_shifts_count: result.count});
            } else {
                toastMessageShow(result.error, "e");
            }
        });
    }

    /**
     * fetching the member shifts that are accepted by him
     */
    get_shifts_member = (type) => {
        var Request = { type: type, applicant_id: this.state.applicant_id, member_id: this.state.member_id, pageSize: this.state.pageSize, page: this.state.page, sorted: this.state.sorted, filtered: this.state.filtered };
        postData('recruitment/Recruitment_member/get_shifts_member', Request).then((result) => {
            if (result.status) {
                this.setState({[type+'_shifts']: result.data, [type+'_shifts_count']: result.count});
            } else {
                toastMessageShow(result.error, "e");
            }
        });
    }

    /**
     * fetching the member unavailability
     */
    get_member_unavailability = (id) => {
        var Request = { applicant_id: this.state.applicant_id, member_id: this.state.member_id, pageSize: this.state.pageSize, page: this.state.page, sorted: this.state.sorted, filtered: this.state.filtered };
        postData('recruitment/Recruitment_member/get_member_unavailability_list', Request).then((result) => {
            if (result.status) {
                this.setState({member_unavailability: result.data, member_unavailability_count: result.count});
            } else {
                toastMessageShow(result.error, "e");
            }
        });
    }

    /**
     * mounting all the components
     */
    componentDidMount() {
        this.setState({loading: true});
        this.get_applicant_member_details_by_id();
        if (this.state.member_id != '' && this.state.member_id != 'null') {
            this.get_available_shifts_member();
            this.get_shifts_member('accepted');
            this.get_shifts_member('completed');
            this.get_shifts_member('cancelled');
            this.get_member_unavailability();
        }
        this.setState({loading: false});    
    }

    /**
     * when update member information modal is asked to be closed
     */
    closeModal = (status) => {
        this.setState({ openCreateModal: false });

        if (status) {
            this.get_applicant_member_details_by_id()
        }
    }

    /**
     * Renders the details tab
     */
    renderDetailsTabContent() {
        const styles = css({
            root: {
                border: 'none',
                paddingTop: 0,
            },
            heading: {
                marginBottom: 15,
                marginTop: 8,
            },
            headingText: {
                fontSize: 14,
                fontWeight: 'normal',
                color: '#4d5145',
            },
            col: {
                marginBottom: 15,
            },
            hr: { marginTop: 20, marginBottom: 20, border: 0, borderTop: '1px solid #eee', width: 'auto' },
            ul: { listStyleType: 'disc', paddingLeft: 40 },
        })

        const notAvailable = 'N/A' // this.props.notAvailable

        const details = [
            {
                label: 'Transport',
                value: this.state.transport_selection && this.state.transport_selection.length>0 ? this.state.transport_selection.map((ele, i) => [
                    i > 0 && ", ",
                    ele.label
                ]) : 'N/A',
                editIcn: false,
            },
            {
                label: 'Likes',
                value: this.state.like_selection && this.state.like_selection.length>0 ? this.state.like_selection.map((ele, i) => [
                    i > 0 && ", ",
                    ele.label
                ]) : 'N/A',
                editIcn: false,
            },
            {
                label: 'Languages',
                value: this.state.language_selection && this.state.language_selection.length>0 ? this.state.language_selection.map((ele, i) => [
                    i > 0 && ", ",
                    ele.label
                ]) : 'N/A',
                editIcn: false,
            },
            {
                label: 'Hours per week',
                value: this.state.hours_per_week || 'N/A',
                editIcn: false,
            },
            {
                label: 'Experience (In years)',
                value: this.state.mem_experience,
                editIcn: false,
            },
            {
                label: 'Max distance to travel (In kms)',
                value: this.state.max_dis_to_travel,
                editIcn: false,
            },
        ]

        return (
            <DetailsTitle 
                details={details}
            />
        )
    }

    /**
     * Rendering the details tab
     */
    renderDetailsTab() {
        if(this.state.loading == true) {
            return (
            <React.Fragment></React.Fragment>
            )
        }

        return (
            <Tabs id="slds-details-tab">
                <TabsPanel label="Details">
                    <div className="container-fluid m-0 pl-1 pr-1">
                        <div class="slds-scrollable_y" style={{"height":'100vh','display': 'initial'}}>
                            {this.renderDetailsTabContent()}
                        </div>
                    </div>
                </TabsPanel>
            </Tabs>
        )
    }

    /**
     * Rendering the related tab
     */
    renderRelatedTab() {
        if(this.state.loading == true) {
            return <React.Fragment />;
        }

        return (
            <Tabs id="slds-related-tab">
                <TabsPanel label="Related">
                    <div className="container-fluid m-0 pl-1 pr-1">
                        <div class="slds-scrollable_y slds_my_card" style={{"height":'100vh','display': 'initial'}}>
                            {this.renderAvailableShiftCard()}
                            {this.renderAcceptedShiftCard()}
                            {this.renderShiftCard('completed')}
                            {this.renderShiftCard('cancelled')}
                            {this.renderUnavailabilityCard()}
                            {this.renderSkillsCard()}
                        </div>
                    </div>
                </TabsPanel>
            </Tabs>
        )
    }

    /**
     * Renders the document card
     */
    renderDocumentCard() {
        var id = this.state.member_id;
        return (
            <DocumentCard
                ref="memberDocument"
                member_id={id}
                isMobile={this.props.isMobile}
            />
        )
    }
    
    /**
     * Renders the unavailability card
     */
    renderUnavailabilityCard() {
        return (
            <React.Fragment>
            <MemberUnavailability member_unavailability={this.state.member_unavailability} member_unavailability_count={this.state.member_unavailability_count} get_member_unavailability={this.get_member_unavailability} type="withcard"></MemberUnavailability>
            </React.Fragment>
        )
    }

    /**
     * Renders the skills card
     */
    renderSkillsCard() {
        return (
            <Card
                heading={"Skills (0)"}
                className="slds-card-bor"
                icon={<Icon category="standard" name="skill_entity" size="small" />}
                ></Card>
        )
    }

    /**
     * Rendering accepted shifts card
     */
    renderAcceptedShiftCard() {
        return (
            <React.Fragment>
                <Card
                heading={"Accepted Shifts ("+ this.state.accepted_shifts_count +")"}
                className="slds-card-bor"
                icon={<Icon category="standard" name="date_input" size="small" />}
                >
                
                <MemberShifts shifts={this.state.accepted_shifts} type="accepted" refAvailableShifts={this.get_available_shifts_member} refAcceptedShifts={this.get_accepted_shifts_member}></MemberShifts>
                </Card>
            </React.Fragment>
        )
    }

    /**
     * Rendering available shifts card
     */
    renderAvailableShiftCard() {
        return (
            <React.Fragment>
                <Card
                heading={"Available Shifts ("+ this.state.available_shifts_count +")"}
                className="slds-card-bor"
                icon={<Icon category="standard" name="date_input" size="small" />}
                >
                
                <MemberShifts shifts={this.state.available_shifts} type="available" refAvailableShifts={this.get_available_shifts_member} refAcceptedShifts={this.get_accepted_shifts_member}></MemberShifts>
                </Card>
            </React.Fragment>
        )
    }

    /**
     * Rendering cancelled shifts card
     *  - Accepted
     *  - Completed
     *  - Cancelled
     */
     renderShiftCard(type) {
        let title = '';
        switch (type) {
            case 'cancelled':
                title = 'Cancelled';
                break;
            case 'completed':
                title = 'Completed';
                break;
            case 'accepted':
                title = 'Accepted';
                break;
            default:
                title = '';
                break;
        }
        return (
            <React.Fragment>
                <Card
                heading={title+" Shifts ("+ this.state[type+'_shifts_count'] +")"}
                className="slds-card-bor"
                icon={<Icon category="standard" name="date_input" size="small" />}
                >
                
                    <MemberShifts shifts={this.state[type+'_shifts']} type={type} refAvailableShifts={this.get_shifts_member} refAcceptedShifts={this.get_shifts_member}></MemberShifts>
                </Card>
            </React.Fragment>
        )
    }

    /*
     * rendering the action header
     */
    actions = () => (
        <React.Fragment>
            <PageHeaderControl>
                <ButtonGroup variant="list" id="button-group-page-header-actions">
                   {/*  <Button label="Edit" onClick={e => { e.preventDefault(); this.setState({ openCreateModal: true, selectedApplicantId: this.state.applicant_id }) }} /> */}
                    {/* <Button label="Edit" /> */}
                </ButtonGroup>
            </PageHeaderControl>
        </React.Fragment>
    );

    /**
     * Render Page Header
     */
    renderPageHeader = () => {
        return (            
            <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                <PageHeader
                    icon={
                        <Icon
                            assistiveText={{ label: 'User' }}
                            category="standard"
                            name="channel_program_members"
                        />
                    }
                    onRenderActions={this.actions}
                    title={this.state.fullname}
                    variant="record-home"
                    className={"mydetails"+ this.props.isMobile === true ? 'slds-header-document-detail-mob-view' : ''}
                />
            </IconSettings>                
        );
    }

    /**
     * Render Modal
     * - Edit Details
     */
    renderModal = () => {
        return (
            <>
                {
                    this.state.openCreateModal && (
                        <EditMemberModal
                            applicantId={this.state.applicant_id}
                            showModal={this.state.openCreateModal}
                            closeModal={this.closeModal}
                            memberId={this.state.id}
                        />
                    )
                }
            </>
        );
    }

    /**
     * rendering components
     */
    render() {

        const styles = css({
            root: {
                fontFamily: "Salesforce Sans, Arial, Helvetica, sans-serif",
                marginRight: -15,
            }
        })

        return (
            <React.Fragment>
                <div className="container-fluid fixed_size">
                <div className="slds slds-grid slds-grid_horizontal" style={styles.root}>
                    <div className="slds-col col-lg-12 custom_page_header max-width-res">
                        {this.renderPageHeader()}
                        {this.renderRelatedTab()}
                      {/*   <SplitContainer
                            leftColContent={this.renderRelatedTab()}
                            rightColContent={this.renderDetailsTab()}
                        /> */}
                        {this.renderModal()}
                    </div>
                </div>
                </div>
            </React.Fragment >
        );
    }
}

ReactTable.PropTypes = {
    defaultFiltered: PropTypes.object
}
const mapStateToProps = state => ({
})

const mapDispatchtoProps = (dispach) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchtoProps)(MemberDetail);
