import '../admin/scss/details.scss';
import 'react-select-plus/dist/react-select-plus.css';
import 'react-table/react-table.css';
import {
    Avatar,
    Button,
    ButtonGroup,
    Icon,
    IconSettings,
    PageHeader,
    PageHeaderControl,
    Tabs,
    TabsPanel,
} from '@salesforce/design-system-react';
import React, { Component } from 'react';
import { css, getApplicantId, getMemberId,getFullName } from 'service/common.js';

import DocumentCard from './member/document/DocumentCard';
import DefaultPic from './oncallui-react-framework/object/DefaultPic';

class MyCredentials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'related',
            applicant_id: getApplicantId(),
            member_id: getMemberId(),
            fullname:getFullName(),
        }
    }
    /**
     * Renders the document card
     */
    renderDocumentCard() {
        var id = this.state.member_id;
        var applicant_id = this.state.applicant_id;
        return (
            <DocumentCard
                ref="memberDocument"
                member_id={id}
                applicant_id={applicant_id}
                isMobile={this.props.isMobile}
            />
        )
    }
    /**
     * Rendering the related tab
     */
     renderRelatedTab() {
        if(this.state.loading == true) {
            return <React.Fragment />;
        }

        return (
            <Tabs id="slds-related-tab">
                <TabsPanel label="Related">
                    <div className="container-fluid m-0 pl-1 pr-1">
                        <div class="slds-scrollable_y" style={{"height":'100vh','display': 'initial'}}>
                            {this.renderDocumentCard()}
                        </div>
                    </div>
                </TabsPanel>
            </Tabs>
        )
    }
    /**
     * Rendering the details tab
     */
    renderDetailsTab() {
        if(this.state.loading == true) {
            return (
            <React.Fragment></React.Fragment>
            )
        }

        return (
            <Tabs id="slds-details-tab">
                <TabsPanel label="Details">
                    <div className="container-fluid m-0 pl-1 pr-1">
                        <div class="slds-scrollable_y" style={{"height":'100vh','display': 'initial'}}>
                            {this.renderDetailsTabContent()}
                        </div>
                    </div>
                </TabsPanel>
            </Tabs>
        )
    }
  
    /**
     * Render Page Header
     */
    renderPageHeader = () => {
        return (            
            <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                <PageHeader
                    icon={
                        this.state.avatar && <Avatar
                        assistiveText={{ icon: 'Avatar image' }}
                        imgSrc={this.state.avatar || DefaultPic}
                        imgAlt="Profile Pic"
                        size="medium"
                        title={false}
                    /> || 
                        <Icon
                            assistiveText={{ label: 'User' }}
                            category="standard"
                            name="lead"
                        />
                    }
                    title={this.state.applicant_name ? this.state.applicant_name : this.state.fullname}
                    variant="record-home"
                    className="mydetails"
                />
            </IconSettings>                
        );
    }
    render() {
        const styles = css({
            root: {
                fontFamily: "Salesforce Sans, Arial, Helvetica, sans-serif",
                marginRight: -15,
            }
        })
        return (
            <React.Fragment>
                <div className="container-fluid fixed_size">
                    <div className="slds slds-grid slds-grid_horizontal" style={styles.root}>
                        <div className="slds-col col-lg-12 custom_page_header max-width-res">
                            {this.renderPageHeader()}
                            {this.renderRelatedTab()}
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default MyCredentials;
