import React, { Component } from 'react';
import { IconSettings } from '@salesforce/design-system-react';

import { postData } from 'service/common.js';

import ExpandableSection from '@salesforce/design-system-react/lib/components/expandable-section';
import '../../admin/scss/components/admin/help/Help.scss';
import { CMS_HOME_PAGE } from 'config.js';

const getURl = () => {
    return new Promise((resolve, reject) => {
        postData('getMemberCMScontent', {}).then((result) => {
            let res = '';
            if(result.status) {
                res = result.url;
            }
            resolve(res);
        });
    });
}
class HelpDashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
            isOpen: false,
            helpJson: [],
            contentBody: '',
            selectedItem:false,
            title:'',
            changed:'',
            overview:'',
            breadcrumb:'',
            contentURL:''
		};
    }

    componentDidMount() {

        var result = [];
        let check = '';
        getURl().then(res => {
            if(!res){
                return true;
            }

            fetch(res)
            .then((response) => response.json())
            .then((responseJson) => {

                result = responseJson.reduce(function (r, a) {
                    r[a.field_parent_page] = r[a.field_parent_page] || [];
                    check = a.field_channels.includes('Member Portal') ? r[a.field_parent_page].push(a) :
                     a.nid == CMS_HOME_PAGE ? r[a.field_parent_page].push(a) : null;
                    return r;
                }, Object.create(null));

                this.setState({ helpJson: result })
                console.log(this.state.helpJson);
            })
            .catch((error) => {
                console.error(error);
            });
        });

    }

    getContent = e => {
        e.preventDefault();
        let val = e.target.dataset.value;
        let pagetitle = e.target.dataset.title;
        let changed = 'last updated ' + e.target.dataset.changed;
        this.setState({ contentBody: ''}, () => { this.setState({contentBody:val,title:pagetitle,changed:changed});

            window.scrollTo(0, 0);
        });
        let json = this.state.helpJson;
        let breadcrumb = '';
        if(e.target.dataset.parent) {
            const items = json[e.target.dataset.parent].map(name => (
                <li>
                <a href='#'
                    onClick={this.getContent.bind(this)}
                    data-parent={e.target.dataset.parent}
                    data-value={name.body}
                    data-title={name.title}
                    data-changed={name.changed}>
                    {name.title}
                </a>
                </li>
            ));
            breadcrumb += e.target.dataset.parent + ' > ' + pagetitle;
            this.setState({catLinks:items});
        } else {
            breadcrumb += pagetitle;
            this.setState({catLinks:''});
        }
        this.setState({breadcrumb:breadcrumb});
    }

    clickDiv(e) {
        e.click()
    }

    createTree() {
        let rows = [];
        let noparent = []
        let result = this.state.helpJson;
        let nid = '';

        for (var commonName in result) {

          //Skip the loop if child element is not available
          if(result[commonName].length == 0) {
            continue;
          }
          let squares = [];

          for(var i = 0; i < result[commonName].length; i++) {
            nid = result[commonName][i].nid;

            if(commonName === '') {
                //Display overview in front page
                if(nid == CMS_HOME_PAGE) {

                    rows.unshift(<div className={"slds-section slds-is-open slds-no-parent"}>
                    <h2
                    key={nid}
                    ref={this.clickDiv} //Trigger overview page for default page load
                    className={"slds-button slds-section__title-action"}
                    onClick={this.getContent.bind(this)}
                    data-value={result[commonName][i].body}
                    data-title={result[commonName][i].title}
                    data-changed={result[commonName][i].changed}
                    >{result[commonName][i].title}</h2></div>);
                    continue;
                }

                noparent.push(<div className={"slds-section slds-is-open slds-no-parent"}>
                    <h2
                    key={nid}
                    className={"slds-button slds-section__title-action"}
                    onClick={this.getContent.bind(this)}
                    data-value={result[commonName][i].body}
                    data-title={result[commonName][i].title}
                    data-changed={result[commonName][i].changed}
                    >{result[commonName][i].title}</h2></div>);
            } else {
                squares.push(<p><a
                    onClick={this.getContent.bind(this)}
                    data-value={result[commonName][i].body}
                    data-title={result[commonName][i].title}
                    data-changed={result[commonName][i].changed}
                    data-parent={commonName}
                    >{result[commonName][i].title}</a></p>);

            }
          }

        if(commonName !== '') {
            rows.push(<ExpandableSection
                id={"controlled-expandable-section" + nid}
                data-id={nid}
                isOpen={false}
                onToggleOpen={(event, data) => {

                    let parentNode = event.target.parentNode.parentNode.parentNode;
                    //Open while click arrow icon
                    if(event.target.className === 'slds-button slds-section__title-action' &&
                      !event.target.parentNode.parentNode.classList.contains('slds-is-open')) {
                        event.target.parentNode.parentNode.className += " slds-is-open";
                    }
                    else if(event.target.parentNode.parentNode.classList.contains('slds-is-open')){
                        event.target.parentNode.parentNode.classList.remove("slds-is-open");
                    }
                    else if(parentNode.classList.contains('slds-is-open')) {
                        parentNode.classList.remove("slds-is-open");
                    } else {
                        parentNode.className += " slds-is-open";
                    }

                }}
                title={commonName}
                >{squares}</ExpandableSection>);

            }
        }
        rows.push(noparent);

        return rows;
      }

   	render() {

		return (
            <React.Fragment>
		    	<IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">

                <div className="help_tree">
                    {this.createTree()}

                </div>
                <div className="help_content">
                    <div className="help_breadcrumb">Help {">"} {this.state.breadcrumb}</div>
                    <h2 className="help_title">{this.state.title}</h2>
                    <p className="help_changed">{this.state.changed}</p>
                    <div className="help_body"
                    dangerouslySetInnerHTML={{
                        __html: this.state.contentBody
                    }}></div>
                </div>
                <div className="help_related">
                    <h3>Related Contents</h3>
                    <ul>
                        {this.state.catLinks}
                    </ul>
                </div>

			</IconSettings>
            </React.Fragment>
        );
    }

}

export default HelpDashboard;