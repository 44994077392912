import React, { Component } from 'react';
import { connect } from 'react-redux'
import './App.css';
import "../src/components/admin/scss/main-style.scss";
import 'simplebar/dist/simplebar.min.css';


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loadable from 'react-loadable';
import {BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ROUTER_PATH, COMMON_DOC_DOWNLOAD_URL } from './config.js';
import { checkItsNotLoggedIn, checkLoginWithReturnTrueFalse, logout } from 'service/common.js';
import { osName} from 'react-device-detect';

import PermissionError from './components/admin/PermissionError';
import PageNotFound from './components/admin/PageNotFound';
import Login from './components/admin/Login';
import ForgotPassword from './components/admin/ForgotPassword';
import Reset_password from './components/admin/Reset_password';
import {Loading} from './components/admin/externl_component/Loading';
import MyDetails from './components/admin/MyDetails';
import MyQuizzes from './components/admin/MyQuizzes';
import ListQuizzes from './components/admin/ListQuizzes';
import Member from './components/admin/member/Member';
import HelpDashboard from './components/admin/help/HelpDashboard';
import NotificationList from './components/admin/notification/NotificationList';

import LoginAuth from './components/admin/LoginAuth';
import ForgotPasswordAuth from './components/admin/ForgotPasswordAuth';
import Reset_password_auth from './components/admin/Reset_password_auth';


import moment from 'moment-timezone';

import {getPermission} from './service/common';
import ErrorBoundary from 'service/ErrorBoundary';

import "react-datepicker/dist/react-datepicker.css";

import Header from './components/admin/Header';
import Footer from './components/admin/Footer';
import GlobalMenu from './components/admin/GlobalMenu';
import MemberDocumentList from './components/admin/member/document/ListDocument';
import MemberDocumentMobList from './components/admin/member/document/mobile/ListDocument';
import Icon from '@salesforce/design-system-react/lib/components/icon';
import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import AvailableShiftsList from './components/admin/member/AvailableShiftsList';
import MemberUnavailabilityList from './components/admin/member/unavailability/MemberUnavailabilityList';
import ViewShift from './components/admin/member/ViewShift';

import jQuery from 'jquery';
import $ from 'jquery'
import ViewSafteyChecklist from './components/admin/member/ViewSafteyChecklist';
import MyCredentials from "./components/admin/MyCredentials";
import CommonDocumentDownload from "./components/admin/CommonDocumentDownload";
moment.tz.setDefault('Australia/Melbourne');

class App extends Component {
    constructor(props) {
          super(props);
          this.permission = (checkLoginWithReturnTrueFalse())?((getPermission() == undefined)? [] : JSON.parse(getPermission())):[];
          this.state = {
              isLeftInnerSidebarOpen: false,
              isMobile: false,
          }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        /**
         * Stick Header
         */
        document.addEventListener('DOMContentLoaded', function() {
            var div = jQuery('#navbarheader');
            if(jQuery(div).offset()) {
                var start = jQuery(div).offset().top; //top header
                start = start + 50;
                jQuery.event.add(window, "scroll", function () {
                    var p = jQuery(window).scrollTop();
                    if (p > start) {
                        jQuery(div).addClass('sticky-navbar');
                    } else {
                        jQuery(div).removeClass('sticky-navbar');
                    }
                });
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    /**
     * Define the view is mobeil or desktop
     */
    resize() {
        this.setState({ isMobile: window.innerWidth < 769 ? true : false });
    }

    permissionRediect = () => {
        if(!checkLoginWithReturnTrueFalse()){
             return <Redirect to={ROUTER_PATH} />;
        }else{
            return <Redirect to={ROUTER_PATH+'admin/no_access'} />;
        }
        checkItsNotLoggedIn();
    }

    renderPushMenu() {
        const { AllPermission } = this.props

        return (
            <div id="GlobalMenuParent">
                <GlobalMenu permissions={AllPermission} isMobile={this.state.isMobile} isLeftInnerSidebarOpen={this.state.isLeftInnerSidebarOpen} />
            </div>
        )
    }

    logout(){
        if(!logout()){
            return false
        }else{
            return true
        }
    }

    /**
     * Handle the responsive header menu
     */
    onclickLeftMenu = () => {
        this.setState(p => ({ isLeftInnerSidebarOpen: !p.isLeftInnerSidebarOpen }))
    }

    render() {
        var myStyle = {fontFamily: "Helvetica LT Std-1"};

        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }

        const { isLeftInnerSidebarOpen, isMobile } = this.state
        const isLoggedIn = checkLoginWithReturnTrueFalse()
        const pageContentWrapperClassName = [isLeftInnerSidebarOpen && `open`, isLoggedIn ? `is-logged-in` : `is-guest`].filter(Boolean).join(' ')
        return (
                <ErrorBoundary>
                    <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                        <div id="wrapper" data-color="#00a7ee" style={myStyle} className={((this.props.RightMenuOpen ? "toggled_new" : '' ) + (this.props.LeftMenuOpen ? "toggled" : ''))} >
                            <div className="overlay_bg" onClick={() => this.props.sideBarclose({LeftMenuOpen: false, RightMenuOpen: false})}></div>
                            <Router>
                                <React.Fragment>
                                    {(checkLoginWithReturnTrueFalse() && this.props.showHeaderFooter)?
                                        <React.Fragment>
                                            <input type="hidden" id="checkLoginStikcy" value={isLoggedIn} />
                                            <div id="navbarheader" class="topnavbar">
                                                <Header handleLeftMenu={this.onclickLeftMenu} />
                                            </div>
                                            {/* <LeftMenu /> */}
                                            {/* <RightMenu /> */}
                                        </React.Fragment>:''
                                    }
                                    <div id="page-content-wrapper" className={pageContentWrapperClassName}>
                                        <div id="page-content-wrapper-inner">
                                            <span id="PushMenuCloseOverlay" onClick={() => this.setState({ isLeftInnerSidebarOpen: false })}></span>
                                            { isMobile && isLoggedIn && this.props.showHeaderFooter && this.renderPushMenu()}
                                            <Switch>
                                                <Route exact path={'/'} render={() => <LoginAuth auth={this.props} />}  />
                                                <Route exact path={'/something_went_wrong'} render={() => <ErrorBoundary />}  />
                                                {/* <Route exact path={ROUTER_PATH+'LoginAuth'} render={() => <LoginAuth auth={this.props} />}  /> */}
                                                {/* <Route exact path={ROUTER_PATH+'forgot_password'}  component={ForgotPassword} /> */}
                                                <Route exact path={ROUTER_PATH+'forgot_password'}  component={ForgotPasswordAuth} />
                                                {/* <Route path={ROUTER_PATH+'reset_password/:applicant_or_member_id/:password_token/:dateTime/:req_from'} component={Reset_password}  /> */}
                                                <Route path={ROUTER_PATH+'reset_password/:id/:token/:dateTime/:type/:uuid_user_type'} component={Reset_password_auth}  />
                                                <Route path={ROUTER_PATH+'mycredentials/document/:id'}  render={(props ) => checkLoginWithReturnTrueFalse() ? ((isMobile === true) ? <MemberDocumentMobList  {...props} /> : <MemberDocumentList  {...props} />) : this.permissionRediect()} />
                                                <Route path={ROUTER_PATH+'mydetails'}  render={(props ) => checkLoginWithReturnTrueFalse() ? <MyDetails props={props} />: this.permissionRediect()} />
                                                <Route path={ROUTER_PATH+'mycredentials'}  render={(props ) => checkLoginWithReturnTrueFalse() ? <MyCredentials props={props} />: this.permissionRediect()} />
                                                <Route path={ROUTER_PATH + COMMON_DOC_DOWNLOAD_URL + ':page/:id/:type/'}  render={(props ) => checkLoginWithReturnTrueFalse() ? <CommonDocumentDownload props={props} />: this.permissionRediect()} />
                                                <Route path={ROUTER_PATH+'myquizzes'}  render={(props ) => checkLoginWithReturnTrueFalse() ? <ListQuizzes props={props} />: this.permissionRediect()} />
                                                <Route path={ROUTER_PATH+'myquizzes/details/:id'}  render={(props) =>  <MyQuizzes {...props} key={props.match.params.id} />} />
                                                <Route path={ROUTER_PATH+'myshifts'}  render={(props ) => checkLoginWithReturnTrueFalse() ? <Member props={props} />: this.permissionRediect()} isMobile={isMobile} />
                                                <Route path={ROUTER_PATH+'shift/:id'}  render={(props ) => checkLoginWithReturnTrueFalse() ? <ViewShift props={props} isMobile={isMobile} />: this.permissionRediect()} />
                                                <Route path={ROUTER_PATH+'available_shifts'}  render={(props ) => checkLoginWithReturnTrueFalse() ? <AvailableShiftsList props={props} type_cap="Available" type="available" isMobile={isMobile} />: this.permissionRediect()} />
                                                <Route path={ROUTER_PATH+'accepted_shifts'}  render={(props ) => checkLoginWithReturnTrueFalse() ? <AvailableShiftsList props={props} type_cap="Accepted" type="accepted" isMobile={isMobile} />: this.permissionRediect()} />
                                                <Route path={ROUTER_PATH+'completed_shifts'}  render={(props ) => checkLoginWithReturnTrueFalse() ? <AvailableShiftsList props={props} type_cap="Accepted" type="completed" isMobile={isMobile} />: this.permissionRediect()} />
                                                <Route path={ROUTER_PATH+'unavailability'}  render={(props ) => checkLoginWithReturnTrueFalse() ? <MemberUnavailabilityList props={props} isMobile={isMobile} />: this.permissionRediect()} />

                                                <Route path={ROUTER_PATH+'help/dashboard'}  render={(props ) => checkLoginWithReturnTrueFalse() ? <HelpDashboard props={props} />: null} isMobile={isMobile} />
                                                <Route path={ROUTER_PATH+'app/notification_all'}  render={(props ) => checkLoginWithReturnTrueFalse() ? <NotificationList props={props} />: null} isMobile={isMobile} />
                                                <Route path={ROUTER_PATH+'checklist/:id/:shift_no/:shift_id'}  render={(props ) => checkLoginWithReturnTrueFalse() ? <ViewSafteyChecklist {...props} isMobile={isMobile} />: this.permissionRediect()} />

                                                <Route path={ROUTER_PATH+'logout'}  render={() => this.logout() } />
                                                <Route path={'/admin/no_access'} component={PermissionError} />

                                                <Route path='*' component={PageNotFound}  />

                                            </Switch>
                                        </div>
                                    </div>

                                    {(checkLoginWithReturnTrueFalse())?
                                        <Footer />:''
                                    }
                                    </React.Fragment>
                                </Router>
                            <ToastContainer />
                        </div>
                    </IconSettings>
                </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => ({
    showHeaderFooter : state.Permission.showHeaderFooter,
    AllPermission: state.Permission.AllPermission,
})

const mapDispatchtoProps = (dispach) => ({

})

export default connect(mapStateToProps, mapDispatchtoProps)(App);
